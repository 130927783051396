import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { updateTransaction, getTransaction } from '../../utilities/sendRequest';
import { decryptData } from '../../utilities/cryptoStorage';
import { dateTimeIndo, formatRupiah } from '../../utilities/helper';

const DetailTransaction = () => {
    const token = JSON.parse(decryptData(localStorage.getItem('cRdT')));
    const navigate = useNavigate()
    const params = useParams()
    const [invoice, setInvoice] = useState("")
    const [totalAmount, setTotalAmount] = useState("")
    const [idEnterpriser, setIdEnterpriser] = useState("")
    const [nameEnterpriser, setNameEnterpriser] = useState("")
    const [teleponEnterpriser, setTeleponEnterpriser] = useState("")
    const [time, setTime] = useState("")
    const [status, setStatus] = useState("")
    const [dataDetails, setDataDetails] = useState([])
    const [fetchMore, setFetchMore] = useState(true)

    useEffect(() => {
        fetchTransactions()
        setFetchMore(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchMore])

    const fetchTransactions = async () => {
        getTransaction(params.id, token).then((res) => {
            setInvoice(res.payload.invoice)
            setTime(dateTimeIndo(res.payload.created_at))
            setStatus(res.payload.status_transaction)
            setIdEnterpriser(res.payload.id_enterpriser)
            setNameEnterpriser(res.payload.name_enterprisers)
            setTeleponEnterpriser(res.payload.telepon)
            setTotalAmount(formatRupiah(res.payload.total_amount))
            setDataDetails(res.payload.detail_transactions)
        }).catch((err) => {
            toast.error(err, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        })  
    }

    const hanldeUpdate = async (updateStatus) => {

        const { value: text } = await Swal.fire({
            input: 'text',
            inputLabel: 'Keterangan',
            inputPlaceholder: 'Type your message here...',
            inputAttributes: {
              'aria-label': 'Type your message here'
            },
            showCancelButton: true
        })
          
        if (text) {
            const reqData = {
                status: updateStatus,
                keterangan: text
            }

            updateTransaction(params.id, reqData, token).then((res) => {
                toast.success(res.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000
                });
                setFetchMore(true)
            }).catch((err) => {
                toast.error(err.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000
                });
            })

        }
        
    }

    const handleChat = () => {
        let chat = "Selamat Pagi Bapak/Ibu "+nameEnterpriser+", Segera lakukan pembayaran sebesar "+totalAmount+" ke Rekening BCA 088 808 9333 - an. PT Bangun Insan Anugerah, dengan nomor invoice "+invoice
        
        window.open(`https://api.whatsapp.com/send?phone=62${teleponEnterpriser}&text=${chat}`)
    }

    const columns = [
        { 
            field: 'kode_product', 
            headerName: 'Kode Product',
            width: 150, 
            renderCell: (params) => {
                return (
                    <div className='flex justify-center items-center font-ssp'>
                        {params.row.kode_product}
                    </div>
                )
            } 
        },
        { 
            field: 'name', 
            headerName: 'Name Product',
            width: 300, 
            renderCell: (params) => {
                return (
                    <div className='flex justify-center items-center font-ssp'>
                        {params.row.name}
                    </div>
                )
            } 
        },
        { 
            field: 'price', 
            headerName: 'Pricce',
            width: 200, 
            renderCell: (params) => {
                return (
                    <div className='flex justify-center items-center font-ssp'>
                        {formatRupiah(params.row.price)}
                    </div>
                )
            } 
        },
    ]

    return (
        <div className='min-h-[calc(100vh-4rem)] flex flex-col'>
            {/* header  */}
            <div className="my-5 mx-7 md:mx-5 flex flex-row justify-between">
                <h1 className='font-ssp font-bold text-4xl md:text-3xl'>Detail Transaction</h1>
            </div>
            {/* content */}
            <div className="my-5 mx-7 md:mx-5 flex flex-col lg:flex-row justify-evenly gap-7 md:gap-10">
                {/* detail invoice */}
                <div className="w-full h-full lg:w-1/4 md:w-1/2 flex flex-col gap-3 items-center">
                    <div className='px-3 py-4 w-full h-full bg-white drop-shadow-lg rounded-md flex flex-col gap-2'>
                        <h3 className='text-lg font-ssp font-semibold text-center'>Detail Invoice</h3>
                        <div className='px-5 mt-3 w-full flex flex-wrap justify-between'>
                            {/* invoice */}
                            <div className="mt-3 mb-6 w-full relative z-0 group">
                                <input 
                                    minLength={3} 
                                    value={invoice} 
                                    type="text" 
                                    name="invoice" 
                                    id="invoice" 
                                    className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                                border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                                focus:border-blue-600 peer" 
                                    placeholder=" " 
                                    required 
                                    readOnly
                                />
                                <label 
                                    htmlFor="invoice" 
                                    className="absolute top-3 -z-10 text-sm text-gray-500 duration-300 transform -translate-y-6 
                                                scale-75 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:font-medium 
                                                peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                >Invoice</label>
                            </div>
                            {/* id enterpriser */}
                            <div className="mt-3 mb-6 w-full relative z-0 group">
                                <input 
                                    minLength={3} 
                                    value={idEnterpriser} 
                                    type="text" 
                                    name="enterpriser_id" 
                                    id="enterpriser_id" 
                                    className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                                border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                                focus:border-blue-600 peer" 
                                    placeholder=" " 
                                    required 
                                    readOnly
                                />
                                <label 
                                    htmlFor="enterpriser_id" 
                                    className="absolute top-3 -z-10 text-sm text-gray-500 duration-300 transform -translate-y-6 
                                                scale-75 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:font-medium 
                                                peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                >ID Enterpriser</label>
                            </div>
                            {/* name enterpriser */}
                            <div className="mt-3 mb-6 w-full relative z-0 group">
                                <input 
                                    minLength={3} 
                                    value={nameEnterpriser} 
                                    type="text" 
                                    name="name_enterpriser" 
                                    id="name_enterpriser" 
                                    className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                                border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                                focus:border-blue-600 peer" 
                                    placeholder=" " 
                                    required
                                    readOnly
                                />
                                <label 
                                    htmlFor="name_enterpriser" 
                                    className="absolute top-3 -z-10 text-sm text-gray-500 duration-300 transform -translate-y-6 
                                                scale-75 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:font-medium 
                                                peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                >Name Enterpriser</label>
                            </div>
                            {/* telepon */}
                            <div className="mt-3 mb-6 w-full relative z-0 group">
                                <input 
                                    minLength={3} 
                                    value={teleponEnterpriser} 
                                    type="text" 
                                    name="telepon_enterpriser" 
                                    id="telepon_enterpriser" 
                                    className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                                border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                                focus:border-blue-600 peer" 
                                    placeholder=" " 
                                    required
                                    readOnly
                                />
                                <label 
                                    htmlFor="telepon_enterpriser" 
                                    className="absolute top-3 -z-10 text-sm text-gray-500 duration-300 transform -translate-y-6 
                                                scale-75 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:font-medium 
                                                peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                >Telephone Enterpriser</label>
                            </div>
                            {/* total */}
                            <div className="mt-3 mb-6 w-full relative z-0 group">
                                <input 
                                    minLength={3} 
                                    value={totalAmount} 
                                    type="text" 
                                    name="total" 
                                    id="total" 
                                    className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                                border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                                focus:border-blue-600 peer" 
                                    placeholder=" " 
                                    required 
                                    readOnly
                                />
                                <label 
                                    htmlFor="total" 
                                    className="absolute top-3 -z-10 text-sm text-gray-500 duration-300 transform -translate-y-6 
                                                scale-75 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:font-medium 
                                                peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                >Total</label>
                            </div>
                            {/* status */}
                            <div className="mt-3 mb-6 w-full relative z-0 group">
                                <input 
                                    minLength={3} 
                                    value={status} 
                                    type="text" 
                                    name="status" 
                                    id="status" 
                                    className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                                border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                                focus:border-blue-600 peer uppercase" 
                                    placeholder=" " 
                                    required 
                                    readOnly
                                />
                                <label 
                                    htmlFor="status" 
                                    className="absolute top-3 -z-10 text-sm text-gray-500 duration-300 transform -translate-y-6 
                                                scale-75 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:font-medium 
                                                peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                >Status</label>
                            </div>
                            {/* time */}
                            <div className="mt-3 mb-6 w-full relative z-0 group">
                                <input 
                                    minLength={3} 
                                    value={time} 
                                    type="text" 
                                    name="time" 
                                    id="time" 
                                    className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                                border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                                focus:border-blue-600 peer" 
                                    placeholder=" " 
                                    required 
                                    readOnly
                                />
                                <label 
                                    htmlFor="time" 
                                    className="absolute top-3 -z-10 text-sm text-gray-500 duration-300 transform -translate-y-6 
                                                scale-75 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:font-medium 
                                                peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                >Time</label>
                            </div>
                        </div>
                    </div>
                </div>
                {/* list detail transaction */}
                <div className="px-2 py-4 w-full h-fit lg:w-2/3 md:w-10/12 bg-white drop-shadow-lg rounded-md flex flex-col justify-center gap-3">
                    <h3 className='text-lg font-ssp font-semibold text-center'>List Product</h3>
                    <DataGrid
                        autoHeight {...dataDetails}
                        rows={dataDetails}
                        columns={columns}
                        pageSize={10}
                        getRowId={(row) => row.id}
                        rowsPerPageOptions={[10]}
                        checkboxSelection
                        disableSelectionOnClick
                        components={{ Toolbar: GridToolbar, }}
                    />
                    <div className="mt-5 mb-6 w-full relative z-0 flex justify-center flex-wrap gap-3">
                        {(status === 'unpaid') &&
                            <>
                                <div 
                                    onClick={() => hanldeUpdate("success")}
                                    className="px-5 py-2.5 w-full h-fit sm:w-auto bg-green-400 hover:bg-green-500 rounded-lg 
                                                font-medium text-sm text-center text-white cursor-pointer "
                                >Approve Transaction</div>
                                <div 
                                    onClick={() => hanldeUpdate("cancel")}
                                    className="px-5 py-2.5 w-full h-fit sm:w-auto bg-yellow-400 hover:bg-yellow-500 rounded-lg 
                                                font-medium text-sm text-center text-white cursor-pointer "
                                >Cancel Transaction</div>
                                <div 
                                    onClick={() => handleChat()}
                                    className="px-5 py-2.5 w-full h-fit sm:w-auto bg-blue-400 hover:bg-blue-500 rounded-lg 
                                                font-medium text-sm text-center text-white cursor-pointer "
                                >Chat</div>
                            </>
                        }
                        <span 
                            onClick={() => navigate("/transactions")} 
                            className="px-5 py-2.5 w-full h-fit sm:w-auto bg-red-400 hover:bg-red-500 rounded-lg 
                                        font-medium text-sm text-center text-white cursor-pointer"
                        >Back</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DetailTransaction