import React, { useEffect, useRef, useContext } from 'react';
import { RiCloseFill } from "react-icons/ri";
import { toast } from 'react-toastify';
import { PopupContext } from "../../context/popupContext";
import { AuthContext } from '../../context/authContext';
import { deleteBroadcast, logoutUser } from '../../utilities/sendRequest';
import { decryptData } from '../../utilities/cryptoStorage';

const ModalDeleteBroadcast = ({ setShowModalDelete, broadcast, setFetchMore }) => {
    const token = JSON.parse(decryptData(localStorage.getItem('cRdT')));
    const { dispatch } = useContext(AuthContext)
    const { popupDispatch } = useContext(PopupContext)
    const clickRef = useRef(null)

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (clickRef.current && !clickRef.current.contains(event.target)) {
                setShowModalDelete(null)
                popupDispatch({ type: "POPUP_END" });
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clickRef])

    const handleDelete = async (parmasId) => {
        deleteBroadcast(parmasId, token).then((res) => {
            toast.success(res.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
            setFetchMore(true)
            setShowModalDelete(null)
        }).catch((error) => {
            toast.error(error.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });

            if (error.response.status === 401) {
                setTimeout(() => {
                    logoutUser(dispatch)
                }, 3500)
            }
        })
        popupDispatch({ type: "POPUP_END" });
    }

    const handleModal = () => {
        setShowModalDelete(null)
        popupDispatch({ type: "POPUP_END" });
    }

    return (
        <>
            <div className='absolute top-0 left-0 z-10 p-3 w-screen min-h-screen overscroll-y-none overflow-y-hidden bg-black bg-opacity-70 flex items-center justify-center'>
                <div ref={clickRef} className="p-3 w-11/12 lg:w-1/3 md:w-1/2 h-fit bg-white rounded-md flex flex-col">
                    <div className="px-2 flex justify-between items-center">
                        <h3 className='font-bold font-ssp text-xl md:text-2xl'>Delete Broadcast</h3>
                        <RiCloseFill onClick={handleModal} className='w-8 h-8 cursor-pointer'/>
                    </div>
                    <div onSubmit={handleDelete} className='mt-5 px-5 w-full flex flex-col'>
                        <div className="mt-3 mb-3 w-full relative z-0 group">
                            Apakah Anda yakin ingin menghapus "{broadcast.title}" ?
                        </div>

                        {broadcast.photo && 
                            <div className="mt-3 w-full justify-center relative flex flex-row group">
                                <img src={`${process.env.REACT_APP_PUBLIC_FOLDER_BROADCASTS}/${broadcast.photo}`} alt="Thumbnail" loading='lazy' className="w-1/2" />
                            </div>
                        }

                        {/* button */}
                        <div className="mb-6 mt-5 w-full relative z-0 flex justify-end gap-3">
                            <button 
                                onClick={() => handleDelete(broadcast.id)}
                                className="mt-5 px-5 py-2.5 w-full sm:w-auto bg-blue-400 hover:bg-blue-500 rounded-lg font-medium  
                                            text-sm text-white text-center cursor-pointer"
                            >Yes</button>
                            <span 
                                onClick={handleModal} 
                                className="mt-5 px-5 py-2.5 w-full sm:w-auto bg-red-400 hover:bg-red-500 rounded-lg font-medium
                                            text-sm text-white text-center cursor-pointer"
                            >Cancel</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ModalDeleteBroadcast