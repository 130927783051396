import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { decryptData } from '../../utilities/cryptoStorage';
import { toast } from 'react-toastify';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { getDetailEntrepriser, getVipProduct, toggleDevice, updateEnterpriser, updateDurationVip, getRecordVIP, updateChangeDurationVip } from '../../utilities/sendRequest';
import { dateTimeIndo } from '../../utilities/helper';

const DetailEnterpriser = () => {
    const navigate = useNavigate()
    const token = JSON.parse(decryptData(localStorage.getItem('cRdT')));
    const params = useParams()
    const [fetchMore, setFetchMore] = useState(true)
    const [idEnterprise, setIdEnterprise] = useState("")
    const [fullname, setFullname] = useState("")
    const [nickname, setNickname] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [kota, setKota] = useState("")
    const [telepon, setTelepon] = useState("")
    const [role, setRole] = useState("")
    const [duration, setDuration] = useState("")
    const [newDate, setNewDate] = useState("")
    const [lifeTime, setLifeTime] = useState("")
    const [data, setData] = useState([])
    const [records, setRecords] = useState([])
    const [vipProducts, setVipProducts] = useState([])
    const [status, setStatus] = useState("")
    const optionStatus = [
        {
            label: "Choose a Status",
            value: "",
        },
        {
            label: "ACTIVE",
            value: 1,
        },
        {
            label: "INACTIVE",
            value: 0,
        },
    ]

    useEffect(() => {
        fetchEnterpriser()
        fetchVipProducts()
        fetchRecordVip()
        setFetchMore(false)
        // eslint-disable-next-line
    }, [fetchMore])


    const fetchRecordVip = async () => {
        getRecordVIP(params.id, token).then((res) => {
            setRecords(
                res.payload.sort((p1, p2) => {
                    return new Date(p2.created_at) - new Date(p1.created_at)
                })
            )
        }).catch((err) => {
            toast.error(err, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        })  
    }

    const fetchEnterpriser = async () => {
        getDetailEntrepriser(params.id, token).then((res) => {
            setData(res.payload)
            setFullname(res.payload.name)
            setNickname(res.payload.nickname)
            setPassword(res.payload.password)
            setIdEnterprise(res.payload.id_enterpriser)
            setEmail(res.payload.email)
            setTelepon(res.payload.telepon)
            setKota(res.payload.kota)
            setKota(res.payload.kota)
            setStatus(res.payload.is_active)
            setRole(res.payload.roles)
            setLifeTime(res.payload.vip_end ? res.payload.vip_end : "Infinity")
        }).catch((err) => {
            toast.error(err, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        })  
    }

    const fetchVipProducts = async () => {
        getVipProduct(token).then((res) => {
            setVipProducts(res.payload)
        }).catch((err) => {
            toast.error(err, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        })  
    }

    const handleResetDevice = async () => {
        toggleDevice(params.id, token).then((res) => {
            toast.success(res.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
            setFetchMore(true)
        }).catch((err) => {
            toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        })
    }

    const handleUpdate = async (e) => {
        e.preventDefault();

        const reqData = {
            idEnterprise,
            fullname,
            nickname,
            email,
            password,
            telepon,
            kota,
            status,
            role
        }

        updateEnterpriser(params.id, reqData, token).then((res) => {
            toast.success(res.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
            
            setFetchMore(true)
        }).catch((err) => {
            toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        })
    }

    const handleAddDuration = async (e) => {
        e.preventDefault();

        const reqData = {
            duration
        }

        updateDurationVip(params.id, reqData, token).then((res) => {
            toast.success(res.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
            
            setFetchMore(true)
            setDuration("")
        }).catch((err) => {
            toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        })
    }

    const handleChangeDuration = async (e) => {
        e.preventDefault();

        const reqData = {
            newDate
        }

        updateChangeDurationVip(params.id, reqData, token).then((res) => {
            toast.success(res.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
            
            setFetchMore(true)
            setNewDate("")
        }).catch((err) => {
            toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        })
    }


    const columns = [
        { 
            field: 'created_at', 
            headerName: 'Time',
            width: 300, 
            renderCell: (params) => {
                return (
                    <div className='flex justify-center items-center font-ssp'>
                        {dateTimeIndo(params.row.created_at)}
                    </div>
                )
            } 
        },
        { 
            field: 'new_roles', 
            headerName: 'New Roles',
            width: 150, 
            renderCell: (params) => {
                return (
                    <div className='flex justify-center items-center font-ssp'>
                        {
                            params.row.new_roles === 'regular' ?
                                "Regular"
                            : params.row.new_roles === 'vip' ?
                                "VIP 3 Bulan"
                            : params.row.new_roles === 'vip2' ?
                                "VIP 6 Bulan"
                            : params.row.new_roles === 'vip3' ?
                                "VIP 1 Tahun"
                            : params.row.new_roles === 'vip4' ?
                                "VIP 3 Hari"
                            : params.row.new_roles === 'vip5' ?
                                "VIP 1 Bulan"
                            : params.row.new_roles === 'vvip' ?
                                "VVIP"
                            :
                                "Unknown"
                        }
                    </div>
                )
            } 
        },
        { 
            field: 'last_roles', 
            headerName: 'Last Roles',
            width: 300, 
            renderCell: (params) => {
                return (
                    <div className='flex justify-center items-center font-ssp'>
                        {
                            params.row.last_roles === 'regular' ?
                                "Regular"
                            : params.row.last_roles === 'vip' ?
                                "VIP 3 Bulan"
                            : params.row.last_roles === 'vip2' ?
                                "VIP 6 Bulan"
                            : params.row.last_roles === 'vip3' ?
                                "VIP 1 Tahun"
                            : params.row.last_roles === 'vip4' ?
                                "VIP 3 Hari"
                            : params.row.last_roles === 'vip5' ?
                                "VIP 1 Bulan"
                            : params.row.last_roles === 'vvip' ?
                                "VVIP"
                            :
                                "Unknown"
                        }
                    </div>
                )
            } 
        },
    ]

    return (
        <div className='min-h-[calc(100vh-4rem)] flex flex-col'>
            {/* header  */}
            <div className="my-5 mx-7 md:mx-5 flex flex-col md:flex-row justify-between gap-2">
                <h1 className='font-ssp font-bold text-4xl md:text-3xl'>Detail Enterpriser</h1>
                <div className="font-ssp">
                        {data.login_devices
                            ? <span className="px-2 py-1 bg-blue-400 rounded-full text-white uppercase">Logged</span>
                            : <span className="px-2 py-1 bg-slate-400 rounded-full text-white uppercase">Unlogged</span>
                        }
                    </div>
            </div>
            {/* content */}
            <div className="my-5 mx-7 md:mx-5 flex flex-col justify-evenly gap-7 md:gap-10">
                {/* form update */}
                <div className="px-2 py-4 w-full bg-white drop-shadow-lg rounded-md flex">
                    <form onSubmit={handleUpdate} className='px-5 w-full flex flex-wrap justify-between'>
                        {/* id enterprise */}
                        <div className="mt-3 mb-6 w-full md:w-[30%] relative z-0 group">
                            <input 
                                minLength={3} 
                                value={idEnterprise} 
                                onChange={(e) => setIdEnterprise(e.target.value)} 
                                type="text" 
                                name="idEnterprise" 
                                id="idEnterprise" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                            focus:border-blue-600 peer" 
                                placeholder=" " 
                                required 
                            />
                            <label 
                                htmlFor="idEnterprise" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 duration-300 transform -translate-y-6 
                                            scale-75 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:font-medium 
                                            peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >ID Enterpriser</label>
                        </div>
                        {/* fullname */}
                        <div className="mt-3 mb-6 w-full md:w-[30%] relative z-0 group">
                            <input 
                                minLength={3} 
                                value={fullname} 
                                onChange={(e) => setFullname(e.target.value)} 
                                type="text" 
                                name="fullname" 
                                id="fullname" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                            focus:border-blue-600 peer" 
                                placeholder=" " 
                                required 
                            />
                            <label 
                                htmlFor="fullname" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 duration-300 transform -translate-y-6 
                                            scale-75 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:font-medium 
                                            peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Fullname</label>
                        </div>
                        {/* nickname */}
                        <div className="mt-3 mb-6 w-full md:w-[30%] relative z-0 group">
                            <input 
                                minLength={3} 
                                value={nickname} 
                                onChange={(e) => setNickname(e.target.value)} 
                                type="text" 
                                name="nickname" 
                                id="nickname" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                            focus:border-blue-600 peer" 
                                placeholder=" " 
                                required 
                            />
                            <label 
                                htmlFor="nickname" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 duration-300 transform -translate-y-6 
                                            scale-75 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:font-medium 
                                            peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Nick Name</label>
                        </div>
                        {/* email */}
                        <div className="mt-3 mb-6 w-full md:w-[30%] relative z-0 group">
                            <input 
                                minLength={3} 
                                value={email} 
                                onChange={(e) => setEmail(e.target.value)} 
                                type="text" 
                                name="email" 
                                id="email" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                            focus:border-blue-600 peer" 
                                placeholder=" " 
                                required 
                            />
                            <label 
                                htmlFor="email" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 duration-300 transform -translate-y-6 
                                            scale-75 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:font-medium 
                                            peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Email</label>
                        </div>
                        {/* password */}
                        <div className="mt-3 mb-6 w-full md:w-[30%] relative z-0 group">
                            <input 
                                minLength={3} 
                                value={password} 
                                onChange={(e) => setPassword(e.target.value)} 
                                type="text" 
                                name="password" 
                                id="password" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                            focus:border-blue-600 peer" 
                                placeholder=" " 
                                required 
                            />
                            <label 
                                htmlFor="password" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 duration-300 transform -translate-y-6 
                                            scale-75 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:font-medium 
                                            peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Password</label>
                        </div>
                        {/* telepon */}
                        <div className="mt-3 mb-6 w-full md:w-[30%] relative z-0 group">
                            <input 
                                minLength={3} 
                                value={telepon} 
                                onChange={(e) => setTelepon(e.target.value)} 
                                type="text" 
                                name="telepon" 
                                id="telepon" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                            focus:border-blue-600 peer" 
                                placeholder=" " 
                                required 
                            />
                            <label 
                                htmlFor="telepon" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 duration-300 transform -translate-y-6 
                                            scale-75 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:font-medium 
                                            peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Telepon</label>
                        </div>
                        {/* kota */}
                        <div className="mt-3 mb-6 w-full md:w-[30%] relative z-0 group">
                            <input 
                                minLength={3} 
                                value={kota} 
                                onChange={(e) => setKota(e.target.value)} 
                                type="text" 
                                name="kota" 
                                id="kota" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                            focus:border-blue-600 peer" 
                                placeholder=" " 
                                required 
                            />
                            <label 
                                htmlFor="kota" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 duration-300 transform -translate-y-6 
                                            scale-75 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:font-medium 
                                            peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Kota</label>
                        </div>
                        {/* roles */}
                        <div className="mb-6 mt-3 w-full md:w-[30%] relative z-0 group">
                            <select 
                                required 
                                value={role} 
                                onChange={(e) => setRole(e.target.value)} 
                                id="role" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-500 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                            focus:border-blue-600 peer"
                            >
                                <option value="">Choose a role</option>
                                <option value="vvip">VVIP</option>
                                <option value="regular">Regular</option>
                                {vipProducts.map((item) => (
                                    <option key={item.id} value={item.code}>{item.name}</option>
                                ))}
                            </select>
                            <label 
                                htmlFor="role" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 
                                            duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:font-medium 
                                            peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 
                                            peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Roles</label>
                        </div>
                        {/* status */}
                        <div className="mb-6 mt-3 w-full md:w-[30%] relative z-0 group">
                            <select 
                                required 
                                value={status} 
                                onChange={(e) => setStatus(e.target.value)} 
                                id="status" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-500 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                            focus:border-blue-600 peer"
                            >
                                {optionStatus.map((option) => (
                                    <option key={option.value} value={option.value}>{option.label}</option>
                                ))}
                            </select>
                            <label 
                                htmlFor="status" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 
                                            duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:font-medium 
                                            peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 
                                            peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Status</label>
                        </div>
                        {/* button */}
                        <div className="mt-2 mb-6 w-full relative z-0 flex flex-wrap justify-center gap-0 md:gap-5">
                            <span 
                                onClick={() => handleResetDevice()} 
                                className="px-5 py-2.5 mt-2 md:mt-5 w-full h-fit sm:w-auto bg-slate-400 hover:bg-slate-500 rounded-lg 
                                            font-medium text-sm text-center text-white cursor-pointer"
                            >Reset Device</span>
                            <button 
                                type="submit" 
                                className="px-5 py-2.5 mt-2 md:mt-5 w-full h-fit sm:w-auto bg-green-400 hover:bg-green-500 rounded-lg 
                                            font-medium text-sm text-center text-white cursor-pointer "
                            >Update</button>
                            <span 
                                onClick={() => navigate(-1)} 
                                className="px-5 py-2.5 mt-2 md:mt-5 w-full h-fit sm:w-auto bg-red-400 hover:bg-red-500 rounded-lg 
                                            font-medium text-sm text-center text-white cursor-pointer"
                            >Back</span>
                        </div>
                    </form>
                </div>
                {/* form add duration */}
                {(data.roles !== "regular" && data.roles !== "vvip") ?
                    <div className='flex flex-col md:flex-row gap-3'>
                        <div className="px-2 py-4 w-full lg:w-1/4 md:w-10/12 bg-white drop-shadow-lg rounded-md flex flex-col gap-2">
                            <h1 className='px-5 ont-ssp font-bold text-base md:text-xl'>Add Duration VIP</h1>
                            <form onSubmit={handleAddDuration} className='px-5 w-full flex flex-wrap justify-between'>
                                {/* masa aktif */}
                                <div className="mt-5 mb-5 w-full relative z-0 group">
                                    <input 
                                        minLength={3} 
                                        value={lifeTime} 
                                        onChange={(e) => setLifeTime(e.target.value)} 
                                        type="text" 
                                        name="lifeTime" 
                                        id="lifeTime" 
                                        className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                                    border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                                    focus:border-blue-600 peer" 
                                        placeholder=" " 
                                        required 
                                        readOnly
                                    />
                                    <label 
                                        htmlFor="lifeTime" 
                                        className="absolute top-3 -z-10 text-sm text-gray-500 duration-300 transform -translate-y-6 
                                                    scale-75 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:font-medium 
                                                    peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                    >Masa Aktif</label>
                                </div>
                                {/* duration */}
                                <div className="mt-3 mb-6 w-full relative z-0 group">
                                    <input 
                                        minLength={3} 
                                        value={duration} 
                                        onChange={(e) => setDuration(e.target.value)} 
                                        type="number" 
                                        name="duration" 
                                        id="duration" 
                                        className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                                    border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                                    focus:border-blue-600 peer" 
                                        placeholder=" " 
                                        required 
                                    />
                                    <label 
                                        htmlFor="duration" 
                                        className="absolute top-3 -z-10 text-sm text-gray-500 duration-300 transform -translate-y-6 
                                                    scale-75 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:font-medium 
                                                    peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                    >Duration (Days)</label>
                                    <p className="mt-1 font-ssp text-xs text-gray-500 dark:text-gray-300" id="duration">1 Month = 30 Days, 1 Year = 365 Days</p>
                                </div>
                                <div className="mb-6 w-full relative z-0 flex justify-center gap-5">
                                    <button 
                                        type="submit" 
                                        className="px-5 py-2.5 mt-5 w-full h-fit sm:w-auto bg-green-400 hover:bg-green-500 rounded-lg 
                                                    font-medium text-sm text-center text-white cursor-pointer capitalize"
                                    >Add Duration</button>
                                </div>
                            </form>
                        </div>
                        <div className="px-2 py-4 w-full lg:w-1/4 md:w-10/12 bg-white drop-shadow-lg rounded-md flex flex-col gap-2">
                            <h1 className='px-5 ont-ssp font-bold text-base md:text-xl'>Change Duration VIP</h1>
                            <form onSubmit={handleChangeDuration} className='px-5 w-full flex flex-wrap justify-between'>
                                {/* masa aktif */}
                                <div className="mt-5 mb-5 w-full relative z-0 group">
                                    <input 
                                        minLength={3} 
                                        value={lifeTime} 
                                        onChange={(e) => setLifeTime(e.target.value)} 
                                        type="text" 
                                        name="lifeTime" 
                                        id="lifeTime" 
                                        className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                                    border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                                    focus:border-blue-600 peer" 
                                        placeholder=" " 
                                        required 
                                        readOnly
                                    />
                                    <label 
                                        htmlFor="lifeTime" 
                                        className="absolute top-3 -z-10 text-sm text-gray-500 duration-300 transform -translate-y-6 
                                                    scale-75 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:font-medium 
                                                    peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                    >Masa Aktif</label>
                                </div>
                                <div className="mt-3 mb-6 w-full relative z-0 group">
                                    <input 
                                        minLength={3} 
                                        value={newDate} 
                                        onChange={(e) => setNewDate(e.target.value)} 
                                        type="date" 
                                        name="newDate" 
                                        id="newDate" 
                                        className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                                    border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                                    focus:border-blue-600 peer" 
                                        placeholder=" " 
                                        required 
                                    />
                                    <label 
                                        htmlFor="duration" 
                                        className="absolute top-3 -z-10 text-sm text-gray-500 duration-300 transform -translate-y-6 
                                                    scale-75 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:font-medium 
                                                    peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                    >New Date</label>
                                </div>
                                <div className="mb-6 w-full relative z-0 flex justify-center gap-5">
                                    <button 
                                        type="submit" 
                                        className="px-5 py-2.5 mt-5 w-full h-fit sm:w-auto bg-green-400 hover:bg-green-500 rounded-lg 
                                                    font-medium text-sm text-center text-white cursor-pointer capitalize"
                                    >Change Duration</button>
                                </div>
                            </form>
                        </div>
                        <div className="px-2 py-4 w-full lg:w-1/2 md:w-10/12 bg-white drop-shadow-lg rounded-md flex flex-col gap-2">
                            <h1 className='px-5 ont-ssp font-bold text-base md:text-xl'>History VIP</h1>
                            <DataGrid
                                autoHeight {...records}
                                rows={records}
                                columns={columns}
                                pageSize={5}
                                getRowId={(row) => row.id}
                                rowsPerPageOptions={[5]}
                                checkboxSelection
                                disableSelectionOnClick
                                components={{ Toolbar: GridToolbar, }}
                            />
                        </div>
                    </div>
                    : ""
                }
            </div>
        </div>
    )
}

export default DetailEnterpriser