export const sidebarMenus = [
    {
        title: 'Dashboard', 
        icon: '/assets/icons/Dashboard.svg',
        link: '/',
        roles: ["it", "market", "event"],
        subMenus: []
    },
    {
        title: 'Employees', 
        icon: '/assets/icons/Employees.svg',
        link: '/employees',
        roles: ["it"],
        subMenus: []
    },
    {
        title: 'News Update', 
        icon: '/assets/icons/News.svg',
        link: '/posts',
        roles: ["it", "event"],
        subMenus: []
    },
    {
        title: 'Profile Success', 
        icon: '/assets/icons/Enterprisers.svg',
        link: '/success',
        roles: ["it"],
        subMenus: []
    },
    {
        title: 'Slider Carousel', 
        icon: '/assets/icons/Gallery.svg',
        link: '/carousels',
        roles: ["it"],
        subMenus: []
    },
    {
        title: 'Broadcast', 
        icon: '/assets/icons/Commercial.svg',
        link: '/broadcasts',
        roles: ["it", "market", "event"],
        subMenus: []
    },
    {
        title: 'Audio Player', 
        icon: '/assets/icons/Audios.svg',
        link: '',
        roles: ["it"],
        subMenus: [
            {
                subTitle: 'Audio CD',
                link: '/audios/players',
            },
            {
                subTitle: 'Audio Book',
                link: '/audios/books',
            },
            {
                subTitle: 'Audio Testimonial',
                link: '/audios/testimonials',
            },
            {
                subTitle: 'Book Study',
                link: '/audios/studies',
            },
            {
                subTitle: 'Audio Free',
                link: '/audios/free',
            },
        ]
    },
    {
        title: 'Enterprisers', 
        icon: '/assets/icons/Enterprisers.svg',
        link: '',
        roles: ["it"],
        subMenus: [
            {
                subTitle: 'Regular',
                link: '/enterprisers/regular',
            },
            {
                subTitle: 'VIP 1 (3 Bulan)',
                link: '/enterprisers/vip',
            },
            {
                subTitle: 'VIP 2 (6 Bulan)',
                link: '/enterprisers/vip2',
            },
            {
                subTitle: 'VIP 3 (1 Tahun)',
                link: '/enterprisers/vip3',
            },
            {
                subTitle: 'VIP 4 (3 Hari)',
                link: '/enterprisers/vip4',
            },
            {
                subTitle: 'VIP 5 (1 Bulan)',
                link: '/enterprisers/vip5',
            },
            {
                subTitle: 'VVIP',
                link: '/enterprisers/vvip',
            },
        ]
    },
    {
        title: 'VIP Content', 
        icon: '/assets/icons/VIP.svg',
        link: '',
        roles: ["it"],
        subMenus: [
            {
                subTitle: 'BV Calculation',
                link: '/bvCalculation',
            },
            {
                subTitle: 'Daftar Penyakit',
                link: '/diseases',
            },
            {
                subTitle: 'Manfaat Product',
                link: '/benefits',
            },
            {
                subTitle: 'Testimonial',
                link: '/testimonials',
            },
            {
                subTitle: 'Billionaires Content',
                link: '/contents',
            }
        ]
    },
    {
        title: 'Event Zone', 
        icon: '/assets/icons/Events.svg',
        link: '',
        roles: ["it", "event"],
        subMenus: [
            {
                subTitle: 'Speakers',
                link: '/speakers',
            },
            {
                subTitle: 'Nationals',
                link: '/nationals',
            },
            {
                subTitle: 'Dailys',
                link: '/dailys',
            }
        ]
    },
    {
        title: 'Market Zone', 
        icon: '/assets/icons/Shop.svg',
        link: '',
        roles: ["it", "market"],
        subMenus: [
            {
                subTitle: 'Categories',
                link: '/productsCategories',
            },
            {
                subTitle: 'Product',
                link: '/products',
            },
            {
                subTitle: 'Market Places',
                link: '/marketPlaces',
            },
            {
                subTitle: 'Transactions',
                link: '/transactions',
            },
            {
                subTitle: 'Report Controls',
                link: '/reports',
            },
        ]
    },
]