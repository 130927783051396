import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

const Accordion = ({ handleSidebar, activeButton, inActiveButton, data }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    useEffect(() => {
        (data.subMenus.some(subMenu => window.location.pathname.includes(subMenu.link))) ? setIsExpanded(true) : setIsExpanded(false)
        // eslint-disable-next-line
    }, []);

    const toggleAccordion = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <div className='flex flex-col gap-2'>
            <div onClick={toggleAccordion} className={`px-2 py-1.5 text-gray-300 font-ssp tracking-wider ${(isExpanded) ? 'text-xl font-semibold' : 'text-lg hover:font-semibold'} flex flex-row justify-between cursor-pointer`}>
                <div className='flex gap-2 justify-start self-center items-center'>
                    <img src={data.icon} alt="Menu Icon" className="w-6 h-6" />
                    <span>{data.title}</span>
                </div>
                <svg className={`w-5 h-5 self-center ${isExpanded ? 'transform rotate-180' : ''} transition-all duration-200 ease-in-out`} fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                </svg>
            </div>
            {isExpanded && (
            <div className="mb-2 bg-darkSecond flex flex-col gap-1">
                {data.subMenus.map((menu, i) => (
                    <NavLink 
                        key={i}
                        to={menu.link} 
                        className={({ isActive }) => isActive ? activeButton : inActiveButton}
                        onClick={handleSidebar}
                    >
                        <span className='ml-3 self-center'>{menu.subTitle}</span>
                    </NavLink>
                ))}
            </div>
            )}
        </div>
    )
}

export default Accordion