import React, { useContext, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { RiCloseFill } from "react-icons/ri";
import { IoIosCloseCircle } from "react-icons/io";
import { PopupContext } from "../../context/popupContext";
import { addSpeaker } from '../../utilities/sendRequest';
import { decryptData } from '../../utilities/cryptoStorage';

const ModalAddSpeaker = ({ setShowModalAdd, setFetchMore }) => {
    const token = JSON.parse(decryptData(localStorage.getItem('cRdT')));
    const { popupDispatch } = useContext(PopupContext)
    const clickRef = useRef(null)
    const photoRef = useRef(null)
    const [photo, setPhoto] = useState(null)
    const [name, setName] = useState("")
    const [level, setLevel] = useState("")
    const [status, setStatus] = useState("")
    const options = [
        {
            label: "ACTIVE",
            value: 1
        },
        {
            label: "INACTIVE",
            value: 0
        },
    ]
    const levelOptions = [
        {
            label: "New Enterpriser",
            value: "New Enterpriser",
        },
        {
            label: "Asisten Enterpriser Manager",
            value: "Asisten Enterpriser Manager",
        },
        {
            label: "Enterpriser Manager",
            value: "Enterpriser Manager",
        },
        {
            label: "Enterpriser Leader",
            value: "Enterpriser Leader",
        },
        {
            label: "Silver Leader",
            value: "Silver Leader",
        },
        {
            label: "Diamond Leader",
            value: "Diamond Leader",
        },
        {
            label: "Executive Diamond",
            value: "Executive Diamond",
        },
        {
            label: "Star Diamond",
            value: "Star Diamond",
        },
        {
            label: "Grand Diamond",
            value: "Grand Diamond",
        },
        {
            label: "Executive Crown",
            value: "Executive Crown",
        },
        {
            label: "Star Crown",
            value: "Star Crown",
        },
        {
            label: "Grand Crown",
            value: "Grand Crown",
        },
        {
            label: "Royal Crown",
            value: "Royal Crown",
        },
    ]

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (clickRef.current && !clickRef.current.contains(event.target)) {
                setShowModalAdd(null)
                popupDispatch({ type: "POPUP_END" });
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clickRef])

    const handleSubmit = async (e) => {
        e.preventDefault()

        const reqData = new FormData();
        reqData.append("name", name);
        reqData.append("level", level);
        reqData.append("status", status);

        if(photo) {
            reqData.append("photo", photo);
        }

        addSpeaker(reqData, token).then((res) => {
            toast.success(res.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
            setFetchMore(true)
            setShowModalAdd(null)
        }).catch((err) => {
            toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        })
        popupDispatch({ type: "POPUP_END" });
    }

    const handleModal = () => {
        setShowModalAdd(null)
        popupDispatch({ type: "POPUP_END" });
    }

    const handleResetImage = () => {
        setPhoto(null)
        photoRef.current.value = null;
    }
    return (
        <>
            <div  className="absolute top-0 left-0 z-10 p-3 w-screen min-h-screen overscroll-y-none overflow-y-hidden bg-black bg-opacity-70 flex items-center justify-center">
                <div ref={clickRef} className="p-3 w-11/12 lg:w-1/3 md:w-1/2 h-full bg-white rounded-md flex flex-col">
                    <div className="px-2 flex justify-between items-center">
                        <h3 className='font-bold font-ssp text-xl md:text-2xl'>Add New Speakers</h3>
                        <RiCloseFill onClick={handleModal} className='w-8 h-8 cursor-pointer'/>
                    </div>
                    <form onSubmit={handleSubmit} className='mt-5 px-5 w-full flex flex-col'>
                        {/* input name */}
                        <div className="relative z-0 mb-3 mt-3 w-full group">
                            <input 
                                minLength={5} 
                                value={name} 
                                onChange={(e) => setName(e.target.value)} 
                                type="text" 
                                name="name" 
                                id="name" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 
                                            appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                                placeholder=" " 
                                required 
                            />
                            <label 
                                htmlFor="name" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 dark:text-gray-400 
                                        duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:font-medium
                                        peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 
                                        peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Name</label>
                        </div>
                        {/* peringkat */}
                        <div className="mb-3 mt-3 w-full relative z-0 group">
                            <select 
                                required value={level} 
                                onChange={(e) => setLevel(e.target.value)} 
                                id="level" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-500 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                            focus:border-blue-600 peer"
                            >
                                <option value="">Choose a level</option>
                                {levelOptions.map((option) => (
                                    <option key={option.value} value={option.value} >{option.label} </option>
                                ))}
                            </select>
                            <label 
                                htmlFor="level" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 
                                            duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:font-medium 
                                            peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 
                                            peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Peringkat</label>
                        </div>
                        {/* status */}
                        <div className="mb-3 mt-4 w-full relative z-0 group">
                            <select required value={status} 
                                onChange={(e) => setStatus(e.target.value)} 
                                id="underline_select" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-500 bg-transparent 
                                border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                focus:border-blue-600 peer"
                            >
                                <option value="">Choose a status</option>
                                {options.map((option) => (
                                    <option key={option.value} value={option.value} >{option.label} </option>
                                ))}
                            </select>
                            <label 
                                htmlFor="underline_select" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 
                                duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Status</label>
                        </div>
                        {/* file */}
                        <div className="mb-3 mt-3 w-full relative z-0 group">
                            <label 
                                className="block mb-2 font-ssp text-sm font-medium text-gray-500" 
                                htmlFor="file_input"
                            >Upload photo</label>
                            <input 
                                type="file" 
                                name="file"
                                ref={photoRef}
                                onChange={(e) => setPhoto(e.target.files[0])}
                                accept='.png'
                                className="block w-full text-sm text-slate-500
                                    file:mr-4 file:py-2 file:px-4
                                    file:rounded-full file:border-0
                                    file:text-sm file:font-semibold
                                    file:bg-gray-50 file:text-gray-500
                                    hover:file:bg-gray-100"
                            />
                            <p className="mt-1 font-ssp text-xs text-gray-500 dark:text-gray-300" id="file_input_help">PNG (MAX. 5MB).</p>
                        </div>
                        {photo && 
                            <div className="mt-3 w-full relative flex flex-row group">
                                <img src={URL.createObjectURL(photo)} alt="Preview" className="w-1/4" />
                                <IoIosCloseCircle  fontSize='25px' className='-mt-3 -ml-3 bg-white rounded-full item-start cursor-pointer' onClick={() => handleResetImage()} />
                            </div>
                        }
                        <div className="mb-6 mt-5 w-full relative z-0 flex gap-3 justify-end">
                            <button type="submit" 
                                className="mt-5 px-5 py-2.5 w-full sm:w-auto bg-blue-400 hover:bg-blue-500 rounded-lg text-sm text-white
                                            text-center font-medium cursor-pointer"
                            >Save</button>
                            <span onClick={handleModal} 
                                className="px-5 py-2.5 mt-5 w-full sm:w-auto bg-red-400 hover:bg-red-500 rounded-lg text-sm text-white
                                            text-center font-medium cursor-pointer"
                            >Close</span>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default ModalAddSpeaker