import React, { useContext, useEffect, useState } from 'react'
import { useParams, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { AuthContext } from '../../context/authContext';
import { getUnpaidTransactions, logoutUser } from '../../utilities/sendRequest';
import { decryptData } from '../../utilities/cryptoStorage';
import { dateTimeIndo, formatRupiah } from '../../utilities/helper';

const Transactions = () => {
    const token = JSON.parse(decryptData(localStorage.getItem('cRdT')));
    const params = useParams()
    const { dispatch } = useContext(AuthContext)
    const [datas, setDatas] = useState([])
    const [fetchMore, setFetchMore] = useState(true)

    useEffect(() => {
        fetcTransactions()
        setFetchMore(false)
        // eslint-disable-next-line
    }, [params, fetchMore])

    const fetcTransactions = async () => {
        getUnpaidTransactions(token).then((res) => {
            setDatas(res.payload)
        }).catch((err) => {
            toast.error(err.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });

            if (err.response.status === 401) {
                setTimeout(() => {
                    logoutUser(dispatch)
                }, 3500)
            }
        })
    }

    const columns = [
        { 
            field: 'created_at', 
            headerName: 'Time', 
            width: 200, 
            renderCell: (params) => {
                return (
                    <div className="font-ssp">
                        {
                            dateTimeIndo(params.row.created_at)
                        }
                    </div>
                )
            } 
        },
        { 
            field: 'id_enterpriser', 
            headerName: 'ID Enterpriser',
            width: 150, 
            renderCell: (params) => {
                return (
                    <div className='flex justify-center items-center font-ssp'>
                        {params.row.enterpriser_id}
                    </div>
                )
            } 
        },
        { 
            field: 'name', 
            headerName: 'Enterpriser',
            width: 300, 
            renderCell: (params) => {
                return (
                    <div className='flex justify-center items-center font-ssp'>
                        {params.row.enterpriser_name}
                    </div>
                )
            } 
        },
        { 
            field: 'total_amount', 
            headerName: 'Total Transaksi',
            width: 200, 
            renderCell: (params) => {
                return (
                    <div className='flex justify-center items-center font-ssp'>
                        {formatRupiah(params.row.total_amount)}
                    </div>
                )
            } 
        },
        {
            field: 'status_transaction',
            headerName: 'Status',
            width: 150,
            renderCell: (params) => {
                return (
                    <div className="font-ssp">
                        <span className="px-2 py-1 bg-yellow-400 rounded-full text-white uppercase">{params.row.status_transaction}</span>
                    </div>
                )
            } 
        },
        {
            field: 'action',
            headerName: 'Action',
            width: 100,
            renderCell: (params) => {
                return (
                    <div className='w-full flex flex-row items-center gap-3'>
                        <Link to={"/transactions/detail/" + params.row.id}>
                            <VisibilityIcon fontSize='medium' className="text-slate-400 hover:text-slate-500 cursor-pointer"/>
                        </Link>
                    </div>
                )
            }
        },
    ]

    return (
        <div className='min-h-[calc(100vh-4rem)] flex flex-col'>
            {/* header  */}
            <div className="my-5 mb-5 mx-7 md:mx-5 flex flex-col md:flex-row justify-between">
                <h1 className='font-ssp font-bold text-2xl md:text-3xl'>Transactions Unpaid</h1>
            </div>
            {/* content  */}
            <div className="my-5 mb-5 mx-7 md:mx-5 flex">
                <div className="p-2 w-full bg-white drop-shadow-lg rounded-md flex">
                    <DataGrid
                        autoHeight {...datas}
                        rows={datas}
                        columns={columns}
                        pageSize={10}
                        getRowId={(row) => row.id}
                        rowsPerPageOptions={[10]}
                        checkboxSelection
                        disableSelectionOnClick
                        components={{ Toolbar: GridToolbar, }}
                    />
                </div>
            </div>
        </div>
    )

}

export default Transactions