import React, { useContext, useEffect, useState } from 'react';
import { FaUsers, FaUsersCog, FaUserTie } from "react-icons/fa";
import { MdAudiotrack } from "react-icons/md";
import { MdEventNote } from "react-icons/md";
import { FaBoxes } from "react-icons/fa";
import { FaFileInvoiceDollar } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { getDashboardLeaderboard, logoutUser, searchEnterprisers } from '../../utilities/sendRequest';
import { toast } from 'react-toastify';
import { AuthContext } from '../../context/authContext';
import { decryptData } from '../../utilities/cryptoStorage';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import VisibilityIcon from '@mui/icons-material/Visibility';
const Dashboard = () => {
    const token = JSON.parse(decryptData(localStorage.getItem('cRdT')));
    const { user, dispatch } = useContext(AuthContext)
    const [leaderboard, setLeaderboard] = useState([])
    const [datas, setDatas] = useState([])
    const [keyword, setKeyword] = useState("")
    const [regular, setRegular] = useState(0)
    const [vip, setVip] = useState(0)

    useEffect(() => {
        fetchDashboard()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const fetchDashboard = async () => {
        getDashboardLeaderboard(token).then((res)=> {
            setLeaderboard(res.payload)
            setRegular(parseInt(res.payload.total_enterprisers) - (parseInt(res.payload.total_vvip) + parseInt(res.payload.total_vip_3hari)+parseInt(res.payload.total_vip_1bulan)+parseInt(res.payload.total_vip_3bulan)+parseInt(res.payload.total_vip_6bulan)+parseInt(res.payload.total_vip_1tahun)))
            setVip(parseInt(res.payload.total_vip_3hari)+parseInt(res.payload.total_vip_1bulan)+parseInt(res.payload.total_vip_3bulan)+parseInt(res.payload.total_vip_6bulan)+parseInt(res.payload.total_vip_1tahun))
        }).catch((error) => {
            toast.error(error.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });

            if (error.response.status === 401) {
                setTimeout(() => {
                    logoutUser(dispatch)
                }, 3500)
            }
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        searchEnterprisers(keyword, token).then((res) => {
            setDatas(
                res.payload.sort((p1, p2) => {
                    return new Date(p2.created_at) - new Date(p1.created_at)
                })
            )
        }).catch((err) => {
            toast.error(err.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        })  
    }

    const handleReset = () => {
        setDatas([])
        setKeyword("")
    }

    const columns = [
        { 
            field: 'id_enterpriser', 
            headerName: 'ID Enterpriser',
            width: 150, 
            renderCell: (params) => {
                return (
                    <div className='flex justify-center items-center font-ssp'>
                        {params.row.id_enterpriser}
                    </div>
                )
            } 
        },
        { 
            field: 'name', 
            headerName: 'Fullname',
            width: 300, 
            renderCell: (params) => {
                return (
                    <div className='flex justify-center items-center font-ssp'>
                        {params.row.name}
                    </div>
                )
            } 
        },
        { 
            field: 'password', 
            headerName: 'Password',
            width: 300, 
            renderCell: (params) => {
                return (
                    <div className='flex justify-center items-center font-ssp'>
                        {params.row.password}
                    </div>
                )
            } 
        },
        { 
            field: 'roles', 
            headerName: 'Roles',
            width: 150, 
            renderCell: (params) => {
                return (
                    <div className='flex justify-center items-center font-ssp uppercase'>
                        {params.row.product_name ? params.row.product_name : params.row.roles}
                    </div>
                )
            } 
        },
        {
            field: 'login_devices',
            headerName: 'Device',
            width: 150,
            renderCell: (params) => {
                return (
                    <div className="font-ssp">
                        {params.row.login_devices
                            ? <span className="px-2 py-1 bg-blue-400 rounded-full text-white uppercase">Logged</span>
                            : <span className="px-2 py-1 bg-slate-400 rounded-full text-white uppercase">Unlogged</span>
                        }
                    </div>
                )
            } 
        },
        {
            field: 'is_active',
            headerName: 'Status',
            width: 150,
            renderCell: (params) => {
                return (
                    <div className="font-ssp">
                        {params.row.is_active === "1"
                            ? <span className="px-2 py-1 bg-green-400 rounded-full text-white uppercase">active</span>
                            : <span className="px-2 py-1 bg-red-400 rounded-full text-white uppercase">inactive</span>
                        }
                    </div>
                )
            } 
        },
        {
            field: 'action',
            headerName: 'Action',
            width: 150,
            renderCell: (params) => {
                return (
                    <div className='w-full flex flex-row items-center gap-3'>
                        {user.division === "it" && (
                            <Link to={"/enterprisers/detail/" + params.row.id}>
                                <VisibilityIcon fontSize='medium' className="text-slate-400 hover:text-slate-500 cursor-pointer"/>
                            </Link>
                        )}
                    </div>
                )
            }
        },
    ]

    return (
        <div className='min-h-[calc(100vh-4rem)] flex flex-col'>
            { user.division === "it" && (
                <div className="my-5 mx-7 md:mx-5 flex flex-wrap flex-col md:flex-row gap-5">
                    <div className="px-3 py-2 bg-white rounded-md drop-shadow-xl flex-1 flex flex-col justify-between gap-2">
                        <div className="mb-2 flex flex-row justify-between text-gray-700">
                            <div className="ml-3 flex-1 flex flex-col justify-between">
                                <span className="font-ssp font-extrabold text-4xl md:text-5xl">{leaderboard.total_employees}</span>
                                <span className="font-ssp font-medium text-sm md:text-xl tracking-wide">Employees</span>
                            </div>
                            <div className="mr-3 flex flex-1 items-center justify-end">
                                <FaUsersCog className="cursor-pointer text-7xl lg:text-5xl"/>
                            </div>
                        </div>
                        <div className="mb-2 flex justify-center">
                            <Link to={"/employees"} className="font-ssp md:text-base text-sm transition-all duration-200 ease-in-out 
                                            text-gray-400 hover:text-gray-700 font-semibold hover:underline cursor-pointer">
                                More Info
                            </Link>
                        </div>
                    </div>
                    <div className="px-3 py-2 bg-white rounded-md drop-shadow-xl flex-1 flex flex-col justify-between gap-2">
                        <div className="mb-2 flex flex-row justify-between text-gray-700">
                            <div className="ml-3 flex-1 flex flex-col justify-between">
                                <span className="font-ssp font-extrabold text-4xl md:text-5xl">{leaderboard.total_audios}</span>
                                <span className="font-ssp font-medium text-sm md:text-xl tracking-wide">Audios</span>
                            </div>
                            <div className="mr-3 flex flex-1 items-center justify-end">
                                <MdAudiotrack className="cursor-pointer text-7xl lg:text-5xl"/>
                            </div>
                        </div>
                        <div className="mb-2 flex justify-center">
                            <Link to={"/audios/players"} className="font-ssp md:text-base text-sm transition-all duration-200 ease-in-out 
                                            text-gray-400 hover:text-gray-700 font-semibold hover:underline cursor-pointer">
                                More Info
                            </Link>
                        </div>
                    </div>
                    <div className="px-3 py-2 bg-white rounded-md drop-shadow-xl flex-1 flex flex-col justify-between gap-2">
                        <div className="mb-2 flex flex-row justify-between text-gray-700">
                            <div className="ml-3 flex-1 flex flex-col justify-between">
                                <span className="font-ssp font-extrabold text-4xl md:text-5xl">{leaderboard.total_speakers}</span>
                                <span className="font-ssp font-medium text-sm md:text-xl tracking-wide">Speakers</span>
                            </div>
                            <div className="mr-3 flex flex-1 items-center justify-end">
                                <FaUserTie className="cursor-pointer text-7xl lg:text-5xl"/>
                            </div>
                        </div>
                        <div className="mb-2 flex justify-center">
                            <Link to={"/speakers"} className="font-ssp md:text-base text-sm transition-all duration-200 ease-in-out 
                                            text-gray-400 hover:text-gray-700 font-semibold hover:underline cursor-pointer">
                                More Info
                            </Link>
                        </div>
                    </div>
                    <div className="px-3 py-2 bg-white rounded-md drop-shadow-xl flex-1 flex flex-col justify-between gap-2">
                        <div className="mb-2 flex flex-row justify-between text-gray-700">
                            <div className="ml-3 flex-1 flex flex-col justify-between">
                                <span className="font-ssp font-extrabold text-4xl md:text-5xl">{leaderboard.total_enterprisers}</span>
                                <span className="font-ssp font-medium text-sm md:text-xl tracking-wide">Enterprisers</span>
                            </div>
                            <div className="mr-3 flex flex-1 items-center justify-end">
                                <FaUsers className="cursor-pointer text-7xl lg:text-5xl"/>
                            </div>
                        </div>
                        <div className="mb-2 flex justify-center">
                            <Link to={"/enterprisers/regular"} className="font-ssp md:text-base text-sm transition-all duration-200 ease-in-out 
                                            text-gray-400 hover:text-gray-700 font-semibold hover:underline cursor-pointer">
                                More Info
                            </Link>
                        </div>
                    </div>
                </div>
            )}
            { user.division === "market" && (
                <div className="my-5 mx-7 md:mx-5 flex flex-wrap flex-col md:flex-row gap-5">
                    <div className="px-3 py-2 bg-white rounded-md drop-shadow-xl flex-1 flex flex-col justify-between gap-2">
                        <div className="mb-2 flex flex-row justify-between text-gray-700">
                            <div className="ml-3 flex-1 flex flex-col justify-between">
                                <span className="font-ssp font-extrabold text-4xl md:text-5xl">{leaderboard.total_orders}</span>
                                <span className="font-ssp font-medium text-sm md:text-xl tracking-wide">Orders</span>
                            </div>
                            <div className="mr-3 flex flex-1 items-center justify-end">
                                <FaFileInvoiceDollar className="cursor-pointer text-7xl lg:text-5xl"/>
                            </div>
                        </div>
                        <div className="mb-2 flex justify-center">
                            <Link to={"/transactions"} className="font-ssp md:text-base text-sm transition-all duration-200 ease-in-out 
                                            text-gray-400 hover:text-gray-700 font-semibold hover:underline cursor-pointer">
                                More Info
                            </Link>
                        </div>
                    </div>
                    <div className="px-3 py-2 bg-white rounded-md drop-shadow-xl flex-1 flex flex-col justify-between gap-2">
                        <div className="mb-2 flex flex-row justify-between text-gray-700">
                            <div className="ml-3 flex-1 flex flex-col justify-between">
                                <span className="font-ssp font-extrabold text-4xl md:text-5xl">{leaderboard.total_tshirt}</span>
                                <span className="font-ssp font-medium text-sm md:text-xl tracking-wide">T-Shirt</span>
                            </div>
                            <div className="mr-3 flex flex-1 items-center justify-end">
                                <FaBoxes className="cursor-pointer text-7xl lg:text-5xl"/>
                            </div>
                        </div>
                        <div className="mb-2 flex justify-center">
                            <Link to={"/products"} className="font-ssp md:text-base text-sm transition-all duration-200 ease-in-out 
                                            text-gray-400 hover:text-gray-700 font-semibold hover:underline cursor-pointer">
                                More Info
                            </Link>
                        </div>
                    </div>
                    <div className="px-3 py-2 bg-white rounded-md drop-shadow-xl flex-1 flex flex-col justify-between gap-2">
                        <div className="mb-2 flex flex-row justify-between text-gray-700">
                            <div className="ml-3 flex-1 flex flex-col justify-between">
                                <span className="font-ssp font-extrabold text-4xl md:text-5xl">{leaderboard.total_merchan}</span>
                                <span className="font-ssp font-medium text-sm md:text-xl tracking-wide">Merchandise</span>
                            </div>
                            <div className="mr-3 flex flex-1 items-center justify-end">
                                <FaBoxes className="cursor-pointer text-7xl lg:text-5xl"/>
                            </div>
                        </div>
                        <div className="mb-2 flex justify-center">
                            <Link to={"/products"} className="font-ssp md:text-base text-sm transition-all duration-200 ease-in-out 
                                            text-gray-400 hover:text-gray-700 font-semibold hover:underline cursor-pointer">
                                More Info
                            </Link>
                        </div>
                    </div>
                    <div className="px-3 py-2 bg-white rounded-md drop-shadow-xl flex-1 flex flex-col justify-between gap-2">
                        <div className="mb-2 flex flex-row justify-between text-gray-700">
                            <div className="ml-3 flex-1 flex flex-col justify-between">
                                <span className="font-ssp font-extrabold text-4xl md:text-5xl">{leaderboard.total_tools}</span>
                                <span className="font-ssp font-medium text-sm md:text-xl tracking-wide">Tools</span>
                            </div>
                            <div className="mr-3 flex flex-1 items-center justify-end">
                                <FaBoxes className="cursor-pointer text-7xl lg:text-5xl"/>
                            </div>
                        </div>
                        <div className="mb-2 flex justify-center">
                            <Link to={"/products"} className="font-ssp md:text-base text-sm transition-all duration-200 ease-in-out 
                                            text-gray-400 hover:text-gray-700 font-semibold hover:underline cursor-pointer">
                                More Info
                            </Link>
                        </div>
                    </div>
                </div>
            )}
            { user.division === "event" && (
                <div className="my-5 mx-7 md:mx-5 flex flex-wrap flex-col md:flex-row gap-5">
                    <div className="px-3 py-2 bg-white rounded-md drop-shadow-xl flex-1 flex flex-col justify-between gap-2">
                        <div className="mb-2 flex flex-row justify-between text-gray-700">
                            <div className="ml-3 flex-1 flex flex-col justify-between">
                                <span className="font-ssp font-extrabold text-4xl md:text-5xl">{leaderboard.total_nationals}</span>
                                <span className="font-ssp font-medium text-sm md:text-xl tracking-wide">Active National Event</span>
                            </div>
                            <div className="mr-3 flex flex-1 items-center justify-end">
                                <MdEventNote className="cursor-pointer text-7xl lg:text-5xl"/>
                            </div>
                        </div>
                        <div className="mb-2 flex justify-center">
                            <Link to={"/nationals"} className="font-ssp md:text-base text-sm transition-all duration-200 ease-in-out 
                                            text-gray-400 hover:text-gray-700 font-semibold hover:underline cursor-pointer">
                                More Info
                            </Link>
                        </div>
                    </div>
                    <div className="px-3 py-2 bg-white rounded-md drop-shadow-xl flex-1 flex flex-col justify-between gap-2">
                        <div className="mb-2 flex flex-row justify-between text-gray-700">
                            <div className="ml-3 flex-1 flex flex-col justify-between">
                                <span className="font-ssp font-extrabold text-4xl md:text-5xl">{leaderboard.total_dailys}</span>
                                <span className="font-ssp font-medium text-sm md:text-xl tracking-wide">Active Daily Event</span>
                            </div>
                            <div className="mr-3 flex flex-1 items-center justify-end">
                                <MdEventNote className="cursor-pointer text-7xl lg:text-5xl"/>
                            </div>
                        </div>
                        <div className="mb-2 flex justify-center">
                            <Link to={"/dailys"} className="font-ssp md:text-base text-sm transition-all duration-200 ease-in-out 
                                            text-gray-400 hover:text-gray-700 font-semibold hover:underline cursor-pointer">
                                More Info
                            </Link>
                        </div>
                    </div>
                    <div className="px-3 py-2 bg-white rounded-md drop-shadow-xl flex-1 flex flex-col justify-between gap-2">
                        <div className="mb-2 flex flex-row justify-between text-gray-700">
                            <div className="ml-3 flex-1 flex flex-col justify-between">
                                <span className="font-ssp font-extrabold text-4xl md:text-5xl">{leaderboard.total_speakers}</span>
                                <span className="font-ssp font-medium text-sm md:text-xl tracking-wide">Speakers</span>
                            </div>
                            <div className="mr-3 flex flex-1 items-center justify-end">
                                <FaUserTie className="cursor-pointer text-7xl lg:text-5xl"/>
                            </div>
                        </div>
                        <div className="mb-2 flex justify-center">
                            <Link to={"/speakers"} className="font-ssp md:text-base text-sm transition-all duration-200 ease-in-out 
                                            text-gray-400 hover:text-gray-700 font-semibold hover:underline cursor-pointer">
                                More Info
                            </Link>
                        </div>
                    </div>
                </div>
            )}
            
            {/* search  */}
            <div className="flex my-5 mx-7 md:mx-5 flex-col md:flex-row gap-3 justify-between">
                <div className="p-2 w-full md:w-[30%] h-full bg-white rounded-md drop-shadow-lg flex flex-col gap-3">
                    <h1 className='font-semibold text-base font-ssp px-3'>Search Enterpriser</h1>
                    <hr className='px-3'/>
                    <form 
                        onSubmit={handleSubmit}
                        className="px-3 w-full flex flex-col"
                    >
                        {/* keyword */}
                        <div className="relative z-0 mb-3 mt-3 w-full group">
                            <input 
                                minLength={3} 
                                value={keyword} 
                                onChange={(e) => setKeyword(e.target.value)} 
                                type="text" 
                                name="keyword" 
                                id="keyword" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                                placeholder=" " 
                                required 
                            />
                            <label 
                                htmlFor="keyword" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >ID / Name Enterpriser</label>
                        </div>
                            
                        {/* button  */}
                        <div className="relative z-0 mb-6 mt-5 w-full flex justify-end gap-3">
                            <button 
                                type="submit" 
                                className="mt-5 px-5 py-2.5 w-full sm:w-auto bg-green-400 hover:bg-green-500 rounded-lg text-white font-medium text-sm text-center"
                            >Search</button>
                            <span 
                                onClick={handleReset} 
                                className="mt-5 px-5 py-2.5 w-full sm:w-auto bg-red-400 hover:bg-red-500 rounded-lg font-medium text-sm text-white text-center cursor-pointer"
                            >Reset</span>
                        </div>
                    </form>
                </div>
                <div className="p-2 w-full md:w-[60%] h-full bg-white rounded-md drop-shadow-lg flex flex-col gap-3">
                    <div className="overflow-x-auto shadow-md">
                        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                <tr>
                                    <th scope="col" className="px-6 py-3">
                                        Keterangan
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Jumlah
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                        Total Pengguna Apps VIP 3 Hari	
                                    </th>
                                    <td className="px-6 py-4">
                                        {leaderboard.total_vip_3hari ? leaderboard.total_vip_3hari : 0}
                                    </td>
                                </tr>
                                <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                        Total Pengguna Apps VIP 1 Bulan	
                                    </th>
                                    <td className="px-6 py-4">
                                        {leaderboard.total_vip_1bulan ? leaderboard.total_vip_1bulan : 0}
                                    </td>
                                </tr>
                                <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                        Total Pengguna Apps VIP 3 Bulan	
                                    </th>
                                    <td className="px-6 py-4">
                                        {leaderboard.total_vip_3bulan ? leaderboard.total_vip_3bulan : 0}
                                    </td>
                                </tr>
                                <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                        Total Pengguna Apps VIP 6 Bulan	
                                    </th>
                                    <td className="px-6 py-4">
                                        {leaderboard.total_vip_6bulan ? leaderboard.total_vip_6bulan : 0}
                                    </td>
                                </tr>
                                <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                        Total Pengguna Apps VIP 1 Tahun	
                                    </th>
                                    <td className="px-6 py-4">
                                        {leaderboard.total_vip_1tahun ? leaderboard.total_vip_1tahun : 0}
                                    </td>
                                </tr>
                                <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                        Total Keseluruhan Pengguna VIP
                                    </th>
                                    <td className="px-6 py-4">
                                        {vip}
                                    </td>
                                </tr>
                                <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                        Total Keseluruhan Pengguna VVIP
                                    </th>
                                    <td className="px-6 py-4">
                                        {leaderboard.total_vvip ? leaderboard.total_vvip : 0}
                                    </td>
                                </tr>
                                <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                        Total Keseluruhan Pengguna Regular
                                    </th>
                                    <td className="px-6 py-4">
                                        {regular}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div className="my-5 mb-5 mx-7 md:mx-5 flex">
                <div className="p-2 w-full bg-white drop-shadow-lg rounded-md flex">
                    <DataGrid
                        autoHeight {...datas}
                        rows={datas}
                        columns={columns}
                        pageSize={10}
                        getRowId={(row) => row.id}
                        rowsPerPageOptions={[10]}
                        checkboxSelection
                        disableSelectionOnClick
                        components={{ Toolbar: GridToolbar, }}
                    />
                </div>
            </div>

            <div className="my-5 mb-5 mx-7 md:mx-5 flex">
            </div>
        </div>
    )
}

export default Dashboard