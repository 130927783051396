import React, { useEffect, useState, useContext, useRef } from 'react';
import { toast } from 'react-toastify';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { IoIosCloseCircle } from 'react-icons/io';
import { PopupContext } from "../../context/popupContext";
import { AuthContext } from '../../context/authContext';
import { logoutUser, addMarketPlace, getMarketPlaces, toggleMarketPlace } from '../../utilities/sendRequest';
import { decryptData } from '../../utilities/cryptoStorage';
import ModalDetail from './modalDetail';

const MarketPlaces = () => {
    const token = JSON.parse(decryptData(localStorage.getItem('cRdT')));
    const { dispatch } = useContext(AuthContext)
    const { popupDispatch } = useContext(PopupContext)
    const [datas, setDatas] = useState([])
    const [data, setData] = useState({})
    const [showModalDetail, setShowModalDetail] = useState(false)
    const [fetchMore, setFetchMore] = useState(true)
    const [title, setTitle] = useState("")
    const [link, setLink] = useState("")
    const [description, setDescription] = useState("")
    const [status, setStatus] = useState("")
    const [icon, setIcon] = useState(null)
    const iconRef = useRef(null); 
    const options = [
        {
            label: "ACTIVE",
            value: 1
        },
        {
            label: "INACTIVE",
            value: 0
        },
    ]

    useEffect(() => {
        fetchMarketPlaces()
        setFetchMore(false)
        // eslint-disable-next-line
    }, [fetchMore])

    const fetchMarketPlaces = async () => {
        getMarketPlaces(token).then((res) => {
            setDatas(res.payload)
        }).catch((err) => {
            toast.error(err.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });

            if (err.response.status === 401) {
                setTimeout(() => {
                    logoutUser(dispatch)
                }, 3500)
            }
        })
    }

    const handleToggle = (marketPlaceId) => {
        toggleMarketPlace(marketPlaceId, token).then((res) => {
            toast.success(res.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
            setFetchMore(true)
        }).catch((err) => {
            toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });

            if (err.response.status === 401) {
                setTimeout(() => {
                    logoutUser(dispatch)
                }, 3500)
            }
        })
    }

    const handleModal = () => {
    }

    const handleDetail = (data) => {
        setShowModalDetail(true)
        setData(data)
        popupDispatch({ type: "POPUP_START" });
    }

    const columns = [
        { 
            field: 'title', 
            headerName: 'Title',
            width: 350, 
            renderCell: (params) => {
                return (
                    <div className='flex justify-center items-center font-ssp'>
                        {params.row.title}
                    </div>
                )
            } 
        },
        { 
            field: 'icon', 
            headerName: 'Icon',
            width: 100, 
            renderCell: (params) => {
                return (
                    <div className='flex justify-center items-center font-ssp'>
                        {params.row.icon ? (
                            <div className='w-8 h-8 object-cover object-top bg-slate-400'>
                                <img className='w-8 h-8 object-cover object-top' src={`${process.env.REACT_APP_PUBLIC_FOLDER_MARKET_PLACES_ICONS}/${params.row.icon}`} alt={params.row.title} />
                            </div>
                        ) : (
                            <div className='w-8 h-8 object-cover object-top bg-slate-400'></div>
                        )}        
                    </div>
                )
            } 
        },
        {
            field: 'is_active',
            headerName: 'Status',
            width: 150,
            renderCell: (params) => {
                return (
                    <div className="font-ssp">
                        {params.row.is_active === "1"
                            ? <span className="px-2 py-1 bg-green-400 rounded-full text-white uppercase">active</span>
                            : <span className="px-2 py-1 bg-red-400 rounded-full text-white uppercase">inactive</span>
                        }
                    </div>
                )
            } 
        },
        {
            field: 'action',
            headerName: 'Action',
            width: 150,
            renderCell: (params) => {
                return (
                    <div className='w-full flex flex-row items-center gap-3'>
                        {/* <Link to={"/productsCategories/" + params.row.id}> */}
                            <VisibilityIcon onClick={() => handleDetail(params.row)} fontSize='medium' className="text-slate-400 hover:text-slate-500 cursor-pointer"/>
                        {/* </Link> */}
                        {params.row.is_active === "1" 
                            ? <ToggleOnIcon className="text-green-400 hover:text-green-500 cursor-pointer" onClick={() => handleToggle(params.row.id)}/>
                            : <ToggleOffIcon className="text-gray-400 hover:text-gray-500 cursor-pointer" onClick={() => handleToggle(params.row.id)}/>
                        }
                    </div>
                )
            }
        },
    ]

    const handleSubmit = async (e) => {
        e.preventDefault()

        const reqData = new FormData();
        reqData.append("title", title);
        reqData.append("description", description);
        reqData.append("link", link);
        reqData.append("status", status);

        if (icon) {
            reqData.append("icon", icon);
        }

        addMarketPlace(reqData, token).then((res) => {
            toast.success(res.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
            setFetchMore(true)
            setTitle("")
            setDescription("")
            setLink("")
            setStatus("")
            handleResetPreview()
        }).catch((err) => {
            toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        })
    }

    const handleResetPreview = () => {
        setIcon(null)
        iconRef.current.value = null;
    }

    return (
        <>
            {showModalDetail && 
                <ModalDetail setShowModalDetail={setShowModalDetail} setFetchMore={setFetchMore} data={data} />
            }
            <div className={`min-h-[calc(100vh-4rem)] flex flex-col ${showModalDetail && 'fixed min-w-[calc(100vw-16.666667%)]'} `}>
                {/* header  */}
                <div className="my-5 mb-5 mx-7 md:mx-5 flex flex-col md:flex-row justify-between">
                    <h1 className='font-ssp font-bold text-2xl md:text-3xl'>Market Places</h1>
                    <div onClick={()=> handleModal()} className="px-5 py-2.5 mr-3 mt-3 md:mt-1 bg-blue-400 hover:bg-blue-500 rounded-lg flex text-sm font-medium self-start md:self-end cursor-pointer">
                        <span className='text-sm text-white font-ssp'>Add New</span>
                    </div>
                </div>
                {/* content  */}
                <div className="my-5 mb-5 mx-7 md:mx-5 flex justify-between flex-col-reverse md:flex-row gap-5 md:gap-0">
                    <div className="p-2 w-full md:w-[67%] bg-white rounded-md drop-shadow-lg flex flex-col gap-3">
                        <h1 className='font-semibold text-base font-ssp'>List Market Places</h1>
                        <DataGrid
                            autoHeight {...datas}
                            rows={datas}
                            columns={columns}
                            pageSize={5}
                            getRowId={(row) => row.id}
                            rowsPerPageOptions={[5]}
                            checkboxSelection
                            disableSelectionOnClick
                            components={{ Toolbar: GridToolbar, }}
                        />
                    </div>
                    <div className="p-2 w-full md:w-[30%] h-full bg-white rounded-md drop-shadow-lg flex flex-col gap-3">
                        <h1 className='font-semibold text-base font-ssp px-3'>Add New Market Places</h1>
                        <hr className='px-3'/>
                        <form onSubmit={handleSubmit} className="px-3 w-full flex flex-col">
                            {/* title */}
                            <div className="relative z-0 mb-3 mt-3 w-full group">
                                <input 
                                    minLength={3} 
                                    value={title} 
                                    onChange={(e) => setTitle(e.target.value)} 
                                    type="text" 
                                    name="title" 
                                    id="title" 
                                    className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                                    placeholder=" " 
                                    required 
                                />
                                <label 
                                    htmlFor="title" 
                                    className="absolute top-3 -z-10 text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                >Title</label>
                            </div>
                            {/* description */}
                            <div className="relative z-0 mb-3 mt-3 w-full group">
                                <input 
                                    minLength={3} 
                                    value={description} 
                                    onChange={(e) => setDescription(e.target.value)} 
                                    type="text" 
                                    name="description" 
                                    id="description" 
                                    className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                                    placeholder=" "  
                                />
                                <label 
                                    htmlFor="description" 
                                    className="absolute top-3 -z-10 text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                >Description</label>
                            </div>
                            {/* link */}
                            <div className="relative z-0 mb-3 mt-3 w-full group">
                                <input 
                                    minLength={3} 
                                    value={link} 
                                    onChange={(e) => setLink(e.target.value)} 
                                    type="text" 
                                    name="link" 
                                    id="link" 
                                    className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                                    placeholder=" " 
                                    required 
                                />
                                <label 
                                    htmlFor="link" 
                                    className="absolute top-3 -z-10 text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                >Link</label>
                            </div>
                            {/* status */}
                            <div className="relative z-0 mb-3 mt-4 w-full group">
                                <select required value={status} 
                                    onChange={(e) => setStatus(e.target.value)} 
                                    id="status_select" 
                                    className="block py-2.5 px-2 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                >
                                    <option value="">Choose a status</option>
                                    {options.map((option) => (
                                        <option key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                                <label 
                                    htmlFor="status_select" 
                                    className="absolute top-3 -z-10 text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                >Status</label>
                            </div>
                            {/* icon */}
                            <div className="mb-3 mt-3 w-full relative z-0 group">
                                <label 
                                    className="block mb-2 font-ssp text-sm font-medium text-gray-500" 
                                    htmlFor="file_input"
                                >Upload Icon</label>
                                <input 
                                    type="file" 
                                    name="icon"
                                    ref={iconRef}
                                    onChange={(e) => setIcon(e.target.files[0])}
                                    accept='.png'
                                    required
                                    className="block w-full text-sm text-slate-500
                                        file:mr-4 file:py-2 file:px-4
                                        file:rounded-full file:border-0
                                        file:text-sm file:font-semibold
                                        file:bg-gray-50 file:text-gray-500
                                        hover:file:bg-gray-100"
                                />
                                <p className="mt-1 font-ssp text-xs text-gray-500 dark:text-gray-300" id="file_input_help">PNG File (MAX. 5MB).</p>
                            </div>
                            {icon && 
                                <div className="mt-3 w-full relative flex flex-row group">
                                    <img src={URL.createObjectURL(icon)} alt="Preview" className="w-1/4" />
                                    <IoIosCloseCircle  fontSize='25px' className='-mt-3 -ml-3 bg-white rounded-full item-start cursor-pointer' onClick={handleResetPreview} />
                                </div>
                            }
                            {/* button  */}
                            <div className="relative z-0 mb-6 mt-5 w-full flex justify-end gap-3">
                                <button 
                                    type="submit" 
                                    className="mt-5 px-5 py-2.5 w-full sm:w-auto bg-blue-400 hover:bg-blue-500 rounded-lg text-white font-medium text-sm text-center"
                                >Save</button>
                                <span 
                                    onClick={handleModal} 
                                    className="mt-5 px-5 py-2.5 w-full sm:w-auto bg-red-400 hover:bg-red-500 rounded-lg font-medium text-sm text-white text-center cursor-pointer"
                                >Close</span>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MarketPlaces