import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { IoIosCloseCircle } from "react-icons/io";
import { getContentCategories, getContent, updateContent } from '../../utilities/sendRequest';
import { decryptData } from '../../utilities/cryptoStorage';
import { getYoutubeEmbedLink } from '../../utilities/youtubeEmbed';

const DetailBill = () => {
    const token = JSON.parse(decryptData(localStorage.getItem('cRdT')));
    const params = useParams()
    const navigate = useNavigate()
    const contentRef = useRef(null); 
    const thumbnailRef = useRef(null); 
    const [data, setData] = useState({})
    const [title, setTitle] = useState("")
    const [type, setType] = useState("")
    const [textContent, setTextContent] = useState("")
    const [category, setCategory] = useState("")
    const [categories, setCategories] = useState([])
    const [status, setStatus] = useState("")
    const [imageContent, setImageContent] = useState(null)
    const [thumbnail, setThumbnail] = useState(null)
    const optionStatus = [
        {
            label: "Choose a Status",
            value: "",
        },
        {
            label: "ACTIVE",
            value: 1,
        },
        {
            label: "INACTIVE",
            value: 0,
        },
    ]
    const optionType = [
        {
            label: "IMAGE",
            value: 0,
        },
        {
            label: "VIDEO",
            value: 1,
        },
    ]

    useEffect(() => {
        fetchContent()
        fetchCategories()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const fetchCategories = async () => {
        getContentCategories(token).then((res) => {
            setCategories(res.payload)
        }).catch((err) => {
            toast.error(err.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        })
    }

    const fetchContent = async () => {
        getContent(params.id, token).then((res) => {
            setData(res.payload)
            setTitle(res.payload.title)
            setType(res.payload.is_video)
            setCategory(res.payload.category_id)
            setStatus(res.payload.is_active)
            res.payload.is_video === "1" && setTextContent(res.payload.content)
        }).catch((err) => {
            toast.error(err, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        })  
    }

    const hanldeUpdate = async (e) => {
        e.preventDefault()

        const reqData = new FormData();
        reqData.append("title", title);
        reqData.append("type", type);
        reqData.append("category", category);
        reqData.append("status", status);
        reqData.append("textContent", textContent);

        if (thumbnail) {
            reqData.append("thumbnail", thumbnail);
        }

        if (imageContent) {
            setTextContent("")
            reqData.append("imageContent", imageContent);
        }

        updateContent(params.id, reqData, token).then((res) => {
            toast.success(res.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });

            fetchContent()
            handleResetPreview(3)
        }).catch((err) => {
            toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        })
    }

    const handleResetPreview = (id) => {
        if (id === 1) {
            setImageContent(null)
            contentRef.current.value = null;
        } else if (id === 2) {
            setThumbnail(null)
            thumbnailRef.current.value = null;
        } else if (id === 3) {
            if (imageContent) {
                setImageContent(null)
                contentRef.current.value = null;
            }

            setThumbnail(null)
            thumbnailRef.current.value = null;
        }
    }

    return (
        <div className='min-h-[calc(100vh-4rem)] flex flex-col'>
            {/* header  */}
            <div className="my-5 mx-7 md:mx-5 flex flex-row justify-between">
                <h1 className='font-ssp font-bold text-4xl md:text-3xl'>Detail Content</h1>
            </div>
            {/* content */}
            <div className="my-5 mx-7 md:mx-5 flex flex-col lg:flex-row justify-evenly gap-7 md:gap-10">
                {/* detail content */}
                <div className="w-full h-full lg:w-1/4 md:w-1/2 flex flex-col gap-3 items-center">
                    {data.thumbnail && 
                        <div className='px-3 py-4 w-full h-full bg-white drop-shadow-lg rounded-md flex flex-col gap-2'>
                            <h3 className='text-lg font-ssp font-semibold text-center'>Thumbnail</h3>
                            <img src={`${process.env.REACT_APP_PUBLIC_FOLDER_BILL_CONTENT_THUMBNAILS}/${data.thumbnail}`} alt={data.title} />
                        </div>
                    }

                    {(data.is_video === "1") ? (
                            <div className='px-3 py-4 w-full h-full bg-white drop-shadow-lg rounded-md flex flex-col gap-2'>
                                {/* video */}
                                <h3 className='text-lg font-ssp font-semibold text-center'>Video Content</h3>
                                <iframe 
                                    className='w-full h-full'
                                    title="YouTube Video"
                                    src={getYoutubeEmbedLink(data.content)}
                                    >
                                </iframe>
                            </div>
                        ) : (
                            <div className='px-3 py-4 w-full h-full bg-white drop-shadow-lg rounded-md flex flex-col gap-2'>
                                {/* gambar*/}
                                <h3 className='text-lg font-ssp font-semibold text-center'>Image Content</h3>
                                <img src={`${process.env.REACT_APP_PUBLIC_FOLDER_BILL_CONTENT_CONTENTS}/${data.content}`} alt={data.title} />
                            </div>
                        )
                    }
                </div>
                {/* form update */}
                <div className="px-2 py-4 w-full lg:w-2/3 md:w-10/12 h-full bg-white drop-shadow-lg rounded-md flex">
                    <form onSubmit={hanldeUpdate} className='px-5 w-full flex flex-wrap justify-between'>
                        {/* title */}
                        <div className="mt-3 mb-6 w-full md:w-[45%] relative z-0 group">
                            <input 
                                minLength={3} 
                                value={title} 
                                onChange={(e) => setTitle(e.target.value)} 
                                type="text" 
                                name="title" 
                                id="title" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                            focus:border-blue-600 peer" 
                                placeholder=" " 
                                required 
                            />
                            <label 
                                htmlFor="title" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 duration-300 transform -translate-y-6 
                                            scale-75 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:font-medium 
                                            peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Title</label>
                        </div>
                        {/* category */}
                        <div className="mb-6 mt-3 w-full md:w-[45%] relative z-0 group">
                            <select 
                                required value={category} 
                                onChange={(e) => setCategory(e.target.value)} 
                                id="category" 
                                name="category" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-500 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                            focus:border-blue-600 peer"
                            >
                                {categories.map((option) => (
                                    <option key={option.id} value={option.id}>{option.name}</option>
                                ))}
                            </select>
                            <label 
                                htmlFor="category" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 
                                            duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                            peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                            peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Category</label>
                        </div>
                        {/* status */}
                        <div className="mb-6 mt-3 w-full md:w-[45%] relative z-0 group">
                            <select 
                                required 
                                value={status} 
                                onChange={(e) => setStatus(e.target.value)} 
                                id="status" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-500 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                            focus:border-blue-600 peer"
                            >
                                {optionStatus.map((option) => (
                                    <option key={option.value} value={option.value}>{option.label}</option>
                                ))}
                            </select>
                            <label 
                                htmlFor="status" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 
                                            duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:font-medium 
                                            peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 
                                            peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Status</label>
                        </div>
                        {/* type */}
                        <div className="mt-3 mb-6 w-full md:w-[45%] relative z-0 group">
                            <select 
                                required 
                                value={type} 
                                onChange={(e) => setType(e.target.value)} 
                                id="type" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-500 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                            focus:border-blue-600 peer"
                            >
                                {optionType.map((option) => (
                                    <option key={option.value} value={option.value}>{option.label}</option>
                                ))}
                            </select>
                            <label 
                                htmlFor="type" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 
                                            duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                            peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                            peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Type</label>
                        </div>
                        {/* video */}
                        {(type === "1") ? (
                                <div className="mt-3 mb-6 w-full md:w-[45%] relative z-0 group">
                                    <input 
                                        minLength={5} 
                                        value={textContent} 
                                        onChange={(e) => setTextContent(e.target.value)} 
                                        type="text" 
                                        name="content" 
                                        id="content" 
                                        className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                                    border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                                    focus:border-blue-600 peer" 
                                        placeholder=" " 
                                        required 
                                    />
                                    <label 
                                        htmlFor="content" 
                                        className="absolute top-3 -z-10 text-sm text-gray-500 
                                                    duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                                    peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                                    peer-focus:scale-75 peer-focus:-translate-y-6"
                                    >Content (Link Video)</label>
                                </div>
                        ) : (
                            <>
                                <div className="mt-3 mb-3 w-full relative z-0 group">
                                    <label 
                                        className="block mb-2 font-ssp text-sm font-medium text-gray-500" 
                                        htmlFor="file_image"
                                    >Upload Image</label>
                                    <input 
                                        type="file" 
                                        id="imageContent"
                                        name="imageContent"
                                        ref={contentRef}
                                        onChange={(e) => setImageContent(e.target.files[0])}
                                        accept='.png,.jpg,.jpeg'
                                        className="block w-full text-sm text-slate-500
                                                    file:mr-4 file:py-2 file:px-4
                                                    file:rounded-full file:border-0
                                                    file:text-sm file:font-semibold
                                                    file:bg-gray-50 file:text-gray-500
                                                    hover:file:bg-gray-100"
                                    />
                                    <p className="mt-1 font-ssp text-xs text-gray-500 dark:text-gray-300" id="file_image_help">PNG, JPG, JPEG (MAX. 5MB).</p>
                                </div>
                                {imageContent && 
                                    <div className="mt-3 w-full relative flex flex-row group">
                                        <img src={URL.createObjectURL(imageContent)} alt="Preview" className="w-1/4" />
                                        <IoIosCloseCircle 
                                            fontSize='25px' 
                                            className='-mt-3 -ml-3 bg-white rounded-full item-start cursor-pointer' 
                                            onClick={() => handleResetPreview(1)} />
                                    </div>
                                }
                                </>
                            )
                        }

                        {/* thumbnail*/}
                        <div className="mb-6 mt-3 w-full relative z-0 group">
                            <label className="block mb-2 font-ssp text-xs font-medium text-gray-500" htmlFor="photo1">New Thumbnail</label>
                            <input 
                                type="file" 
                                name="thumbnail"
                                id="thumbnail"
                                ref={thumbnailRef}
                                onChange={(e) => setThumbnail(e.target.files[0])}
                                accept='.png, .jpg, .jpeg'
                                className="block w-full text-sm text-slate-500
                                    file:mr-4 file:py-2 file:px-4
                                    file:rounded-full file:border-0
                                    file:text-sm file:font-semibold
                                    file:bg-gray-50 file:text-gray-500
                                    hover:file:bg-gray-100"
                            />
                            <p className="mt-1 font-ssp text-xs text-gray-500 dark:text-gray-300" id="photo1">PNG, JPG, JPEG (MAX. 5MB).</p>
                        </div>
                        {thumbnail && 
                            <div className="mt-3 w-full relative flex flex-row group">
                                <img src={URL.createObjectURL(thumbnail)} alt="Preview" className="w-1/4" />
                                <IoIosCloseCircle fontSize='25px' className='-mt-3 -ml-3 item-start bg-white rounded-full cursor-pointer' onClick={() => handleResetPreview(2)} />
                            </div>
                        }
                        {/* button */}
                        <div className="mb-6 w-full relative z-0 flex justify-center gap-5">
                            <button 
                                type="submit" 
                                className="px-5 py-2.5 mt-5 w-full h-fit sm:w-auto bg-green-400 hover:bg-green-500 rounded-lg 
                                            font-medium text-sm text-center text-white cursor-pointer "
                            >Update</button>
                            <span 
                                onClick={() => navigate("/contents")} 
                                className="px-5 py-2.5 mt-5 w-full h-fit sm:w-auto bg-red-400 hover:bg-red-500 rounded-lg 
                                            font-medium text-sm text-center text-white cursor-pointer"
                            >Back</span>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default DetailBill