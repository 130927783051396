import React, { useEffect, useRef, useState, useContext } from 'react';
import { toast } from 'react-toastify';
import { RiCloseFill } from "react-icons/ri";
import { IoIosCloseCircle } from "react-icons/io";
import { PopupContext } from "../../context/popupContext";
import { AuthContext } from '../../context/authContext';
import { addPost, logoutUser } from '../../utilities/sendRequest';
import { decryptData } from '../../utilities/cryptoStorage';

const ModalAddPost = ({ setShowModalAdd, setFetchMore }) => {
    const token = JSON.parse(decryptData(localStorage.getItem('cRdT')));
    const { dispatch } = useContext(AuthContext)
    const { popupDispatch } = useContext(PopupContext)
    const clickRef = useRef(null)
    const thumbnailRef = useRef(null)
    const contentRef = useRef(null)
    const [thumbnail, setThumbnail] = useState(null)
    const [imageContent, setImageContent] = useState(null)
    const [title, setTitle] = useState("")
    const [scheduleDate, setScheduleDate] = useState("")
    const [description, setDescription] = useState("")
    const [textContent, setTextContent] = useState("")
    const [status, setStatus] = useState("")
    const [type, setType] = useState("")

    const options = [
        {
            label: "ACTIVE",
            value: 1
        },
        {
            label: "INACTIVE",
            value: 0
        },
    ]
    const typeOptions = [
        {
            label: "Video",
            value: 1
        },
        {
            label: "Image",
            value: 0
        },
    ]

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (clickRef.current && !clickRef.current.contains(event.target)) {
                setShowModalAdd(null)
                popupDispatch({ type: "POPUP_END" });
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clickRef])

    const handleSubmit = async (e) => {
        e.preventDefault()

        const reqData = new FormData();
        reqData.append("title", title);
        reqData.append("description", description);
        reqData.append("scheduleDate", scheduleDate);
        reqData.append("status", status);
        reqData.append("type", type);
        reqData.append("textContent", textContent);

        if(thumbnail) {
            reqData.append("thumbnail", thumbnail);
        }

        if(imageContent) {
            reqData.append("imageContent", imageContent);
        }

        addPost(reqData, token).then((res) => {
            toast.success(res.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
            setFetchMore(true)
            setShowModalAdd(null)
            handleResetPreview(3)
        }).catch((error) => {
            toast.error(error.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });

            if (error.response.status === 401) {
                setTimeout(() => {
                    logoutUser(dispatch)
                }, 3500)
            }
        })
        popupDispatch({ type: "POPUP_END" });
    }

    const handleResetPreview = (id) => {
        if (id === 1) {
            setImageContent(null)
            contentRef.current.value = null;
        } else if (id === 2) {
            setThumbnail(null)
            thumbnailRef.current.value = null;
        } else if (id === 3) {
            if (imageContent) {
                setImageContent(null)
                contentRef.current.value = null;
            }

            setThumbnail(null)
            thumbnailRef.current.value = null;
        }
    }

    const handleModal = () => {
        setShowModalAdd(null)
        popupDispatch({ type: "POPUP_END" });
    }

    return (
        <>
            <div className='absolute top-0 left-0 z-10 p-3 w-screen min-h-screen overscroll-y-none overflow-y-hidden bg-black bg-opacity-70 flex items-center justify-center'>
                <div ref={clickRef} className="p-3 w-11/12 lg:w-1/3 md:w-1/2 h-fit bg-white rounded-md flex flex-col">
                    <div className="px-2 flex justify-between items-center">
                        <h3 className='font-bold font-ssp text-xl md:text-2xl'>Add New Posts</h3>
                        <RiCloseFill onClick={handleModal} className='w-8 h-8 cursor-pointer'/>
                    </div>
                    <form onSubmit={handleSubmit} className='mt-5 px-5 w-full flex flex-col'>
                        {/* title */}
                        <div className="mt-3 mb-3 w-full relative z-0 group">
                            <input 
                                minLength={5} 
                                value={title} 
                                onChange={(e) => setTitle(e.target.value)} 
                                type="text" 
                                name="title" 
                                id="title" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                            focus:border-blue-600 peer" 
                                placeholder=" " 
                                required 
                                />
                            <label 
                                htmlFor="title" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 dark:text-gray-400 
                                            duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                            peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                            peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Title</label>
                        </div>
                        {/* description */}
                        <div className="mt-3 mb-3 w-full relative z-0 group">
                            <input 
                                minLength={5} 
                                value={description} 
                                onChange={(e) => setDescription(e.target.value)} 
                                type="text" 
                                name="description" 
                                id="description" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                            focus:border-blue-600 peer" 
                                placeholder=" " 
                            />
                            <label htmlFor="description" className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Description</label>
                        </div>
                        {/* publish */}
                        <div className="mt-3 mb-3 w-full relative z-0 group">
                            <input 
                                minLength={5} 
                                value={scheduleDate} 
                                onChange={(e) => setScheduleDate(e.target.value)} 
                                type="date" 
                                name="scheduleDate" 
                                id="scheduleDate" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                            focus:border-blue-600 peer" 
                                placeholder=" " 
                                required 
                            />
                            <label 
                                htmlFor="scheduleDate" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 dark:text-gray-400 
                                            duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                            peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                            peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Publish Date</label>
                        </div>
                        {/* status */}
                        <div className="mb-3 mt-4 w-full relative z-0 group">
                            <select 
                                required 
                                value={status} 
                                onChange={(e) => setStatus(e.target.value)} 
                                id="status" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-500 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                            focus:border-blue-600 peer"
                            >
                                <option value="">Choose a status</option>
                                {options.map((option) => (
                                    <option key={option.value} value={option.value} >{option.label} </option>
                                ))}
                            </select>
                            <label 
                                htmlFor="status" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 duration-300 
                                            transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:font-medium 
                                            peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Status</label>
                        </div>
                        {/* type */}
                        <div className="mb-3 mt-4 w-full relative z-0 group">
                            <select 
                                required 
                                value={type} 
                                onChange={(e) => setType(e.target.value)} 
                                id="type" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-500 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                            focus:border-blue-600 peer"
                            >
                                <option value="">Choose a type</option>
                                {typeOptions.map((option) => (
                                    <option key={option.value} value={option.value} >{option.label}</option>
                                ))}
                            </select>
                            <label 
                                htmlFor="type" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 duration-300 
                                            transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:font-medium 
                                            peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Type</label>
                        </div>

                        {/* text content */}
                        {type === "1" &&
                            <div className="mt-3 mb-3 w-full relative z-0 group">
                                <input 
                                    minLength={5} 
                                    value={textContent} 
                                    onChange={(e) => setTextContent(e.target.value)} 
                                    type="text" 
                                    name="textContent" 
                                    id="textContent" 
                                    className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                                border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                                focus:border-blue-600 peer" 
                                    placeholder=" " 
                                    required 
                                />
                                <label 
                                    htmlFor="textContent" 
                                    className="absolute top-3 -z-10 text-sm text-gray-500 dark:text-gray-400 
                                    duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:font-medium 
                                    peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                >Link Youtube</label>
                            </div>
                        }

                        {/* image */}
                        {type === "0" &&
                            <>
                                <div className="mt-3 mb-3 w-full relative z-0 group">
                                    <label 
                                        className="block mb-2 font-ssp text-sm font-medium text-gray-500" 
                                        htmlFor="file_image"
                                    >Upload Image</label>
                                    <input 
                                        type="file" 
                                        name="image"
                                        ref={contentRef}
                                        onChange={(e) => setImageContent(e.target.files[0])}
                                        accept='.png,.jpg,.jpeg'
                                        className="block w-full text-sm text-slate-500
                                                    file:mr-4 file:py-2 file:px-4
                                                    file:rounded-full file:border-0
                                                    file:text-sm file:font-semibold
                                                    file:bg-gray-50 file:text-gray-500
                                                    hover:file:bg-gray-100"
                                    />
                                    <p className="mt-1 font-ssp text-xs text-gray-500 dark:text-gray-300" id="file_image_help">PNG, JPG, JPEG (MAX. 5MB).</p>
                                </div>
                                {imageContent && 
                                    <div className="mt-3 w-full relative flex flex-row group">
                                        <img src={URL.createObjectURL(imageContent)} alt="Preview" className="w-1/4" />
                                        <IoIosCloseCircle 
                                            fontSize='25px' 
                                            className='-mt-3 -ml-3 bg-white rounded-full item-start cursor-pointer' 
                                            onClick={() => handleResetPreview(1)} />
                                    </div>
                                }
                            </>
                        }

                        {/* thumbnail */}
                        <div className="mt-3 mb-3 w-full relative z-0 group">
                            <label 
                                className="block mb-2 font-ssp text-sm font-medium text-gray-500" 
                                htmlFor="file_thumbnail"
                            >Upload Thumbnail</label>
                            <input 
                                required
                                type="file" 
                                name="thumbnail"
                                ref={thumbnailRef}
                                onChange={(e) => setThumbnail(e.target.files[0])}
                                accept='.png,.jpg,.jpeg'
                                className="block w-full text-sm text-slate-500
                                            file:mr-4 file:py-2 file:px-4
                                            file:rounded-full file:border-0
                                            file:text-sm file:font-semibold
                                            file:bg-gray-50 file:text-gray-500
                                            hover:file:bg-gray-100"
                            />
                            <p className="mt-1 font-ssp text-xs text-gray-500 dark:text-gray-300" id="file_thumbnail_help">PNG, JPG, JPEG (MAX. 5MB).</p>
                        </div>
                        {thumbnail && 
                            <div className="mt-3 w-full relative flex flex-row group">
                                <img src={URL.createObjectURL(thumbnail)} alt="Preview" className="w-1/4" />
                                <IoIosCloseCircle 
                                    fontSize='25px' 
                                    className='-mt-3 -ml-3 bg-white rounded-full item-start cursor-pointer' 
                                    onClick={() => handleResetPreview(2)} />
                            </div>
                        }
                        {/* button */}
                        <div className="mb-6 mt-5 w-full relative z-0 flex justify-end gap-3">
                            <button 
                                type="submit" 
                                className="mt-5 px-5 py-2.5 w-full sm:w-auto bg-blue-400 hover:bg-blue-500 rounded-lg font-medium  
                                            text-sm text-white text-center cursor-pointer"
                            >Save</button>
                            <span 
                                onClick={handleModal} 
                                className="mt-5 px-5 py-2.5 w-full sm:w-auto bg-red-400 hover:bg-red-500 rounded-lg font-medium
                                            text-sm text-white text-center cursor-pointer"
                            >Close</span>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default ModalAddPost