import React, { useContext, useEffect, useRef, useState } from 'react';
import { RiCloseFill } from "react-icons/ri";
import { toast } from 'react-toastify';
import { PopupContext } from "../../context/popupContext";
import { addSpeakerAudio, getSpeakers } from '../../utilities/sendRequest';
import { decryptData } from '../../utilities/cryptoStorage';

const ModalAddSpeaker = ({ setShowModalAdd, setFetchMore, data }) => {
    const token = JSON.parse(decryptData(localStorage.getItem('cRdT')));
    const { popupDispatch } = useContext(PopupContext)
    const clickRef = useRef(null)
    const [speakers, setSpeakers] = useState([])
    const [speakerId, setSpeakerId] = useState("")
    const [title, setTitle] = useState(data.name)
    
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (clickRef.current && !clickRef.current.contains(event.target)) {
                setShowModalAdd(null)
                popupDispatch({ type: "POPUP_END" });
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clickRef])

    useEffect(() => {
        fetchSpeakers()
        // eslint-disable-next-line
    }, [])

    const fetchSpeakers = async () => {
        getSpeakers(token).then((res) => {
            setSpeakers(
                res.payload.sort((p1, p2) => {
                    return new Date(p2.created_at) - new Date(p1.created_at)
                })
            )
        }).catch((err) => {
            toast.error(err.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        })
    }

    const handleSubmit = async(e) => {
        e.preventDefault()

        const reqData = {
            productId: data.id,
            speakerId
        }

        addSpeakerAudio(reqData, token).then((res) => {
            toast.success(res.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
            setFetchMore(true)
            setShowModalAdd(null)
        }).catch((err) => {
            toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        })
        popupDispatch({ type: "POPUP_END" });
    }
    
    const handleModal = () => {
        setShowModalAdd(null)
        popupDispatch({ type: "POPUP_END" });
    }

    return (
        <>
            <div  className="absolute top-0 left-0 z-10 p-3 w-screen min-h-screen overscroll-y-none overflow-y-hidden bg-black bg-opacity-70 flex items-center justify-center">
                <div ref={clickRef} className="p-3 w-11/12 lg:w-1/3 md:w-1/2 h-full bg-white rounded-md flex flex-col">
                    <div className="px-2 flex justify-between items-center">
                        <h3 className="font-bold font-ssp text-xl md:text-2xl">Add Spekers Audio</h3>
                        <RiCloseFill onClick={handleModal} className="w-8 h-8 cursor-pointer"/>
                    </div>
                    <form onSubmit={handleSubmit} className="mt-5 px-5 w-full flex flex-col">
                        {/* title */}
                        <div className="relative z-0 mb-3 mt-3 w-full group">
                            <input 
                                minLength={3} 
                                value={title} 
                                onChange={(e) => setTitle(e.target.value)} 
                                type="text" 
                                name="title" 
                                id="title" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                                placeholder=" " 
                                required 
                                readOnly
                            />
                            <label 
                                htmlFor="title" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Title</label>
                        </div>
                        {/* speaker */}
                        <div className="relative z-0 mb-3 mt-4 w-full group">
                            <select required value={speakerId} 
                                onChange={(e) => setSpeakerId(e.target.value)} 
                                id="speaker_select" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            >
                                <option value="">Choose a speaker</option>
                                {speakers.map((speaker) => (
                                    <option key={speaker.id} value={speaker.id}>
                                        {speaker.name}
                                    </option>
                                ))}
                            </select>
                            <label 
                                htmlFor="speaker_select" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Speaker</label>
                        </div>
                        {/* button  */}
                        <div className="relative z-0 mb-6 mt-5 w-full flex justify-end gap-3">
                            <button 
                                type="submit" 
                                className="mt-5 px-5 py-2.5 w-full sm:w-auto bg-blue-400 hover:bg-blue-500 rounded-lg text-white font-medium text-sm text-center"
                            >Save</button>
                            <span 
                                onClick={handleModal} 
                                className="mt-5 px-5 py-2.5 w-full sm:w-auto bg-red-400 hover:bg-red-500 rounded-lg font-medium text-sm text-white text-center cursor-pointer"
                            >Close</span>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default ModalAddSpeaker