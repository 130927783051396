import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import VisibilityIcon from '@mui/icons-material/Visibility';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import { AuthContext } from "../../context/authContext";
import { PopupContext } from "../../context/popupContext";
import { decryptData } from '../../utilities/cryptoStorage';
import { logoutUser, getContentCategories, toggleContentCategory, getContents, toggleContent } from '../../utilities/sendRequest';
import ModalAddCategory from './modalAddCategory';
import ModalAddContent from './modalAddContent';

const BillContents = () => {
    const token = JSON.parse(decryptData(localStorage.getItem('cRdT')));
    const { dispatch } = useContext(AuthContext)
    const { popupDispatch } = useContext(PopupContext)
    const [categories, setCategories] = useState([])
    const [contents, setContents] = useState([])
    const [showModalAddCategory, setShowModalAddCategory] = useState(false)
    const [showModalAddContent, setShowModalAddContent] = useState(false)
    const [fetchMore, setFetchMore] = useState(true)

    useEffect(() => {
        fetchCategory()
        fetchContent()
        setFetchMore(false)
        // eslint-disable-next-line
    }, [fetchMore])

    const fetchCategory = async () => {
        getContentCategories(token).then((res) => {
            setCategories(res.payload)
        }).catch((err) => {
            toast.error(err.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });

            if (err.response.status === 401) {
                setTimeout(() => {
                    logoutUser(dispatch)
                }, 3500)
            }
        })
    }

    const fetchContent = async () => {
        getContents(token).then((res) => {
            setContents(
                res.payload.sort((p1, p2) => {
                    return new Date(p2.created_at) - new Date(p1.created_at)
                })
            )
        }).catch((err) => {
            toast.error(err.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });

            if (err.response.status === 401) {
                setTimeout(() => {
                    logoutUser(dispatch)
                }, 3500)
            }
        })
    }

    const handleToggleCategory = (categoryId) => {
        toggleContentCategory(categoryId, token).then((res) => {
            toast.success(res.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
            setFetchMore(true)
        }).catch((err) => {
            toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });

            if (err.response.status === 401) {
                setTimeout(() => {
                    logoutUser(dispatch)
                }, 3500)
            }
        })
    }

    const handleToggleContent = (contentId) => {
        toggleContent(contentId, token).then((res) => {
            toast.success(res.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
            setFetchMore(true)
        }).catch((err) => {
            toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });

            if (err.response.status === 401) {
                setTimeout(() => {
                    logoutUser(dispatch)
                }, 3500)
            }
        })
    }

    const handleModalCategory = () => {
        setShowModalAddCategory(true)
        popupDispatch({ type: "POPUP_START" });
    }

    const handleModalContent = () => {
        setShowModalAddContent(true)
        popupDispatch({ type: "POPUP_START" });
    }

    const columns = [
        { 
            field: 'name', 
            headerName: 'Category',
            width: 200, 
            renderCell: (params) => {
                return (
                    <div className='flex justify-center items-center font-ssp'>
                        {params.row.name}
                    </div>
                )
            } 
        },
        {
            field: 'is_active',
            headerName: 'Status',
            width: 120,
            renderCell: (params) => {
                return (
                    <div className="font-ssp">
                        {params.row.is_active === "1"
                            ? <span className="px-2 py-1 bg-green-400 rounded-full text-white uppercase">active</span>
                            : <span className="px-2 py-1 bg-red-400 rounded-full text-white uppercase">inactive</span>
                        }
                    </div>
                )
            } 
        },
        {
            field: 'action',
            headerName: 'Action',
            width: 120,
            renderCell: (params) => {
                return (
                    <div className='w-full flex flex-row gap-3 items-center'>
                        <Link to={"/contents/category/" + params.row.id}>
                            <VisibilityIcon fontSize='medium' className="text-slate-400 hover:text-slate-500 cursor-pointer"/>
                        </Link>
                        {params.row.is_active === "1" 
                            ? <ToggleOnIcon className="text-green-400 hover:text-green-500 cursor-pointer" onClick={() => handleToggleCategory(params.row.id)}/>
                            : <ToggleOffIcon className="text-gray-400 hover:text-gray-500 cursor-pointer" onClick={() => handleToggleCategory(params.row.id)}/>
                        }
                    </div>
                )
            }
        },
    ]

    const contentColumns = [
        { 
            field: 'title', 
            headerName: 'Title',
            width: 200, 
            renderCell: (params) => {
                return (
                    <div className='flex justify-center items-center font-ssp'>
                        {params.row.title}
                    </div>
                )
            } 
        },
        { 
            field: 'category_id', 
            headerName: 'Category',
            width: 120, 
            renderCell: (params) => {
                return (
                    <div className='flex justify-center items-center font-ssp'>
                        {params.row.category}
                    </div>
                )
            } 
        },
        { 
            field: 'is_video', 
            headerName: 'Type',
            width: 100, 
            renderCell: (params) => {
                return (
                    <div className='flex justify-center items-center font-ssp uppercase'>
                        {(params.row.is_video === "1") ? "VIDEO" : "IMAGE"}
                    </div>
                )
            } 
        },
        {
            field: 'is_active',
            headerName: 'Status',
            width: 100,
            renderCell: (params) => {
                return (
                    <div className="font-ssp">
                        {params.row.is_active === "1"
                            ? <span className="px-2 py-1 bg-green-400 rounded-full text-white uppercase">active</span>
                            : <span className="px-2 py-1 bg-red-400 rounded-full text-white uppercase">inactive</span>
                        }
                    </div>
                )
            } 
        },
        {
            field: 'action',
            headerName: 'Action',
            width: 100,
            renderCell: (params) => {
                return (
                    <div className='w-full flex flex-row gap-3 items-center'>
                        <Link to={"/contents/" + params.row.id}>
                            <VisibilityIcon fontSize='medium' className="text-slate-400 hover:text-slate-500 cursor-pointer"/>
                        </Link>
                        {params.row.is_active === "1" 
                            ? <ToggleOnIcon className="text-green-400 hover:text-green-500 cursor-pointer" onClick={() => handleToggleContent(params.row.id)}/>
                            : <ToggleOffIcon className="text-gray-400 hover:text-gray-500 cursor-pointer" onClick={() => handleToggleContent(params.row.id)}/>
                        }
                    </div>
                )
            }
        },
    ]

    return (
        <>
            {showModalAddCategory && 
                <ModalAddCategory setShowModalAddCategory={setShowModalAddCategory} setFetchMore={setFetchMore} />
            }
            {showModalAddContent && 
                <ModalAddContent setShowModalAddContent={setShowModalAddContent} setFetchMore={setFetchMore} />
            }
            <div className={`min-h-[calc(100vh-4rem)] flex flex-col ${(showModalAddContent || showModalAddCategory) && 'fixed min-w-[calc(100vw-16.666667%)]'} `}>
                {/* header  */}
                <div className="my-5 mb-5 mx-7 md:mx-5 flex flex-col md:flex-row justify-between">
                    <h1 className='font-ssp font-bold text-2xl md:text-3xl'>Billionaires Contents</h1>
                    <div className='flex flex-row gap-3'>
                        <div onClick={() => handleModalCategory()} className="px-5 py-2.5 mr-3 mt-3 md:mt-1 bg-blue-400 hover:bg-blue-500 rounded-lg flex text-sm font-medium self-start md:self-end cursor-pointer">
                            <span className='text-sm text-white font-ssp'>Add Category</span>
                        </div>
                        <div onClick={() => handleModalContent()} className="px-5 py-2.5 mr-3 mt-3 md:mt-1 bg-blue-400 hover:bg-blue-500 rounded-lg flex text-sm font-medium self-start md:self-end cursor-pointer">
                            <span className='text-sm text-white font-ssp'>Add Content</span>
                        </div>
                    </div>
                </div>
                {/* content  */}
                <div className="my-5 mb-5 mx-7 md:mx-5 flex justify-between flex-col md:flex-row gap-5 md:gap-0">
                    <div className="p-2 w-full md:w-[40%] bg-white rounded-md drop-shadow-lg flex flex-col gap-3">
                        <h1 className='font-semibold text-base font-ssp'>Categories</h1>
                        <DataGrid
                            autoHeight {...categories}
                            rows={categories}
                            columns={columns}
                            pageSize={5}
                            getRowId={(row) => row.id}
                            rowsPerPageOptions={[5]}
                            checkboxSelection
                            disableSelectionOnClick
                            components={{ Toolbar: GridToolbar, }}
                        />
                    </div>
                    <div className="p-2 w-full md:w-[57%] bg-white rounded-md drop-shadow-lg flex flex-col gap-3">
                        <h1 className='font-semibold text-base font-ssp'>Billinaires Contents</h1>
                        <DataGrid
                            autoHeight {...contents}
                            rows={contents}
                            columns={contentColumns}
                            pageSize={5}
                            getRowId={(row) => row.id}
                            rowsPerPageOptions={[5]}
                            checkboxSelection
                            disableSelectionOnClick
                            components={{ Toolbar: GridToolbar, }}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default BillContents