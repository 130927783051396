import { createContext, useReducer } from "react"
import PopupReducer from "./popupReducer"

const INITIAL_STATE = {
    isPopup: false,
}

export const PopupContext = createContext(INITIAL_STATE)

export const PopupContextProvider = ({ children }) => {
    const [popupState, popupDispatch] = useReducer(PopupReducer, INITIAL_STATE)

    return (
        <PopupContext.Provider 
            value={{ 
                isPopup: popupState.isPopup,
                popupDispatch
            }}
        >
            { children }
        </PopupContext.Provider>
    )
}