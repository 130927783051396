import React, { useContext } from "react";
import { PopupContext } from "../../context/popupContext";
import { AuthContext } from "../../context/authContext";
import { Link, NavLink } from 'react-router-dom';
import { sidebarMenus } from '../../utilities/sidebarMenu';
import Accordion from '../Accordion'

const Sidebar = ({ handleSidebar }) => {
    const { user } = useContext(AuthContext)
    const { isPopup } = useContext(PopupContext)
    const activeButton = 'px-2 py-1.5 w-full flex flex-row items-center gap-2 bg-darkThird rounded-sm font-ssp font-semibold text-gray-300 tracking-wider text-lg transition-all duration-200 ease-in-out cursor-pointer'
    const inActiveButton = 'px-2 py-1.5 w-full flex flex-row items-center gap-2 font-ssp hover:font-semibold text-gray-300 tracking-wider text-lg transition-all duration-200 ease-in-out cursor-pointer'

    return (
        // <div className={`${(isPopup) ? 'fixed' : 'absolute'} w-full md:w-1/6 min-w-0 min-h-screen h-fit bg-darkPrimary flex flex-initial flex-col`}>
        <div className={`${(isPopup) ? 'fixed' : 'fixed'} w-full md:w-1/6 min-w-0 min-h-screen h-full bg-darkPrimary flex flex-initial flex-col overflow-scroll`}>
        {/* <div className={`fixed w-full md:w-1/6 min-w-0 min-h-screen h-full bg-darkPrimary flex flex-initial flex-col overflow-scroll`}> */}
            <Link 
                to={"/"} 
                className="p-2 mt-1 h-14 flex flex-col justify-between items-center gap-3"
                onClick={handleSidebar}
            >
                <span className="ml-5 md:ml-3 lg:ml-5 self-start font-ssp font-semibold text-xl lg:text-2xl text-gray-300 tracking-wider">Admin Panel</span>
                <hr className='w-11/12 bg-slate-200' />
            </Link>
            
            <div className="p-4 mt-2 w-full min-w-full flex flex-col gap-3">
                {sidebarMenus.filter(sidebarMenu => sidebarMenu.roles.includes(user.division)).map((menu, i) => (
                    <div key={i}>
                        {menu.subMenus.length > 0 ? (
                            <Accordion  handleSidebar={handleSidebar} activeButton={activeButton} inActiveButton={inActiveButton} data={menu} />
                        ) : (
                            <NavLink 
                                to={menu.link} 
                                className={({ isActive }) => isActive ? activeButton : inActiveButton}
                                onClick={handleSidebar}
                            >
                                <img src={ menu.icon } alt="Menu Icon" className="w-6 h-6" />
                                <span className='self-center'>{ menu.title }</span>
                            </NavLink>
                        )}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Sidebar