import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from "../../context/authContext";
import { PopupContext } from "../../context/popupContext";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Link } from 'react-router-dom';
import { logoutUser, getProducts, toggleProduct } from '../../utilities/sendRequest';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { toast } from 'react-toastify';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import ModalAddProduct from './modalAdd';
import { decryptData } from '../../utilities/cryptoStorage';
import { formatRupiah } from '../../utilities/helper';

const Products = () => {
    const token = JSON.parse(decryptData(localStorage.getItem('cRdT')));
    const { dispatch } = useContext(AuthContext)
    const { popupDispatch } = useContext(PopupContext)
    const [datas, setDatas] = useState([])
    const [showModalAdd, setShowModalAdd] = useState(false)
    const [fetchMore, setFetchMore] = useState(true)

    useEffect(() => {
        fetchProducts()
        setFetchMore(false)
        // eslint-disable-next-line
    }, [fetchMore])

    const fetchProducts = async () => {
        getProducts(token).then((res) => {
            setDatas(res.payload)
        }).catch((err) => {
            toast.error(err.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });

            if (err.response.status === 401) {
                setTimeout(() => {
                    logoutUser(dispatch)
                }, 3500)
            }
        })
    }

    const handleToggle = (productId) => {
        toggleProduct(productId, token).then((res) => {
            toast.success(res.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
            setFetchMore(true)
        }).catch((err) => {
            toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });

            if (err.response.status === 401) {
                setTimeout(() => {
                    logoutUser(dispatch)
                }, 3500)
            }
        })
    }

    const handleModal = () => {
        setShowModalAdd(true)
        popupDispatch({ type: "POPUP_START" });
    }

    const columns = [
        { 
            field: 'kode_product', 
            headerName: 'Kode',
            width: 100, 
            renderCell: (params) => {
                return (
                    <div className='flex justify-center items-center font-ssp'>
                        {params.row.kode_product}
                    </div>
                )
            } 
        },
        { 
            field: 'photo', 
            headerName: 'Cover',
            width: 100, 
            renderCell: (params) => {
                return (
                    <div className='flex justify-center items-center font-ssp'>
                        {params.row.thumbnail_1 ? (
                            <div className='w-8 h-8 object-cover object-top bg-slate-400'>
                                <img className='w-8 h-8 object-cover object-top' src={`${process.env.REACT_APP_PUBLIC_FOLDER_PRODUCT_COVERS}/${params.row.thumbnail_1}`} alt={params.row.name} />
                            </div>
                        ) : (
                            <div className='w-8 h-8 object-cover object-top bg-slate-400'></div>
                        )}        
                    </div>
                )
            } 
        },
        { 
            field: 'name', 
            headerName: 'Name',
            width: 400, 
            renderCell: (params) => {
                return (
                    <div className='flex justify-center items-center font-ssp'>
                        {params.row.name}
                    </div>
                )
            } 
        },
        { 
            field: 'category', 
            headerName: 'Category',
            width: 100, 
            renderCell: (params) => {
                return (
                    <div className='flex justify-center items-center font-ssp'>
                        {params.row.category}
                    </div>
                )
            } 
        },
        { 
            field: 'price', 
            headerName: 'Price',
            width: 100, 
            renderCell: (params) => {
                return (
                    <div className='flex justify-center items-center gap-2 font-ssp'>
                        {formatRupiah(params.row.price)}
                    </div>
                )
            } 
        },
        {
            field: 'is_active',
            headerName: 'Status',
            width: 120,
            renderCell: (params) => {
                return (
                    <div className="font-ssp">
                        {params.row.is_active === "1"
                            ? <span className="px-2 py-1 bg-green-400 rounded-full text-white uppercase">active</span>
                            : <span className="px-2 py-1 bg-red-400 rounded-full text-white uppercase">inactive</span>
                        }
                    </div>
                )
            } 
        },
        {
            field: 'action',
            headerName: 'Action',
            width: 120,
            renderCell: (params) => {
                return (
                    <div className='w-full flex flex-row gap-3 items-center'>
                        <Link to={"/products/" + params.row.id}>
                            <VisibilityIcon fontSize='medium' className="text-slate-400 hover:text-slate-500 cursor-pointer"/>
                        </Link>
                        {params.row.is_active === "1" 
                            ? <ToggleOnIcon className="text-green-400 hover:text-green-500 cursor-pointer" onClick={() => handleToggle(params.row.id)}/>
                            : <ToggleOffIcon className="text-gray-400 hover:text-gray-500 cursor-pointer" onClick={() => handleToggle(params.row.id)}/>
                        }
                    </div>
                )
            }
        },
    ]

    return (
        <>
            {showModalAdd && 
                <ModalAddProduct setShowModalAdd={setShowModalAdd} setFetchMore={setFetchMore} />
            }
            <div className={`min-h-[calc(100vh-4rem)] flex flex-col ${showModalAdd && 'fixed min-w-[calc(100vw-16.666667%)]'} `}>
                {/* header  */}
                <div className="my-5 mb-5 mx-7 md:mx-5 flex flex-col md:flex-row justify-between">
                    <h1 className='font-ssp font-bold text-2xl md:text-3xl'>Billionaires Products</h1>
                    <div onClick={() => handleModal()} className="px-5 py-2.5 mr-3 mt-3 md:mt-1 bg-blue-400 hover:bg-blue-500 rounded-lg flex text-sm font-medium self-start md:self-end cursor-pointer">
                        <span className='text-sm text-white font-ssp'>Add New</span>
                    </div>
                </div>
                {/* content  */}
                <div className="my-5 mb-5 mx-7 md:mx-5 flex">
                    <div className="p-2 w-full bg-white rounded-md drop-shadow-lg flex">
                        <DataGrid
                            autoHeight {...datas}
                            rows={datas}
                            columns={columns}
                            pageSize={10}
                            getRowId={(row) => row.id}
                            rowsPerPageOptions={[10]}
                            checkboxSelection
                            disableSelectionOnClick
                            components={{ Toolbar: GridToolbar, }}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Products