import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getHdiProduct, updateHdiProduct } from '../../utilities/sendRequest';
import { decryptData } from '../../utilities/cryptoStorage';

const DetailBvCalculation = () => {
    const token = JSON.parse(decryptData(localStorage.getItem('cRdT')));
    const params = useParams()
    const navigate = useNavigate()
    const [title, setTitle] = useState("")
    const [price, setPrice] = useState("")
    const [bv, setBv] = useState("")
    const [bp, setBp] = useState("")
    const [status, setStatus] = useState("")
    const options = [
        {
            label: "ACTIVE",
            value: 1
        },
        {
            label: "INACTIVE",
            value: 0
        },
    ]
    
    useEffect(() => {
        fetchHdiProduct()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const fetchHdiProduct = async () => {
        getHdiProduct(params.id, token).then((res) => {
            setTitle(res.payload.title)
            setPrice(res.payload.price)
            setBv(res.payload.bv)
            setBp(res.payload.bp)
            setStatus(res.payload.is_active)
        }).catch((err) => {
            toast.error(err.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        })  
    }

    const handleUpdate = async (e) => {
        e.preventDefault()

        const reqData = {
            title,
            price,
            bv,
            bp,
            status
        }

        updateHdiProduct(params.id, reqData, token).then((res) => {
            toast.success(res.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
            fetchHdiProduct()
        }).catch((err) => {
            return toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        })
    }

    return (
        <div className='min-h-[calc(100vh-4rem)] flex flex-col'>
            {/* header */}
            <div className="my-5 mx-7 md:mx-5 flex flex-row justify-between">
                <h1 className='font-ssp font-bold text-4xl md:text-3xl'>Detail HDI Product</h1>
            </div>
            {/* content */}
            <div className="my-5 mx-7 md:mx-5 flex flex-col lg:flex-row gap-10">
                {/* form update */}
                <div className="px-2 py-4 w-full lg:w-1/2 md:w-10/12 flex bg-white drop-shadow-lg rounded-md">
                    <form onSubmit={handleUpdate} className='px-5 w-full flex flex-wrap justify-between'>
                        {/* title */}
                        <div className="relative z-0 mt-3 mb-6 w-full md:w-[45%] group">
                            <input 
                                minLength={3} 
                                value={title} 
                                onChange={(e) => setTitle(e.target.value)} 
                                type="text" 
                                name="title" 
                                id="title" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                            focus:border-blue-600 peer" 
                                placeholder=" " 
                                required 
                            />
                            <label 
                                htmlFor="title" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 
                                            duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                            peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                            peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Title</label>
                        </div>
                        {/* bv */}
                        <div className="relative z-0 mt-3 mb-6 w-full md:w-[45%] group">
                            <input 
                                minLength={1} 
                                value={bv} 
                                onChange={(e) => setBv(e.target.value)} 
                                type="text" 
                                name="bv" 
                                id="bv" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                            focus:border-blue-600 peer" 
                                placeholder=" " 
                                required 
                            />
                            <label 
                                htmlFor="bv" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 
                                            duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                            peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                            peer-focus:scale-75 peer-focus:-translate-y-6"
                            >BV</label>
                            <p className="mt-1 font-ssp text-xs text-gray-500 dark:text-gray-300 italic" id="bv">Contoh: 23400</p>
                        </div>
                        {/* bp */}
                        <div className="relative z-0 mt-3 mb-6 w-full md:w-[45%] group">
                            <input 
                                minLength={1} 
                                value={bp} 
                                onChange={(e) => setBp(e.target.value)} 
                                type="text" 
                                name="bp" 
                                id="bp" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                            focus:border-blue-600 peer" 
                                placeholder=" " 
                                required 
                            />
                            <label 
                                htmlFor="bp" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 
                                            duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                            peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                            peer-focus:scale-75 peer-focus:-translate-y-6"
                            >BP</label>
                            <p className="mt-1 font-ssp text-xs text-gray-500 dark:text-gray-300 italic" id="bp">Contoh: 134.89</p>
                        </div>
                        {/* price */}
                        <div className="relative z-0 mt-3 mb-6 w-full md:w-[45%] group">
                            <input 
                                minLength={1} 
                                value={price} 
                                onChange={(e) => setPrice(e.target.value)} 
                                type="text" 
                                name="price" 
                                id="price" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                            focus:border-blue-600 peer" 
                                placeholder=" " 
                                required 
                            />
                            <label 
                                htmlFor="price" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 
                                            duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                            peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                            peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Price</label>
                            <p className="mt-1 font-ssp text-xs text-gray-500 dark:text-gray-300 italic" id="price">Contoh: 530000</p>
                        </div>
                        {/* status */}
                        <div className="relative z-0 mt-3 mb-6 w-full md:w-[45%] group">
                            <select required value={status} 
                                onChange={(e) => setStatus(e.target.value)} 
                                id="status_select" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            >
                                <option value="">Choose a status</option>
                                {options.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                            <label 
                                htmlFor="status_select" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Status</label>
                        </div>
                        {/* button */}
                        <div className="mb-6 w-full relative z-0 flex justify-center gap-5">
                            <button 
                                type="submit" 
                                className="px-5 py-2.5 mt-5 w-full sm:w-auto bg-green-400 hover:bg-green-500 rounded-lg 
                                            font-medium text-sm text-white text-center cursor-pointer"
                            >Submit</button>
                            <span 
                                onClick={() => navigate('/bvCalculation')} 
                                className="px-5 py-2.5 mt-5 w-full sm:w-auto bg-red-400 hover:bg-red-500 rounded-lg
                                            font-medium text-sm text-white text-center cursor-pointer"
                            >Back</span>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default DetailBvCalculation