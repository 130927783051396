import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { IoIosCloseCircle } from "react-icons/io";
import { getProfileCategories, getSuccessProfile, updateSuccessProfile, addGalleryProfile, getProfileGalleries, deleteGalleryProfile } from '../../utilities/sendRequest';
import { decryptData } from '../../utilities/cryptoStorage';

const DetailProfile = () => {
    const token = JSON.parse(decryptData(localStorage.getItem('cRdT')));
    const params = useParams()
    const navigate = useNavigate()
    const imageRef = useRef(null)
    const [title, setTitle] = useState("")
    const [category, setCategory] = useState("")
    const [categories, setCategories] = useState([])
    const [status, setStatus] = useState("")
    const [galleries, setGalleries] = useState({})
    const optionStatus = [
        {
            label: "Choose a Status",
            value: "",
        },
        {
            label: "ACTIVE",
            value: 1,
        },
        {
            label: "INACTIVE",
            value: 0,
        },
    ]

    useEffect(() => {
        fetchProfile()
        fetchCategories()
        fetchGalleries()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const fetchCategories = async () => {
        getProfileCategories(token).then((res) => {
            setCategories(res.payload)
        }).catch((err) => {
            toast.error(err.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        })
    }

    const fetchProfile = async () => {
        getSuccessProfile(params.id, token).then((res) => {
            setTitle(res.payload.title)
            setCategory(res.payload.category_id)
            setStatus(res.payload.is_active)
        }).catch((err) => {
            toast.error(err, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        })  
    }

    const fetchGalleries = async () => {
        getProfileGalleries(params.id, token).then((res) => {
            setGalleries(res.payload)
        }).catch((err) => {
            toast.error(err, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        })  
    }

    const hanldeUpdate = async (e) => {
        e.preventDefault()

        const reqData = new FormData();
        reqData.append("title", title);
        reqData.append("category", category);
        reqData.append("status", status);

        updateSuccessProfile(params.id, reqData, token).then((res) => {
            toast.success(res.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });

            fetchProfile()
        }).catch((err) => {
            toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        })
    }
    
    const handleInputImage = () => {
        imageRef.current.click();
    };

    const handleAddImage = async (e) => {
        e.preventDefault()

        const reqData = new FormData();
        reqData.append("profile", params.id);
        reqData.append("image", e.target.files[0]);
        
        addGalleryProfile(reqData, token).then((res) => {
            toast.success(res.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
            imageRef.current.value = null;
            fetchGalleries()
        }).catch((error) => {
            toast.error(error.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        })
    }

    const handleDeleteImage = async (paramsId) => {
        deleteGalleryProfile(paramsId, token).then((res) => {
            toast.success(res.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
            
            fetchGalleries()
        }).catch((error) => {
            toast.error(error.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        })
    }

    return (
        <div className='min-h-[calc(100vh-4rem)] flex flex-col'>
            {/* header  */}
            <div className="my-5 mx-7 md:mx-5 flex flex-row justify-between">
                <h1 className='font-ssp font-bold text-4xl md:text-3xl'>Detail Profile Success</h1>
            </div>
            {/* content */}
            <div className="my-5 mx-7 md:mx-5 flex flex-col-reverse lg:flex-row justify-evenly gap-7 md:gap-10">
                {/* profile galleries */}
                <div className="w-full h-full lg:w-2/3 md:w-10/12 flex flex-col gap-3 items-center">
                    <div className='px-3 py-4 w-full h-full bg-white drop-shadow-lg rounded-md flex flex-col gap-2'>
                        <h3 className='text-lg font-ssp font-semibold text-center'>Contents</h3>
                        <div className='py-3 w-full flex flex-wrap gap-5 items-center justify-center'>
                            {galleries.length > 0 ? (
                                <>
                                {galleries.map((gallery) => (
                                    <div key={gallery.id} className='h-40 w-32 flex flex-row justify-center bg-slate-500'>
                                        <div className='relative w-full flex flex-row'>
                                            <img src={`${process.env.REACT_APP_PUBLIC_FOLDER_PROFILE_GALLERIES}/${gallery.content}`} alt="Contents" className='w-full object-cover' />
                                            <IoIosCloseCircle 
                                                className='absolute -mt-2 -ml-2 text-xl bg-white rounded-full item-start cursor-pointer' 
                                            onClick={() => handleDeleteImage(gallery.id)} />
                                        </div>
                                    </div>
                                ))}
                                </>
                            ) : (
                                <div>Contents not found</div>
                            )}
                        </div>
                        <div className='w-full flex justify-center items-center'>
                            <div 
                                className="px-3 py-2 mt-5 w-fit h-fit sm:w-auto bg-blue-400 hover:bg-blue-500 rounded-lg 
                                            font-medium text-sm text-center text-white cursor-pointer" 
                                onClick={handleInputImage}>
                                Upload Image
                            </div>
                            <input
                                type="file"
                                ref={imageRef}
                                className="hidden"
                                onChange={(e) => handleAddImage(e)}
                                accept=".jpg, .jpeg, .png"
                                aria-label="Upload file"
                            />
                        </div>
                     </div>
                </div>
                {/* form update */}
                <div className="px-2 py-4 w-full lg:w-1/3 md:w-1/2 bg-white drop-shadow-lg rounded-md flex">
                    <form onSubmit={hanldeUpdate} className='px-5 w-full flex flex-wrap justify-between'>
                        {/* title */}
                        <div className="mt-3 mb-6 w-full relative z-0 group">
                            <input 
                                minLength={3} 
                                value={title} 
                                onChange={(e) => setTitle(e.target.value)} 
                                type="text" 
                                name="title" 
                                id="title" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                            focus:border-blue-600 peer" 
                                placeholder=" " 
                                required 
                            />
                            <label 
                                htmlFor="title" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 duration-300 transform -translate-y-6 
                                            scale-75 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:font-medium 
                                            peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Title</label>
                        </div>
                        {/* category */}
                        <div className="mb-6 mt-3 w-full relative z-0 group">
                            <select 
                                required value={category} 
                                onChange={(e) => setCategory(e.target.value)} 
                                id="category" 
                                name="category" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-500 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                            focus:border-blue-600 peer"
                            >
                                {categories.map((option) => (
                                    <option key={option.id} value={option.id}>{option.name}</option>
                                ))}
                            </select>
                            <label 
                                htmlFor="category" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 
                                            duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                            peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                            peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Category</label>
                        </div>
                        {/* status */}
                        <div className="mb-6 mt-3 w-full relative z-0 group">
                            <select 
                                required 
                                value={status} 
                                onChange={(e) => setStatus(e.target.value)} 
                                id="status" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-500 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                            focus:border-blue-600 peer"
                            >
                                {optionStatus.map((option) => (
                                    <option key={option.value} value={option.value}>{option.label}</option>
                                ))}
                            </select>
                            <label 
                                htmlFor="status" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 
                                            duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:font-medium 
                                            peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 
                                            peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Status</label>
                        </div>
                        {/* button */}
                        <div className="mb-6 w-full relative z-0 flex justify-center gap-5">
                            <button 
                                type="submit" 
                                className="px-5 py-2.5 mt-5 w-full h-fit sm:w-auto bg-green-400 hover:bg-green-500 rounded-lg 
                                            font-medium text-sm text-center text-white cursor-pointer "
                            >Update</button>
                            <span 
                                onClick={() => navigate("/success")} 
                                className="px-5 py-2.5 mt-5 w-full h-fit sm:w-auto bg-red-400 hover:bg-red-500 rounded-lg 
                                            font-medium text-sm text-center text-white cursor-pointer"
                            >Back</span>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default DetailProfile