import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import DeleteIcon from '@mui/icons-material/Delete';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { PopupContext } from "../../context/popupContext";
import { AuthContext } from '../../context/authContext';
import { decryptData } from '../../utilities/cryptoStorage';
import { dateDDMMYYY } from '../../utilities/formatDate';
import { getDailys, toggleDaily, logoutUser } from '../../utilities/sendRequest';
import ModalAddDaily from './modalAdd';
import ModalDeleteDaily from './modalDelete';

const Dailys = () => {
    const token = JSON.parse(decryptData(localStorage.getItem('cRdT')));
    const { dispatch } = useContext(AuthContext)
    const { popupDispatch } = useContext(PopupContext)
    const [datas, setDatas] = useState([])
    const [data, setData] = useState([])
    const [showModalAdd, setShowModalAdd] = useState(false)
    const [showModalDelete, setShowModalDelete] = useState(false)
    const [fetchMore, setFetchMore] = useState(true)

    useEffect(() => {
        fetchDailys()
        setFetchMore(false)
        // eslint-disable-next-line
    }, [fetchMore])

    const fetchDailys = async () => {
        getDailys(token).then((res) => {
            setDatas(
                res.payload.sort((p1, p2) => {
                    return new Date(p2.schedule_date) - new Date(p1.schedule_date)
                })
            )
        }).catch((error) => {
            toast.error(error.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });

            if (error.response.status === 401) {
                setTimeout(() => {
                    logoutUser(dispatch)
                }, 3500)
            }
        })
    }

    const handleToggle = (dailyUUID) => {
        toggleDaily(dailyUUID, token).then((res) => {
            toast.success(res.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
            setFetchMore(true)
        }).catch((error) => {
            toast.error(error.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });

            if (error.response.status === 401) {
                setTimeout(() => {
                    logoutUser(dispatch)
                }, 3500)
            }
        })
    }

    const handleModal = () => {
        setShowModalAdd(true)
        popupDispatch({ type: "POPUP_START" });
    }

    const handleModalDelete = (event) => {
        setShowModalDelete(true)
        setData(event)
        popupDispatch({ type: "POPUP_START" });
    }

    const columns = [
        { 
            field: 'name', 
            headerName: 'Title',
            width: 350, 
            renderCell: (params) => {
                return (
                    <div className='flex justify-center items-center font-ssp'>
                        {params.row.name}
                    </div>
                )
            } 
        },
        { 
            field: 'schedule_date', 
            headerName: 'Schedule', 
            width: 200, 
            renderCell: (params) => {
                return (
                    <div className="font-ssp">
                        {dateDDMMYYY(params.row.schedule_date)} {params.row.schedule_time}
                    </div>
                )
            } 
        },
        { 
            field: 'photo', 
            headerName: 'Flyer',
            width: 100, 
            renderCell: (params) => {
                return (
                    <div className='m-auto font-ssp'>
                        {params.row.photo && 
                            <img className='w-10 h-10 object-cover' src={`${process.env.REACT_APP_PUBLIC_FOLDER_FLYERS}/${params.row.photo}`} alt={params.row.name} />
                        }        
                    </div>
                )
            } 
        },
        {
            field: 'types',
            headerName: 'Type',
            width: 200,
            renderCell: (params) => {
                return (
                    <div className="font-ssp flex gap-2">
                        {params.row.types.map((option) => (
                                <span key={option.type} className={`px-2 py-1 ${(option.type === "ONLINE") ? 'bg-blue-400' : 'bg-yellow-400' } rounded text-white uppercase`}>{option.type}</span>
                        ))}
                    </div>
                )
            } 
        },
        {
            field: 'is_active',
            headerName: 'Status',
            width: 150,
            renderCell: (params) => {
                return (
                    <div className="font-ssp">
                        {params.row.is_active === "1"
                            ? <span className="px-2 py-1 bg-green-400 rounded-full text-white uppercase">active</span>
                            : <span className="px-2 py-1 bg-red-400 rounded-full text-white uppercase">inactive</span>
                        }
                    </div>
                )
            } 
        },
        {
            field: 'action',
            headerName: 'Action',
            width: 150,
            renderCell: (params) => {
                return (
                    <div className='w-full flex flex-row gap-3 items-center'>
                        <Link to={"/dailys/" + params.row.uuid}>
                            <VisibilityIcon fontSize='medium' className="text-slate-400 hover:text-slate-500 cursor-pointer"/>
                        </Link>
                        {params.row.is_active === "1" 
                            ? <ToggleOnIcon className="text-green-400 hover:text-green-500 cursor-pointer" onClick={() => handleToggle(params.row.uuid)}/>
                            : <ToggleOffIcon className="text-gray-400 hover:text-gray-500 cursor-pointer" onClick={() => handleToggle(params.row.uuid)}/>
                        }
                        <DeleteIcon className="text-red-400 hover:text-red-500 cursor-pointer" onClick={() => handleModalDelete(params.row)}/>
                    </div>
                )
            }
        },
    ]

    return (
        <>
            {showModalAdd && 
                <ModalAddDaily setShowModalAdd={setShowModalAdd} setFetchMore={setFetchMore} />
            }
            {showModalDelete && 
                <ModalDeleteDaily setShowModalDelete={setShowModalDelete} data={data} setFetchMore={setFetchMore} />
            }
            <div className={`min-h-[calc(100vh-4rem)] flex flex-col ${showModalAdd && 'fixed min-w-[calc(100vw-16.666667%)]'} `}>
                {/* header  */}
                <div className="my-5 mb-5 mx-7 md:mx-5 flex flex-col md:flex-row justify-between">
                    <h1 className='font-ssp font-bold text-2xl md:text-3xl'>Daily Events</h1>
                    <div onClick={() => handleModal()} className="px-5 py-2.5 mr-3 mt-3 md:mt-1 bg-blue-400 hover:bg-blue-500 rounded-lg text-sm font-medium flex self-start md:self-end cursor-pointer">
                        <span className='text-sm text-white font-ssp'>Add New</span>
                    </div>
                </div>
                {/* content  */}
                <div className="my-5 mb-5 mx-7 md:mx-5 flex">
                    <div className="p-2 w-full flex bg-white drop-shadow-lg rounded-md">
                        <DataGrid
                            autoHeight {...datas}
                            rows={datas}
                            columns={columns}
                            pageSize={10}
                            getRowId={(row) => row.uuid}
                            rowsPerPageOptions={[10]}
                            checkboxSelection
                            disableSelectionOnClick
                            components={{ Toolbar: GridToolbar, }}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Dailys