import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { IoIosCloseCircle } from "react-icons/io";
import { getSpeaker, updateSpeaker } from '../../utilities/sendRequest';
import { decryptData } from '../../utilities/cryptoStorage';

const DetailSpeaker = () => {
    const token = JSON.parse(decryptData(localStorage.getItem('cRdT')));
    const navigate = useNavigate()
    const params = useParams()
    const photoRef = useRef(null);
    const [name, setName] = useState("")
    const [level, setLevel] = useState("")
    const [photo, setPhoto] = useState(null)
    const [status, setStatus] = useState("")
    const [data, setData] = useState({})
    const optionStatus = [
        {
            label: "Choose a Status",
            value: "",
        },
        {
            label: "ACTIVE",
            value: 1,
        },
        {
            label: "INACTIVE",
            value: 0,
        },
    ]
    const optionLevel = [
        {
            label: "Choose a level",
            value: "",
        },
        {
            label: "New Enterpriser",
            value: "New Enterpriser",
        },
        {
            label: "Asisten Enterpriser Manager",
            value: "Asisten Enterpriser Manager",
        },
        {
            label: "Enterpriser Manager",
            value: "Enterpriser Manager",
        },
        {
            label: "Enterpriser Leader",
            value: "Enterpriser Leader",
        },
        {
            label: "Silver Leader",
            value: "Silver Leader",
        },
        {
            label: "Diamond Leader",
            value: "Diamond Leader",
        },
        {
            label: "Executive Diamond",
            value: "Executive Diamond",
        },
        {
            label: "Star Diamond",
            value: "Star Diamond",
        },
        {
            label: "Grand Diamond",
            value: "Grand Diamond",
        },
        {
            label: "Executive Crown",
            value: "Executive Crown",
        },
        {
            label: "Star Crown",
            value: "Star Crown",
        },
        {
            label: "Grand Crown",
            value: "Grand Crown",
        },
        {
            label: "Royal Crown",
            value: "Royal Crown",
        },
    ]

    useEffect(() => {
        fetchSpeaker()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const fetchSpeaker = async () => {
        getSpeaker(params.id, token).then((res) => {
            setData(res.payload)
            setName(res.payload.name)
            setLevel(res.payload.level)
            setStatus(res.payload.is_active)
        }).catch((err) => {
            toast.error(err, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        })  
    }

    const handleResetImage = () => {
        setPhoto(null)
        photoRef.current.value = null;
    }

    const hanldeUpdate = async (e) => {
        e.preventDefault()

        const reqData = new FormData();
        reqData.append("name", name);
        reqData.append("level", level);
        reqData.append("status", status);

        if(photo) {
            reqData.append("photo", photo);
        }

        updateSpeaker(params.id, reqData, token).then((res) => {
            toast.success(res.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
            fetchSpeaker()
            handleResetImage()
        }).catch((err) => {
            toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        })
    }

    return (
        <div className='min-h-[calc(100vh-4rem)] flex flex-col'>
            {/* header  */}
            <div className="my-5 mx-7 md:mx-5 flex flex-row justify-between">
                <h1 className='font-ssp font-bold text-4xl md:text-3xl'>Detail Speaker</h1>
            </div>
            {/* content */}
            <div className="my-5 mx-7 md:mx-5 flex flex-col lg:flex-row justify-evenly gap-10">
                {/* detail speaker */}
                <div className="px-3 py-4 w-full h-full lg:w-1/4 md:w-1/2 bg-white drop-shadow-lg rounded-md flex flex-col gap-3 items-center">
                    {data.photo && 
                        <div className='w-32 h-32 bg-cyan-500 rounded-full flex justify-center items-center'>
                            <img src={`${process.env.REACT_APP_PUBLIC_FOLDER_SPEAKERS}/${data.photo}`} alt={name} className='w-28 h-28 rounded-full object-cover object-top' />
                        </div>
                    }
                    <div className="mt-3 p-3 flex flex-col gap-2 text-center">
                        <span className='font-ssp text-base'>{data.name}</span>
                        <span className='font-ssp text-sm font-light'>{data.level}</span>
                    </div>
                </div>
                {/* form update */}
                <div className="px-2 py-4 w-full lg:w-2/3 md:w-10/12 bg-white drop-shadow-lg rounded-md flex">
                    <form onSubmit={hanldeUpdate} className='px-5 w-full flex flex-col'>
                        {/* name */}
                        <div className="mt-3 mb-6 w-full relative z-0 group">
                            <input 
                                minLength={5} 
                                value={name} 
                                onChange={(e) => setName(e.target.value)} 
                                type="text" 
                                name="name" 
                                id="name" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                            focus:border-blue-600 peer" 
                                placeholder=" " 
                                required 
                            />
                            <label 
                                htmlFor="name" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 duration-300 transform -translate-y-6 
                                            scale-75 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:font-medium 
                                            peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Name</label>
                        </div>
                        {/* peringkat */}
                        <div className="mb-6 mt-3 w-full relative z-0 group">
                            <select 
                                required value={level} 
                                onChange={(e) => setLevel(e.target.value)} 
                                id="level" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-500 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                            focus:border-blue-600 peer"
                            >
                                {optionLevel.map((option) => (
                                    <option key={option.value} value={option.value}>{option.label}</option>
                                ))}
                            </select>
                            <label 
                                htmlFor="level" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 
                                            duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                            peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                            peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Peringkat</label>
                        </div>
                        {/* status */}
                        <div className="mb-6 mt-3 w-full relative z-0 group">
                            <select 
                                required 
                                value={status} 
                                onChange={(e) => setStatus(e.target.value)} 
                                id="status" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-500 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                            focus:border-blue-600 peer"
                            >
                                {optionStatus.map((option) => (
                                    <option key={option.value} value={option.value}>{option.label}</option>
                                ))}
                            </select>
                            <label 
                                htmlFor="status" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 
                                            duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:font-medium 
                                            peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 
                                            peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Status</label>
                        </div>
                        {/* file */}
                        <div className="mb-6 mt-3 w-full relative z-0 group">
                            <label className="block mb-2 font-ssp text-xs font-medium text-gray-500" htmlFor="photo">New Photo</label>
                            <input 
                                type="file" 
                                name="photo"
                                id="photo"
                                ref={photoRef}
                                onChange={(e) => setPhoto(e.target.files[0])}
                                accept='.png'
                                className="block w-full text-sm text-slate-500
                                    file:mr-4 file:py-2 file:px-4
                                    file:rounded-full file:border-0
                                    file:text-sm file:font-semibold
                                    file:bg-gray-50 file:text-gray-500
                                    hover:file:bg-gray-100"
                            />
                            <p className="mt-1 font-ssp text-xs text-gray-500 dark:text-gray-300" id="photo">PNG (MAX. 5MB).</p>
                        </div>
                        {photo && 
                            <div className="mt-3 w-full relative flex flex-row group">
                                <img src={URL.createObjectURL(photo)} alt="Preview" className="w-1/4" />
                                <IoIosCloseCircle fontSize='25px' className='-mt-3 -ml-3 item-start bg-white rounded-full cursor-pointer' onClick={() => handleResetImage()} />
                            </div>
                        }
                        <div className="mb-6 w-full relative z-0 flex justify-center gap-5">
                            <button 
                                type="submit" 
                                className="px-5 py-2.5 mt-5 w-full sm:w-auto bg-green-400 hover:bg-green-500 rounded-lg 
                                            font-medium text-sm text-center text-white cursor-pointer "
                            >Update</button>
                            <span 
                                onClick={() => navigate("/speakers")} 
                                className="px-5 py-2.5 mt-5 w-full sm:w-auto bg-red-400 hover:bg-red-500 rounded-lg 
                                            font-medium text-sm text-center text-white cursor-pointer"
                            >Back</span>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default DetailSpeaker