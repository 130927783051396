import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { IoIosCloseCircle } from "react-icons/io";
import { getBroadcast, updateBroadcast } from '../../utilities/sendRequest';
import { decryptData } from '../../utilities/cryptoStorage';
import { checkTimeStamp } from '../../utilities/helper';

const DetailBroadcast = () => {
    const token = JSON.parse(decryptData(localStorage.getItem('cRdT')));
    const params = useParams()
    const navigate = useNavigate()
    const photoRef = useRef(null)
    const [datas, setDatas] = useState([])
    const [title, setTitle] = useState("")
    const [link, setLink] = useState("")
    const [startDate, setStartDate] = useState("")
    const [startTime, setStartTime] = useState("")
    const [endDate, setEndDate] = useState("")
    const [endTime, setEndTime] = useState("")
    const [photo, setPhoto] = useState(null)
    
    useEffect(() => {
        fetchBroadcast()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const fetchBroadcast = async () => {
        getBroadcast(params.id, token).then((res) => {
            setDatas(res.payload);
            setTitle(res.payload.title)
            setLink(res.payload.link)

            const [start_date_at, start_time_at] = res.payload.start_publish_at.split(' ');
            const [end_date_at, end_time_at] = res.payload.end_publish_at.split(' ');

            setStartDate(start_date_at)
            setStartTime(start_time_at)
            setEndDate(end_date_at)
            setEndTime(end_time_at)

        }).catch((err) => {
            toast.error(err, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        })  
    }

    const handleResetThumbnail = () => {
        setPhoto(null)
        photoRef.current.value = null;
    }

    const hanldeUpdate = async (e) => {
        e.preventDefault()

        if (!checkTimeStamp(startDate, startTime, endDate, endTime)) {
            toast.error("Tanggal waktu awal dan berakhir publish tidak sesuai", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
            return
        }

        const reqData = new FormData();
        reqData.append("title", title);
        reqData.append("link", link);
        reqData.append("startDate", startDate);
        reqData.append("startTime", startTime);
        reqData.append("endDate", endDate);
        reqData.append("endTime", endTime);

        if(photo) {
            reqData.append("photo", photo);
        }

        updateBroadcast(params.id, reqData, token).then((res) => {
            toast.success(res.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
            fetchBroadcast()
            handleResetThumbnail()
        }).catch((error) => {
            console.log(error);
            toast.error(error.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        })
    }

    return (
        <div className='min-h-[calc(100vh-4rem)] flex flex-col'>
            {/* header  */}
            <div className="my-5 mx-7 md:mx-5 flex flex-row justify-between">
                <h1 className='font-ssp font-bold text-4xl md:text-3xl'>Detail Broadcast</h1>
            </div>
            {/* content */}
            <div className="my-5 mx-7 md:mx-5 flex flex-col lg:flex-row justify-evenly gap-7 md:gap-10">
                {/* detail broadcast */}
                <div className="w-full h-full lg:w-1/4 md:w-1/2 flex flex-col gap-3 items-center">
                    {datas.photo && 
                        <div className='px-3 py-4 w-full h-full bg-white drop-shadow-lg rounded-md flex flex-col gap-2'>
                            <h3 className='text-lg font-ssp font-semibold text-center'>Photo</h3>
                            <img src={`${process.env.REACT_APP_PUBLIC_FOLDER_BROADCASTS}/${datas.photo}`} alt={title} />
                        </div>
                    }
                </div>

                {/* form update */}
                <div className="px-2 py-4 w-full h-fit lg:w-2/3 md:w-10/12 bg-white drop-shadow-lg rounded-md flex">
                    <form onSubmit={hanldeUpdate} className='px-5 w-full flex flex-wrap justify-between'>
                        {/* title */}
                        <div className="mt-3 mb-6 w-[45%] relative z-0 group">
                            <input 
                                minLength={5} 
                                value={title} 
                                onChange={(e) => setTitle(e.target.value)} 
                                type="text" 
                                name="title" 
                                id="title" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                            focus:border-blue-600 peer" 
                                placeholder=" " 
                                required 
                            />
                            <label 
                                htmlFor="title" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 
                                            duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                            peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                            peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Title</label>
                        </div>
                        {/* link */}
                        <div className="mt-3 mb-6 w-[45%] relative z-0 group">
                            <input 
                                minLength={5} 
                                value={link} 
                                onChange={(e) => setLink(e.target.value)} 
                                type="text" 
                                name="link" 
                                id="link" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                            focus:border-blue-600 peer" 
                                placeholder=" " 
                                required 
                            />
                            <label 
                                htmlFor="link" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 dark:text-gray-400 
                                            duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                            peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                            peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Link</label>
                        </div>
                        {/* start date */}
                        <div className="mt-3 mb-6 w-[45%] relative z-0 group">
                            <input 
                                value={startDate} 
                                onChange={(e) => setStartDate(e.target.value)} 
                                type="date" 
                                name="startDate" 
                                id="startDate" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                            focus:border-blue-600 peer" 
                                placeholder=" " 
                                required 
                            />
                            <label 
                                htmlFor="startDate" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 dark:text-gray-400 
                                            duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                            peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                            peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Start Publish Date</label>
                        </div>
                        {/* start time */}
                        <div className="mt-3 mb-6 w-[45%] relative z-0 group">
                            <input 
                                value={startTime} 
                                onChange={(e) => setStartTime(e.target.value)} 
                                type="time" 
                                name="startTime" 
                                id="startTime" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                            focus:border-blue-600 peer" 
                                placeholder=" " 
                                required 
                            />
                            <label 
                                htmlFor="startTime" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 dark:text-gray-400 
                                            duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                            peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                            peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Start Publish Time</label>
                        </div>
                        {/* end date */}
                        <div className="mt-3 mb-6 w-[45%] relative z-0 group">
                            <input 
                                value={endDate} 
                                onChange={(e) => setEndDate(e.target.value)} 
                                type="date" 
                                name="endDate" 
                                id="endDate" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                            focus:border-blue-600 peer" 
                                placeholder=" " 
                                required 
                            />
                            <label 
                                htmlFor="endDate" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 dark:text-gray-400 
                                            duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                            peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                            peer-focus:scale-75 peer-focus:-translate-y-6"
                            >End Publish Date</label>
                        </div>
                        {/* end time */}
                        <div className="mt-3 mb-6 w-[45%] relative z-0 group">
                            <input 
                                value={endTime} 
                                onChange={(e) => setEndTime(e.target.value)} 
                                type="time" 
                                name="endTime" 
                                id="endTime" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                            focus:border-blue-600 peer" 
                                placeholder=" " 
                                required 
                            />
                            <label 
                                htmlFor="endTime" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 dark:text-gray-400 
                                            duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                            peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                            peer-focus:scale-75 peer-focus:-translate-y-6"
                            >End Publish Time</label>
                        </div>
                        {/* photo */}
                        <div className="mt-3 mb-6 w-full relative z-0 group">
                            <label 
                                className="block mb-2 font-ssp text-xs font-medium text-gray-500" htmlFor="photo">New Photo</label>
                            <input 
                                type="file" 
                                name="photo"
                                id="photo"
                                ref={photoRef}
                                onChange={(e) => setPhoto(e.target.files[0])}
                                accept='.png, .jpg, .jpeg'
                                className="block w-full text-sm text-slate-500
                                    file:mr-4 file:py-2 file:px-4
                                    file:rounded-full file:border-0
                                    file:text-sm file:font-semibold
                                    file:bg-gray-50 file:text-gray-500
                                    hover:file:bg-gray-100"
                            />
                            <p className="mt-1 font-ssp text-xs text-gray-500 dark:text-gray-300" id="photo">PNG, JPG, JPEG (MAX. 5MB).</p>
                        </div>
                        {photo && 
                            <div className="mt-3 w-full relative flex flex-row group">
                                <img src={URL.createObjectURL(photo)} alt="Preview" className="w-1/4" />
                                <IoIosCloseCircle  
                                    fontSize='25px' 
                                    className='-mt-3 -ml-3 bg-white rounded-full item-start cursor-pointer' 
                                    onClick={handleResetThumbnail} 
                                />
                            </div>
                        }
                        {/* button */}
                        <div className="mb-6 w-full relative z-0 flex justify-center gap-5">
                            <button 
                                type="submit" 
                                className="mt-5 px-5 py-2.5 w-full sm:w-auto bg-green-400 hover:bg-green-500 rounded-lg 
                                            font-medium text-white text-sm text-center cursor-pointer"
                            >Update</button>
                            <span 
                                onClick={() => navigate('/broadcasts')} 
                                className="mt-5 px-5 py-2.5 w-full sm:w-auto bg-red-400 hover:bg-red-500 rounded-lg 
                                            font-medium text-white text-sm text-center cursor-pointer"
                            >Back</span>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default DetailBroadcast