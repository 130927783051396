import React from 'react';
import { Dashboard, DetailEmployee, Employees, Profile, 
    Topbar, DetailSpeaker, Speakers, Nationals, DetailNational, 
    Dailys, DetailDaily, Posts, DetailPost, ProductCategories, DetailProductCategory, Products, DetailProduct,
    BvCalculation, DetailBvCalculation, Testimonials, DetailTestimonial, DetailTestimonialCategory, Diseases, DetailDiseases, 
    Benefits, DetailBenefits, BillContents, DetailBillCategory, DetailBill, SuccessProfiles, DetailProfileCategory, DetailProfile,
    MarketPlaces, AudioContents, Enterprisers, DetailEnterpriser, Transactions, DetailTransaction, Carousels, DetailCarousel,
    Broadcasts, DetailBroadcast, TransactionReport } from '../components';
import { Routes, Route } from 'react-router-dom';
import Error404 from '../pages/Error/404';
import Error500 from '../pages/Error/500'
import Error403 from '../pages/Error/403';
import PrivateRoute from './privateRoute';


const ContainerPanel = ({ handleSidebar }) => {
    return (
        <div>
            <Topbar handleSidebar={handleSidebar} />
            <div className="h-full">
                <Routes>
                    <Route path='/' element={<Dashboard />} />

                    <Route element={<PrivateRoute allowedRoles={['it']} />}>
                        <Route path='/employees' element={<Employees />} /> 
                        <Route path='/employees/:uuid' element={<DetailEmployee />} />
                        <Route path='/bvCalculation' element={<BvCalculation />} /> 
                        <Route path='/bvCalculation/:id' element={<DetailBvCalculation />} />
                        <Route path='/testimonials' element={<Testimonials />} />
                        <Route path='/testimonials/category/:id' element={<DetailTestimonialCategory />} />
                        <Route path='/testimonials/:id' element={<DetailTestimonial />} />
                        <Route path='/contents' element={<BillContents />} />
                        <Route path='/contents/category/:id' element={<DetailBillCategory />} />
                        <Route path='/contents/:id' element={<DetailBill />} />
                        <Route path='/diseases' element={<Diseases />} /> 
                        <Route path='/diseases/:id' element={<DetailDiseases />} />
                        <Route path='/benefits' element={<Benefits />} /> 
                        <Route path='/benefits/:id' element={<DetailBenefits />} />
                        <Route path='/success' element={<SuccessProfiles />} />
                        <Route path='/success/category/:id' element={<DetailProfileCategory />} />
                        <Route path='/success/:id' element={<DetailProfile />} />

                        <Route path='/audios/:slug' element={<AudioContents />} />
                        <Route path='/enterprisers/:slug' element={<Enterprisers />} />
                        <Route path='/enterprisers/detail/:id' element={<DetailEnterpriser />} />

                        <Route path='/carousels' element={<Carousels />} /> 
                        <Route path='/carousels/:id' element={<DetailCarousel />} />
                    </Route>

                    <Route element={<PrivateRoute allowedRoles={['it', 'event']} />}>
                        <Route path='/posts' element={<Posts />} />
                        <Route path='/posts/:id' element={<DetailPost />} />
                        <Route path='/speakers' element={<Speakers />} />
                        <Route path='/speakers/:id' element={<DetailSpeaker />} />
                        <Route path='/nationals' element={<Nationals />} />
                        <Route path='/nationals/:uuid' element={<DetailNational />} />
                        <Route path='/dailys' element={<Dailys />} />
                        <Route path='/dailys/:uuid' element={<DetailDaily />} />
                    </Route>

                    <Route element={<PrivateRoute allowedRoles={['it', 'market']} />}>
                        <Route path='/productsCategories' element={<ProductCategories />} /> 
                        <Route path='/productsCategories/:id' element={<DetailProductCategory />} />
                        <Route path='/products' element={<Products />} /> 
                        <Route path='/products/:id' element={<DetailProduct />} />
                        <Route path='/marketPlaces' element={<MarketPlaces />} />
                        <Route path='/transactions' element={<Transactions />} />
                        <Route path='/transactions/detail/:id' element={<DetailTransaction />} />
                        <Route path='/reports' element={<TransactionReport />} />
                    </Route>

                    <Route path='/broadcasts' element={<Broadcasts />} /> 
                    <Route path='/broadcasts/:id' element={<DetailBroadcast />} />
                    
                    <Route path='/profile' element={<Profile />} />
                    
                    <Route path='/*' element={<Error404 />} />
                    <Route path='/500' element={<Error500 />} />
                    <Route path='/403' element={<Error403 />} />
                </Routes>
            </div>
        </div>
    )
}

export default ContainerPanel