import React, { useContext, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { RiCloseFill } from "react-icons/ri";
import { IoIosCloseCircle } from "react-icons/io";
import { PopupContext } from "../../context/popupContext";
import { addProduct, getProductCategories } from '../../utilities/sendRequest';
import { decryptData } from '../../utilities/cryptoStorage';

const ModalAddProduct = ({ setShowModalAdd, setFetchMore }) => {
    const token = JSON.parse(decryptData(localStorage.getItem('cRdT')));
    const { popupDispatch } = useContext(PopupContext)
    const clickRef = useRef(null)
    const thumbnail1Ref = useRef(null)
    const thumbnail2Ref = useRef(null)
    const [thumbnail1, setThumbnail1] = useState(null)
    const [thumbnail2, setThumbnail2] = useState(null)
    const [kode, setKode] = useState("")
    const [name, setName] = useState("")
    const [price, setPrice] = useState("")
    const [code, setCode] = useState("")
    const [duration, setDuration] = useState("")
    const [categories, setCategories] = useState([])
    const [category, setCategory] = useState("")
    const [keterangan, setKeterangan] = useState("")
    const [status, setStatus] = useState("")
    const options = [
        {
            label: "ACTIVE",
            value: 1
        },
        {
            label: "INACTIVE",
            value: 0
        },
    ]

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (clickRef.current && !clickRef.current.contains(event.target)) {
                setShowModalAdd(null)
                popupDispatch({ type: "POPUP_END" });
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clickRef])

    useEffect(() => {
        fetchCategories()
        // eslint-disable-next-line
    }, [])

    const fetchCategories = async () => {
        getProductCategories(token).then((res) => {
            setCategories(res.payload)
        }).catch((err) => {
            toast.error(err.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        
        const reqData = new FormData();
        reqData.append("kode", kode);
        reqData.append("name", name);
        reqData.append("price", price);
        reqData.append("category", category);
        reqData.append("keterangan", keterangan);
        reqData.append("status", status);
        reqData.append("duration", duration);
        reqData.append("code", code);

        if (thumbnail1) {
            reqData.append("thumbnail1", thumbnail1);
        }

        if (thumbnail2) {
            reqData.append("thumbnail2", thumbnail2);
        }

        addProduct(reqData, token).then((res) => {
            toast.success(res.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
            setFetchMore(true)
            setShowModalAdd(null)
            handleResetImage(3)
        }).catch((err) => {
            toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        })
        popupDispatch({ type: "POPUP_END" });
    }

    const handleModal = () => {
        setShowModalAdd(null)
        popupDispatch({ type: "POPUP_END" });
    }

    const handleResetImage = (id) => {
        if (id === 1) {
            setThumbnail1(null)
            thumbnail1Ref.current.value = null;
        } else if (id === 2) {
            setThumbnail2(null)
            thumbnail2Ref.current.value = null;
        } else {
            setThumbnail1(null)
            thumbnail1Ref.current.value = null;

            setThumbnail2(null)
            thumbnail2Ref.current.value = null;
        }
    }


    return (
        <>
            <div  className="absolute top-0 left-0 z-10 p-3 w-screen min-h-screen overscroll-y-none overflow-y-hidden bg-black bg-opacity-70 flex items-center justify-center">
                <div ref={clickRef} className="p-3 w-11/12 lg:w-1/3 md:w-1/2 h-full bg-white rounded-md flex flex-col">
                    <div className="px-2 flex justify-between items-center">
                        <h3 className='font-bold font-ssp text-xl md:text-2xl'>Add New Product</h3>
                        <RiCloseFill onClick={handleModal} className='w-8 h-8 cursor-pointer'/>
                    </div>
                    <form onSubmit={handleSubmit} className='mt-5 px-5 w-full flex flex-col'>
                        {/* kode */}
                        <div className="relative z-0 mb-3 mt-3 w-full group">
                            <input 
                                minLength={3} 
                                value={kode} 
                                onChange={(e) => setKode(e.target.value)} 
                                type="text" 
                                name="kode" 
                                id="kode" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 
                                            appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                                placeholder=" " 
                                required 
                            />
                            <label 
                                htmlFor="kode" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 dark:text-gray-400 
                                        duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:font-medium
                                        peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 
                                        peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Kode</label>
                        </div>
                        {/* name */}
                        <div className="relative z-0 mb-3 mt-3 w-full group">
                            <input 
                                minLength={3} 
                                value={name} 
                                onChange={(e) => setName(e.target.value)} 
                                type="text" 
                                name="name" 
                                id="name" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 
                                            appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                                placeholder=" " 
                                required 
                            />
                            <label 
                                htmlFor="name" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 dark:text-gray-400 
                                        duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:font-medium
                                        peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 
                                        peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Name</label>
                        </div>
                        {/* price */}
                        <div className="relative z-0 mb-3 mt-3 w-full group">
                            <input 
                                value={price} 
                                onChange={(e) => setPrice(e.target.value)} 
                                type="number" 
                                name="price" 
                                id="price" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 
                                            appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                                placeholder=" " 
                                required 
                            />
                            <label 
                                htmlFor="price" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 dark:text-gray-400 
                                        duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:font-medium
                                        peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 
                                        peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Price</label>
                        </div>
                        {/* category */}
                        <div className="mb-3 mt-3 w-full relative z-0 group">
                            <select 
                                required value={category} 
                                onChange={(e) => setCategory(e.target.value)} 
                                id="category" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-500 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                            focus:border-blue-600 peer"
                            >
                                <option value="">Choose a category</option>
                                {categories.map((option) => (
                                    <option key={option.id} value={option.id} >{option.name} </option>
                                ))}
                            </select>
                            <label 
                                htmlFor="category" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 
                                            duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:font-medium 
                                            peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 
                                            peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Category</label>
                        </div>
                        {category === "6" && 
                            <>
                                {/* code */}
                                <div className="relative z-0 mb-3 mt-3 w-full group">
                                    <input  
                                        value={code} 
                                        onChange={(e) => setCode(e.target.value)} 
                                        type="text" 
                                        name="code" 
                                        id="code" 
                                        className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 
                                                    appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                                        placeholder=" " 
                                        required 
                                    />
                                    <label 
                                        htmlFor="code" 
                                        className="absolute top-3 -z-10 text-sm text-gray-500 dark:text-gray-400 
                                                duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:font-medium
                                                peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 
                                                peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                    >Code VIP</label>
                                </div>
                                {/* duration */}
                                <div className="relative z-0 mb-3 mt-3 w-full group">
                                    <input  
                                        value={duration} 
                                        onChange={(e) => setDuration(e.target.value)} 
                                        type="text" 
                                        name="duration" 
                                        id="duration" 
                                        className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 
                                                    appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                                        placeholder=" " 
                                        required 
                                    />
                                    <label 
                                        htmlFor="duration" 
                                        className="absolute top-3 -z-10 text-sm text-gray-500 dark:text-gray-400 
                                                duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:font-medium
                                                peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 
                                                peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                    >Duration (Days)</label>
                                    <p className="mt-1 font-ssp text-xs text-gray-500 dark:text-gray-300" id="duration">1 Month = 30 Days, 1 Year = 365 Days</p>
                                </div>
                            </>
                        }
                        {/* keterangan */}
                        <div className="relative z-0 mb-3 mt-3 w-full group">
                            <input  
                                value={keterangan} 
                                onChange={(e) => setKeterangan(e.target.value)} 
                                type="text" 
                                name="keterangan" 
                                id="keterangan" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 
                                            appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                                placeholder=" " 
                            />
                            <label 
                                htmlFor="keterangan" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 dark:text-gray-400 
                                        duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:font-medium
                                        peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 
                                        peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Keterangan</label>
                        </div>
                        {/* status */}
                        <div className="mb-3 mt-4 w-full relative z-0 group">
                            <select required value={status} 
                                onChange={(e) => setStatus(e.target.value)} 
                                id="underline_select" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-500 bg-transparent 
                                border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                focus:border-blue-600 peer"
                            >
                                <option value="">Choose a status</option>
                                {options.map((option) => (
                                    <option key={option.value} value={option.value} >{option.label} </option>
                                ))}
                            </select>
                            <label 
                                htmlFor="underline_select" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 
                                duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Status</label>
                        </div>
                        {/* thumbnail 1 */}
                        <div className="mb-3 mt-3 w-full relative z-0 group">
                            <label 
                                className="block mb-2 font-ssp text-sm font-medium text-gray-500" 
                                htmlFor="file_input"
                            >Upload Cover Depan</label>
                            <input 
                                type="file" 
                                name="thumbnail1"
                                ref={thumbnail1Ref}
                                onChange={(e) => setThumbnail1(e.target.files[0])}
                                accept='.png, .jpg, .jpeg'
                                className="block w-full text-sm text-slate-500
                                    file:mr-4 file:py-2 file:px-4
                                    file:rounded-full file:border-0
                                    file:text-sm file:font-semibold
                                    file:bg-gray-50 file:text-gray-500
                                    hover:file:bg-gray-100"
                            />
                            <p className="mt-1 font-ssp text-xs text-gray-500 dark:text-gray-300" id="file_input_help">PNG, JPG, JPEG (MAX. 5MB).</p>
                        </div>
                        {thumbnail1 && 
                            <div className="mt-3 w-full relative flex flex-row group">
                                <img src={URL.createObjectURL(thumbnail1)} alt="Preview" className="w-1/4" />
                                <IoIosCloseCircle  fontSize='25px' className='-mt-3 -ml-3 bg-white rounded-full item-start cursor-pointer' onClick={() => handleResetImage(1)} />
                            </div>
                        }
                        {/* thumbnail 2 */}
                        <div className="mb-3 mt-3 w-full relative z-0 group">
                            <label 
                                className="block mb-2 font-ssp text-sm font-medium text-gray-500" 
                                htmlFor="file_input2"
                            >Upload Cover Belakang</label>
                            <input 
                                type="file" 
                                name="thumbnail2"
                                ref={thumbnail2Ref}
                                onChange={(e) => setThumbnail2(e.target.files[0])}
                                accept='.png, .jpg, .jpeg'
                                className="block w-full text-sm text-slate-500
                                    file:mr-4 file:py-2 file:px-4
                                    file:rounded-full file:border-0
                                    file:text-sm file:font-semibold
                                    file:bg-gray-50 file:text-gray-500
                                    hover:file:bg-gray-100"
                            />
                            <p className="mt-1 font-ssp text-xs text-gray-500 dark:text-gray-300" id="file_input2_help">PNG, JPG, JPEG (MAX. 5MB).</p>
                        </div>
                        {thumbnail2 && 
                            <div className="mt-3 w-full relative flex flex-row group">
                                <img src={URL.createObjectURL(thumbnail2)} alt="Preview" className="w-1/4" />
                                <IoIosCloseCircle  fontSize='25px' className='-mt-3 -ml-3 bg-white rounded-full item-start cursor-pointer' onClick={() => handleResetImage(2)} />
                            </div>
                        }
                        {/* Button */}
                        <div className="mb-6 mt-5 w-full relative z-0 flex gap-3 justify-end">
                            <button type="submit" 
                                className="mt-5 px-5 py-2.5 w-full sm:w-auto bg-blue-400 hover:bg-blue-500 rounded-lg text-sm text-white
                                            text-center font-medium cursor-pointer"
                            >Save</button>
                            <span onClick={handleModal} 
                                className="px-5 py-2.5 mt-5 w-full sm:w-auto bg-red-400 hover:bg-red-500 rounded-lg text-sm text-white
                                            text-center font-medium cursor-pointer"
                            >Close</span>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default ModalAddProduct