import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { IoIosCloseCircle } from "react-icons/io";
import { getDisease, updateDisease } from '../../utilities/sendRequest';
import { decryptData } from '../../utilities/cryptoStorage';

const DetailDiseases = () => {
    const token = JSON.parse(decryptData(localStorage.getItem('cRdT')));
    const navigate = useNavigate()
    const params = useParams()
    const thumbnailInputRef = useRef(null); 
    const contentInputRef = useRef(null); 
    const [title, setTitle] = useState("")
    const [status, setStatus] = useState("")
    const [thumbnail, setThumbnail] = useState(null)
    const [content, setContent] = useState(null)
    const [data, setData] = useState({})
    const optionStatus = [
        {
            label: "Choose a Status",
            value: "",
        },
        {
            label: "ACTIVE",
            value: 1,
        },
        {
            label: "INACTIVE",
            value: 0,
        },
    ]

    useEffect(() => {
        fetcDisease()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const fetcDisease = async () => {
        getDisease(params.id, token).then((res) => {
            setData(res.payload)
            setTitle(res.payload.title)
            setStatus(res.payload.is_active)
        }).catch((err) => {
            toast.error(err, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        })  
    }

    const handleResetImage = (id) => {
        if (id === 1) {
            setThumbnail(null)
            thumbnailInputRef.current.value = null;
        } else if (id === 2) {
            setContent(null)
            contentInputRef.current.value = null;
        } else {
            setThumbnail(null)
            thumbnailInputRef.current.value = null;

            setContent(null)
            contentInputRef.current.value = null;
        }
    }

    const hanldeUpdate = async (e) => {
        e.preventDefault()

        const reqData = new FormData();
        reqData.append("title", title);
        reqData.append("status", status);

        if (thumbnail) {
            reqData.append("thumbnail", thumbnail);
        }

        if (content) {
            reqData.append("content", content);
        }

        updateDisease(params.id, reqData, token).then((res) => {
            toast.success(res.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
            fetcDisease()
            handleResetImage(3)
        }).catch((err) => {
            toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        })
    }

    return (
        <div className='min-h-[calc(100vh-4rem)] flex flex-col'>
            {/* header  */}
            <div className="my-5 mx-7 md:mx-5 flex flex-row justify-between">
                <h1 className='font-ssp font-bold text-4xl md:text-3xl'>Detail Daftar Penyakit</h1>
            </div>
            {/* content */}
            <div className="my-5 mx-7 md:mx-5 flex flex-col lg:flex-row justify-evenly gap-7 md:gap-10">
                <div className="w-full h-full lg:w-1/4 md:w-1/2 flex flex-col gap-3 items-center">
                    {data.thumbnail && 
                        <div className='px-3 py-4 w-full h-full bg-white drop-shadow-lg rounded-md flex flex-col gap-2'>
                            <h3 className='text-lg font-ssp font-semibold text-center'>Thumbnail</h3>
                            <img src={`${process.env.REACT_APP_PUBLIC_FOLDER_DISEASES_THUMBNAILS}/${data.thumbnail}`} alt={data.title} />
                        </div>
                    }
                    {data.content && 
                        <div className='px-3 py-4 w-full h-full bg-white drop-shadow-lg rounded-md flex flex-col gap-2'>
                            <h3 className='text-lg font-ssp font-semibold text-center'>Content</h3>
                            <img src={`${process.env.REACT_APP_PUBLIC_FOLDER_DISEASES_CONTENTS}/${data.content}`} alt={data.title} />
                        </div>
                    }
                </div>
                {/* form update */}
                <div className="px-2 py-4 h-fit w-full lg:w-2/3 md:w-10/12 bg-white drop-shadow-lg rounded-md flex">
                    <form onSubmit={hanldeUpdate} className='px-5 w-full flex flex-wrap justify-between'>
                        {/* title */}
                        <div className="mt-3 mb-6 w-full md:w-[45%] relative z-0 group">
                            <input 
                                minLength={3} 
                                value={title} 
                                onChange={(e) => setTitle(e.target.value)} 
                                type="text" 
                                name="title" 
                                id="title" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                            focus:border-blue-600 peer" 
                                placeholder=" " 
                                required 
                            />
                            <label 
                                htmlFor="title" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 duration-300 transform -translate-y-6 
                                            scale-75 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:font-medium 
                                            peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Title</label>
                        </div>
                        {/* status */}
                        <div className="mb-6 mt-3 w-full md:w-[45%] relative z-0 group">
                            <select 
                                required 
                                value={status} 
                                onChange={(e) => setStatus(e.target.value)} 
                                id="status" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-500 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                            focus:border-blue-600 peer"
                            >
                                {optionStatus.map((option) => (
                                    <option key={option.value} value={option.value}>{option.label}</option>
                                ))}
                            </select>
                            <label 
                                htmlFor="status" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 
                                            duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:font-medium 
                                            peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 
                                            peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Status</label>
                        </div>

                        {/* thumbnail*/}
                        <div className="mb-6 mt-3 w-full relative z-0 group">
                            <label className="block mb-2 font-ssp text-xs font-medium text-gray-500" htmlFor="thumbnail">New Thumbnail</label>
                            <input 
                                type="file" 
                                name="thumbnail"
                                id="thumbnail"
                                ref={thumbnailInputRef}
                                onChange={(e) => setThumbnail(e.target.files[0])}
                                accept='.png, .jpg, .jpeg'
                                className="block w-full text-sm text-slate-500
                                    file:mr-4 file:py-2 file:px-4
                                    file:rounded-full file:border-0
                                    file:text-sm file:font-semibold
                                    file:bg-gray-50 file:text-gray-500
                                    hover:file:bg-gray-100"
                            />
                            <p className="mt-1 font-ssp text-xs text-gray-500 dark:text-gray-300" id="thumbnail">PNG, JPG, JPEG (MAX. 5MB).</p>
                        </div>
                        {thumbnail && 
                            <div className="mt-3 w-full relative flex flex-row group">
                                <img src={URL.createObjectURL(thumbnail)} alt="Preview" className="w-1/4" />
                                <IoIosCloseCircle fontSize='25px' className='-mt-3 -ml-3 item-start bg-white rounded-full cursor-pointer' onClick={() => handleResetImage(1)} />
                            </div>
                        }
                        {/* content*/}
                        <div className="mb-6 mt-3 w-full relative z-0 group">
                            <label className="block mb-2 font-ssp text-xs font-medium text-gray-500" htmlFor="content">New Content</label>
                            <input 
                                type="file" 
                                name="content"
                                id="content"
                                ref={contentInputRef}
                                onChange={(e) => setContent(e.target.files[0])}
                                accept='.png, .jpg, .jpeg'
                                className="block w-full text-sm text-slate-500
                                    file:mr-4 file:py-2 file:px-4
                                    file:rounded-full file:border-0
                                    file:text-sm file:font-semibold
                                    file:bg-gray-50 file:text-gray-500
                                    hover:file:bg-gray-100"
                            />
                            <p className="mt-1 font-ssp text-xs text-gray-500 dark:text-gray-300" id="content">PNG, JPG, JPEG (MAX. 5MB).</p>
                        </div>
                        {content && 
                            <div className="mt-3 w-full relative flex flex-row group">
                                <img src={URL.createObjectURL(content)} alt="Preview" className="w-1/4" />
                                <IoIosCloseCircle fontSize='25px' className='-mt-3 -ml-3 item-start bg-white rounded-full cursor-pointer' onClick={() => handleResetImage(2)} />
                            </div>
                        }
                        
                        <div className="mb-6 w-full relative z-0 flex justify-center gap-5">
                            <button 
                                type="submit" 
                                className="px-5 py-2.5 mt-5 w-full h-fit sm:w-auto bg-green-400 hover:bg-green-500 rounded-lg 
                                            font-medium text-sm text-center text-white cursor-pointer "
                            >Update</button>
                            <span 
                                onClick={() => navigate("/diseases")} 
                                className="px-5 py-2.5 mt-5 w-full h-fit sm:w-auto bg-red-400 hover:bg-red-500 rounded-lg 
                                            font-medium text-sm text-center text-white cursor-pointer"
                            >Back</span>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default DetailDiseases