import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getProduct, updateProduct, getProductCategories, getMarketPlaces, getProductLinks, addProductLink, deleteProductLink } from '../../utilities/sendRequest';
import { IoIosCloseCircle } from "react-icons/io";
import DeleteIcon from '@mui/icons-material/Delete';
import { decryptData } from '../../utilities/cryptoStorage';

const DetailProduct = () => {
    const token = JSON.parse(decryptData(localStorage.getItem('cRdT')));
    const [kode, setKode] = useState("")
    const [name, setName] = useState("")
    const [price, setPrice] = useState("")
    const [keterangan, setKeterangan] = useState("")
    const [category, setCategory] = useState("")
    const [categories, setCategories] = useState([])
    const [marketPlaces, setMarketPlaces] = useState([])
    const [marketPlace, setMarketPlace] = useState("")
    const [link, setLink] = useState("")
    const [duration, setDuration] = useState("")
    const [code, setCode] = useState("")
    const [linkDatas, setLinkDatas] = useState([])
    const [status, setStatus] = useState("")
    const [thumbnail1, setThumbnail1] = useState(null)
    const [thumbnail2, setThumbnail2] = useState(null)
    const [data, setData] = useState({})
    const params = useParams()
    const thumbnail1InputRef = useRef(null); 
    const thumbnail2InputRef = useRef(null); 
    const navigate = useNavigate()
    const optionStatus = [
        {
            label: "Choose a Status",
            value: "",
        },
        {
            label: "ACTIVE",
            value: 1,
        },
        {
            label: "INACTIVE",
            value: 0,
        },
    ]

    useEffect(() => {
        fetchProduct()
        fetchCategories()
        fetchMarketPlaces()
        fetchProductLinks()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const fetchCategories = async () => {
        getProductCategories(token).then((res) => {
            setCategories(res.payload)
        }).catch((err) => {
            toast.error(err.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        })
    }

    const fetchProduct = async () => {
        getProduct(params.id, token).then((res) => {
            setData(res.payload)
            setKode(res.payload.kode_product)
            setName(res.payload.name)
            setPrice(res.payload.price)
            setCategory(res.payload.category_id)
            setKeterangan(res.payload.keterangan)
            setStatus(res.payload.is_active)
            setCode(res.payload.code)
            setDuration(res.payload.duration)
            console.log(res.payload);
        }).catch((err) => {
            toast.error(err, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        })  
    }

    const fetchMarketPlaces = async () => {
        getMarketPlaces(token).then((res) => {
            setMarketPlaces(res.payload)
        }).catch((err) => {
            toast.error(err, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        })  
    }

    const fetchProductLinks = async () => {
        getProductLinks(params.id, token).then((res) => {
            setLinkDatas(res.payload)
            console.log(res.payload)
        }).catch((err) => {
            toast.error(err, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        })  
    }

    const handleResetImage = (id) => {
        if (id === 1) {
            setThumbnail1(null)
            thumbnail1InputRef.current.value = null;
        } else if (id === 2) {
            setThumbnail2(null)
            thumbnail2InputRef.current.value = null;
        } else {
            setThumbnail1(null)
            thumbnail1InputRef.current.value = null;

            setThumbnail2(null)
            thumbnail2InputRef.current.value = null;
        }
    }

    const hanldeUpdate = async (e) => {
        e.preventDefault()

        const reqData = new FormData();
        reqData.append("kode", kode);
        reqData.append("name", name);
        reqData.append("price", price);
        reqData.append("category", category);
        reqData.append("keterangan", keterangan);
        reqData.append("status", status);
        reqData.append("code", code);
        reqData.append("duration", duration);

        if (thumbnail1) {
            reqData.append("thumbnail1", thumbnail1);
        }

        if (thumbnail2) {
            reqData.append("thumbnail2", thumbnail2);
        }

        updateProduct(params.id, reqData, token).then((res) => {
            toast.success(res.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
            fetchProduct()
            handleResetImage(3)
        }).catch((err) => {
            toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        })
    }

    const handleAddLink = async (e) => {
        e.preventDefault()

        const reqData = {
            marketPlace,
            link
        }

        addProductLink(params.id, reqData, token).then((res) => {
            toast.success(res.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
            setLink("")
            setMarketPlace("")
            fetchProductLinks()
        }).catch((err) => {
            toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        })
    }

    const handleDeletProductLink = async (paramsId) => {
        deleteProductLink(paramsId, token).then((res) => {
            toast.success(res.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
            fetchProductLinks()
        }).catch((err) => {
            toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        })
    }

    return (
        <div className='min-h-[calc(100vh-4rem)] flex flex-col'>
            {/* header  */}
            <div className="my-5 mx-7 md:mx-5 flex flex-row justify-between">
                <h1 className='font-ssp font-bold text-4xl md:text-3xl'>Detail Product</h1>
            </div>
            {/* content */}
            <div className="my-5 mx-7 md:mx-5 flex flex-col lg:flex-row justify-evenly gap-7 md:gap-10">
                <div className="w-full h-full lg:w-1/4 md:w-1/2 flex flex-col gap-3 items-center">
                    {data.thumbnail_1 && 
                        <div className='px-3 py-4 w-full h-full bg-white drop-shadow-lg rounded-md flex flex-col gap-2'>
                            <h3 className='text-lg font-ssp font-semibold text-center'>Cover Depan</h3>
                            <img src={`${process.env.REACT_APP_PUBLIC_FOLDER_PRODUCT_COVERS}/${data.thumbnail_1}`} alt={data.name} />
                        </div>
                    }
                    {data.thumbnail_2 && 
                        <div className='px-3 py-4 w-full h-full bg-white drop-shadow-lg rounded-md flex flex-col gap-2'>
                            <h3 className='text-lg font-ssp font-semibold text-center'>Cover Belakang</h3>
                            <img src={`${process.env.REACT_APP_PUBLIC_FOLDER_PRODUCT_COVERS}/${data.thumbnail_2}`} alt={data.name} />
                        </div>
                    }
                </div>
                {/* form update */}
                <div className="px-2 py-4 w-full h-fit lg:w-2/3 md:w-10/12 bg-white drop-shadow-lg rounded-md flex">
                    <form onSubmit={hanldeUpdate} className='px-5 w-full flex flex-wrap justify-between'>
                        {/* kode */}
                        <div className="mt-3 mb-6 w-full md:w-[45%] relative z-0 group">
                            <input 
                                minLength={3} 
                                value={kode} 
                                onChange={(e) => setKode(e.target.value)} 
                                type="text" 
                                name="kode" 
                                id="kode"
                                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                            focus:border-blue-600 peer" 
                                placeholder=" " 
                                required 
                            />
                            <label 
                                htmlFor="kode" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 duration-300 transform -translate-y-6 
                                            scale-75 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:font-medium 
                                            peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Kode</label>
                        </div>
                        {/* name */}
                        <div className="mt-3 mb-6 w-full md:w-[45%] relative z-0 group">
                            <input 
                                minLength={3} 
                                value={name} 
                                onChange={(e) => setName(e.target.value)} 
                                type="text" 
                                name="name" 
                                id="name" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                            focus:border-blue-600 peer" 
                                placeholder=" " 
                                required 
                            />
                            <label 
                                htmlFor="name" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 duration-300 transform -translate-y-6 
                                            scale-75 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:font-medium 
                                            peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Name</label>
                        </div>
                        {/* price */}
                        <div className="mt-3 mb-6 w-full md:w-[45%] relative z-0 group">
                            <input 
                                minLength={3} 
                                value={price} 
                                onChange={(e) => setPrice(e.target.value)} 
                                type="number" 
                                name="price" 
                                id="price" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                            focus:border-blue-600 peer" 
                                placeholder=" " 
                                required 
                            />
                            <label 
                                htmlFor="price" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 duration-300 transform -translate-y-6 
                                            scale-75 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:font-medium 
                                            peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Price</label>
                        </div>
                        {/* keterangan */}
                        <div className="mt-3 mb-6 w-full md:w-[45%] relative z-0 group">
                            <input 
                                minLength={3} 
                                value={keterangan} 
                                onChange={(e) => setKeterangan(e.target.value)} 
                                type="text" 
                                name="keterangan" 
                                id="keterangan" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                            focus:border-blue-600 peer" 
                                placeholder=" " 
                                 
                            />
                            <label 
                                htmlFor="keterangan" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 duration-300 transform -translate-y-6 
                                            scale-75 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:font-medium 
                                            peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Keterangan</label>
                        </div>
                        {/* category */}
                        <div className="mb-6 mt-3 w-full md:w-[45%] relative z-0 group">
                            <select 
                                required value={category} 
                                onChange={(e) => setCategory(e.target.value)} 
                                id="category" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-500 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                            focus:border-blue-600 peer"
                            >
                                {categories.map((option, i) => (
                                    <option key={i} value={option.id}>{option.name}</option>
                                ))}
                            </select>
                            <label 
                                htmlFor="category" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 
                                            duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                            peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                            peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Category</label>
                        </div>
                        {/* status */}
                        <div className="mb-6 mt-3 w-full md:w-[45%] relative z-0 group">
                            <select 
                                required 
                                value={status} 
                                onChange={(e) => setStatus(e.target.value)} 
                                id="status" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-500 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                            focus:border-blue-600 peer"
                            >
                                {optionStatus.map((option, i) => (
                                    <option key={i} value={option.value}>{option.label}</option>
                                ))}
                            </select>
                            <label 
                                htmlFor="status" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 
                                            duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:font-medium 
                                            peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 
                                            peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Status</label>
                        </div>
                        {category === "6" &&
                            <>
                                {/* code */}
                                <div className="mt-3 mb-6 w-full md:w-[45%] relative z-0 group">
                                    <input 
                                        minLength={3} 
                                        value={code} 
                                        onChange={(e) => setCode(e.target.value)} 
                                        type="text" 
                                        name="code" 
                                        id="code" 
                                        className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                                    border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                                    focus:border-blue-600 peer" 
                                        placeholder=" " 
                                        
                                    />
                                    <label 
                                        htmlFor="code" 
                                        className="absolute top-3 -z-10 text-sm text-gray-500 duration-300 transform -translate-y-6 
                                                    scale-75 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:font-medium 
                                                    peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                    >Code VIP</label>
                                </div>
                                {/* duration */}
                                <div className="mt-3 mb-6 w-full md:w-[45%] relative z-0 group">
                                    <input 
                                        minLength={3} 
                                        value={duration} 
                                        onChange={(e) => setDuration(e.target.value)} 
                                        type="text" 
                                        name="duration" 
                                        id="duration" 
                                        className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                                    border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                                    focus:border-blue-600 peer" 
                                        placeholder=" " 
                                        
                                    />
                                    <label 
                                        htmlFor="duration" 
                                        className="absolute top-3 -z-10 text-sm text-gray-500 duration-300 transform -translate-y-6 
                                                    scale-75 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:font-medium 
                                                    peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                    >Duration (Days)</label>
                                    <p className="mt-1 font-ssp text-xs text-gray-500 dark:text-gray-300" id="duration">1 Month = 30 Days, 1 Year = 365 Days</p>
                                </div>
                            </>
                        }
                        {/* thumbnail1 */}
                        <div className="mb-6 mt-3 w-full relative z-0 group">
                            <label className="block mb-2 font-ssp text-xs font-medium text-gray-500" htmlFor="photo1">New Cover Depan</label>
                            <input 
                                type="file" 
                                name="thumbnail1"
                                id="thumbnail1"
                                ref={thumbnail1InputRef}
                                onChange={(e) => setThumbnail1(e.target.files[0])}
                                accept='.png, .jpg, .jpeg'
                                className="block w-full text-sm text-slate-500
                                    file:mr-4 file:py-2 file:px-4
                                    file:rounded-full file:border-0
                                    file:text-sm file:font-semibold
                                    file:bg-gray-50 file:text-gray-500
                                    hover:file:bg-gray-100"
                            />
                            <p className="mt-1 font-ssp text-xs text-gray-500 dark:text-gray-300" id="photo1">PNG, JPG, JPEG (MAX. 5MB).</p>
                        </div>
                        {thumbnail1 && 
                            <div className="mt-3 w-full relative flex flex-row group">
                                <img src={URL.createObjectURL(thumbnail1)} alt="Preview" className="w-1/4" />
                                <IoIosCloseCircle fontSize='25px' className='-mt-3 -ml-3 item-start bg-white rounded-full cursor-pointer' onClick={() => handleResetImage(1)} />
                            </div>
                        }
                        {/* thumbnail2 */}
                        <div className="mb-6 mt-3 w-full relative z-0 group">
                            <label className="block mb-2 font-ssp text-xs font-medium text-gray-500" htmlFor="photo2">New Cover Belakang</label>
                            <input 
                                type="file" 
                                name="thumbnail2"
                                id="thumbnail2"
                                ref={thumbnail2InputRef}
                                onChange={(e) => setThumbnail2(e.target.files[0])}
                                accept='.png, .jpg, .jpeg'
                                className="block w-full text-sm text-slate-500
                                    file:mr-4 file:py-2 file:px-4
                                    file:rounded-full file:border-0
                                    file:text-sm file:font-semibold
                                    file:bg-gray-50 file:text-gray-500
                                    hover:file:bg-gray-100"
                            />
                            <p className="mt-1 font-ssp text-xs text-gray-500 dark:text-gray-300" id="photo2">PNG, JPG, JPEG (MAX. 5MB).</p>
                        </div>
                        {thumbnail2 && 
                            <div className="mt-3 w-full relative flex flex-row group">
                                <img src={URL.createObjectURL(thumbnail2)} alt="Preview" className="w-1/4" />
                                <IoIosCloseCircle fontSize='25px' className='-mt-3 -ml-3 item-start bg-white rounded-full cursor-pointer' onClick={() => handleResetImage(2)} />
                            </div>
                        }
                        <div className="mb-6 w-full relative z-0 flex justify-center gap-5">
                            <button 
                                type="submit" 
                                className="px-5 py-2.5 mt-5 w-full sm:w-auto bg-green-400 hover:bg-green-500 rounded-lg 
                                            font-medium text-sm text-center text-white cursor-pointer "
                            >Update</button>
                            <span 
                                onClick={() => navigate("/products")} 
                                className="px-5 py-2.5 mt-5 w-full sm:w-auto bg-red-400 hover:bg-red-500 rounded-lg 
                                            font-medium text-sm text-center text-white cursor-pointer"
                            >Back</span>
                        </div>
                    </form>
                </div>
            </div>
            {/* market place */}
            <div className="my-5 mx-7 md:mx-5 flex flex-col lg:flex-row justify-evenly gap-7 md:gap-10">
                <div className="w-full h-full md:w-[70%] flex flex-col gap-3 items-center">
                    <div className='px-3 py-4 w-full h-full bg-white drop-shadow-lg rounded-md flex flex-col gap-2'>
                        <h3 className='text-lg font-ssp font-semibold text-center'>List Market Places</h3>
                        <div className='w-full mt-7 mb-3 flex flex-col gap-4'>
                            {linkDatas?.map((link) => (
                                <div key={link.id} className='px-3 py-2 w-full flex flex-row justify-between items-start drop-shadow-lg hover:bg-slate-100 transition-all duration-200 ease-out font-ssp'>
                                    <div className='flex flex-col md:flex-row md:gap-5'>
                                        <span className='font-semibold'>{link.title}</span>
                                        <span className='font-light italic text-xs md:text-base'>{link.link}</span>
                                    </div>
                                    <DeleteIcon onClick={() => handleDeletProductLink(link.id)} className="md:mr-5 text-red-400 hover:text-red-500 cursor-pointer" />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* form update */}
                <div className="px-2 py-4 w-full md:w-[30%] bg-white drop-shadow-lg rounded-md flex">
                    <form onSubmit={handleAddLink} className='px-5 w-full flex flex-wrap justify-between'>
                        {/* market places */}
                        <div className="mb-6 mt-3 w-full relative z-0 group">
                            <select 
                                required 
                                value={marketPlace} 
                                onChange={(e) => setMarketPlace(e.target.value)} 
                                id="status" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-500 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                            focus:border-blue-600 peer"
                            >
                                <option value="">Choose a market places</option>
                                {marketPlaces.map((market) => (
                                    <option key={market.id} value={market.id}>{market.title}</option>
                                ))}
                            </select>
                            <label 
                                htmlFor="status" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 
                                            duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:font-medium 
                                            peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 
                                            peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Market Places</label>
                        </div>
                        {/* link */}
                        <div className="mt-3 mb-6 w-full relative z-0 group">
                            <input 
                                minLength={3} 
                                value={link} 
                                onChange={(e) => setLink(e.target.value)} 
                                type="text" 
                                name="link" 
                                id="link"
                                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                            focus:border-blue-600 peer" 
                                placeholder=" " 
                                required 
                            />
                            <label 
                                htmlFor="link" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 duration-300 transform -translate-y-6 
                                            scale-75 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:font-medium 
                                            peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Link</label>
                        </div>
                        
                        <div className="mb-6 w-full relative z-0 flex justify-center gap-5">
                            <button 
                                type="submit" 
                                className="px-5 py-2.5 mt-5 w-full sm:w-auto bg-green-400 hover:bg-green-500 rounded-lg 
                                            font-medium text-sm text-center text-white cursor-pointer "
                            >Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default DetailProduct