import { format } from 'date-fns';
import { id } from 'date-fns/locale'; // Import Indonesian locale

// Function format rupiah
export const formatRupiah = (angka) => {
    const formatter = new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR',
      minimumFractionDigits: 0,
    });
  
    return formatter.format(angka);
}

// Fungsi untuk format tanggal dan waktu menjadi 20 Agustus 2022 11:11:00
export const dateTimeIndo = (formatDate) => {
  // Ubah format tanggal menjadi "24 Agustus 2023"
  const tanggalFormatted = format(new Date(formatDate), 'dd MMMM yyyy HH:mm:ss', { locale: id }); // Gunakan locale 'id' untuk bahasa Indonesia

  return tanggalFormatted;
}

// function check timestamp
export const checkTimeStamp = (startDate, startTime, endDate, endTime) => {

  const combinedDateTimeString = startDate + 'T' + startTime;
  const timestamp = new Date(combinedDateTimeString).getTime() / 1000; // Dibagi 1000 untuk mendapatkan timestamp dalam detik
  
  const combinedDateTimeString2 = endDate + 'T' + endTime;
  const timestamp2 = new Date(combinedDateTimeString2).getTime() / 1000; // Dibagi 1000 untuk mendapatkan timestamp dalam detik

  if (timestamp < timestamp2) {
      // console.log("start lebih awal");
      return true
  } else {
      // console.log("end lebih awal");
      return false
  }
}