import React, { useContext, useEffect, useRef, useState } from 'react';
import { RiCloseFill } from "react-icons/ri";
import { toast } from 'react-toastify';
import DeleteIcon from '@mui/icons-material/Delete';
import { PopupContext } from "../../context/popupContext";
import { updateStoreAudioDetail, getSpeakersAudio, deleteSpeakerAudio } from '../../utilities/sendRequest';
import { decryptData } from '../../utilities/cryptoStorage';
import ReactAudioPlayer from 'react-audio-player';

const ModalDetailAudio = ({ setShowModalDetail, setFetchMore, data }) => {
    const token = JSON.parse(decryptData(localStorage.getItem('cRdT')));
    const { popupDispatch } = useContext(PopupContext)
    const clickRef = useRef(null)
    const [title, setTitle] = useState(data.name)
    const [fullAudio, setFullAudio] = useState(data.full_audio)
    const [shortAudio, setShortAudio] = useState(data.short_audio)
    const [speakers, setSpeakers] = useState([])
    
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (clickRef.current && !clickRef.current.contains(event.target)) {
                setShowModalDetail(null)
                popupDispatch({ type: "POPUP_END" });
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clickRef])

    useEffect(() => {
        fetchSpeakers()
        // eslint-disable-next-line
    }, [])

    const fetchSpeakers = async () => {
        getSpeakersAudio(data.id, token).then((res) => {
            setSpeakers(
                res.payload.sort((p1, p2) => {
                    return new Date(p2.created_at) - new Date(p1.created_at)
                })
            )
            // console.log(res.payload);
        }).catch((err) => {
            toast.error(err.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        })
    }

    const handleSubmit = async(e) => {
        e.preventDefault()

        const reqData = {
            productId: data.id,
            shortAudio,
            fullAudio
        }

        updateStoreAudioDetail(reqData, token).then((res) => {
            toast.success(res.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
            setFetchMore(true)
            setShowModalDetail(null)
        }).catch((err) => {
            toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        })
        popupDispatch({ type: "POPUP_END" });
    }
    
    const handleModal = () => {
        setShowModalDetail(null)
        popupDispatch({ type: "POPUP_END" });
    }

    const handleDeleteSpeaker = async (paramsId) => {
        deleteSpeakerAudio(paramsId, token).then((res) => {
            toast.success(res.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
            fetchSpeakers()
        }).catch((err) => {
            toast.error(err.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        })
    }

    return (
        <>
            <div  className="absolute top-0 left-0 z-10 p-3 w-screen min-h-screen overscroll-y-none overflow-y-hidden bg-black bg-opacity-70 flex items-center justify-center">
                <div ref={clickRef} className="p-3 w-11/12 lg:w-1/3 md:w-1/2 h-full bg-white rounded-md flex flex-col">
                    <div className="px-2 flex justify-between items-center">
                        <h3 className="font-bold font-ssp text-xl md:text-2xl">Detail Audio</h3>
                        <RiCloseFill onClick={handleModal} className="w-8 h-8 cursor-pointer"/>
                    </div>
                    <form onSubmit={handleSubmit} className="mt-5 px-5 w-full flex flex-col">
                        {/* title */}
                        <div className="relative z-0 mb-3 mt-3 w-full group">
                            <input 
                                minLength={3} 
                                value={title} 
                                onChange={(e) => setTitle(e.target.value)} 
                                type="text" 
                                name="title" 
                                id="title" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                                placeholder=" " 
                                readOnly
                            />
                            <label 
                                htmlFor="title" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Title</label>
                        </div>
                        {/* shortAudio */}
                        {data.short_audio &&
                            <div className="relative z-0 mt-7 w-full group">
                                <ReactAudioPlayer
                                    // src={`http://localhost:3000/assets/musics/Song 1.mp3`}
                                    src={`${process.env.REACT_APP_PUBLIC_FOLDER_PRODUCT_AUDIOS}/${data.short_audio}`}
                                    controls
                                    className="w-60 mx-auto"
                                />
                            </div>
                        }
                        <div className="relative z-0 mb-3 mt-3 w-full group">
                            <input  
                                value={shortAudio} 
                                onChange={(e) => setShortAudio(e.target.value)} 
                                type="text" 
                                name="shortAudio" 
                                id="shortAudio" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 
                                                    appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                                placeholder=" "  
                            />
                            <label 
                                htmlFor="shortAudio" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 dark:text-gray-400 
                                            duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:font-medium
                                            peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 
                                            peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Name Short Audio</label>
                            <p className="mt-1 font-ssp text-xs text-gray-500 dark:text-gray-300 italic" id="shortAudio">Nb. untuk file audio diupload manual sesuai dengan pathnya.</p>
                        </div>
                        {/* fullAudio */} 
                        {data.full_audio &&
                            <div className="relative z-0 mt-7 w-full group items-center">
                                <ReactAudioPlayer
                                    src={`${process.env.REACT_APP_PUBLIC_FOLDER_PRODUCT_AUDIOS}/${data.full_audio}`}
                                    controls
                                    className="w-60 mx-auto"
                                />
                            </div>
                        }
                        <div className="relative z-0 mb-3 mt-3 w-full group">
                            <input  
                                value={fullAudio} 
                                onChange={(e) => setFullAudio(e.target.value)} 
                                type="text" 
                                name="fullAudio" 
                                id="fullAudio" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 
                                                    appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                                placeholder=" "  
                            />
                            <label 
                                htmlFor="fullAudio" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 dark:text-gray-400 
                                            duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:font-medium
                                            peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 
                                            peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Name Full Audio</label>
                            <p className="mt-1 font-ssp text-xs text-gray-500 dark:text-gray-300 italic" id="fullAudio">Nb. untuk file audio diupload manual sesuai dengan pathnya.</p>
                        </div>
                        <div className="relative z-0 mb-3 mt-3 w-full group flex flex-col gap-2">
                            <p className="font-ssp text-sm text-gray-700 dark:text-gray-300" id="fullAudio">Speakers</p>
                            <hr className="mb-2" />
                            {speakers.map((speaker, i) => (
                                <li key={speaker.id} className="px-5 flex flex-row justify-start items-center font-ssp text-xs">
                                    <span className="w-[5%]">{i+1}.</span>
                                    <span className="w-[60%]">{speaker.speaker_name}</span>
                                    <DeleteIcon className="w-[30%] text-red-400 hover:text-red-500 cursor-pointer text-sm" onClick={() => handleDeleteSpeaker(speaker.id)} />
                                </li>
                            ))}
                        </div>
                        {/* button  */}
                        <div className="relative z-0 mb-6 mt-5 w-full flex justify-end gap-3">
                            <button 
                                type="submit" 
                                className="mt-5 px-5 py-2.5 w-full sm:w-auto bg-blue-400 hover:bg-blue-500 rounded-lg text-white font-medium text-sm text-center"
                            >Update</button>
                            <span 
                                onClick={handleModal} 
                                className="mt-5 px-5 py-2.5 w-full sm:w-auto bg-red-400 hover:bg-red-500 rounded-lg font-medium text-sm text-white text-center cursor-pointer"
                            >Close</span>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default ModalDetailAudio