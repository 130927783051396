import { decryptData } from "./cryptoStorage";

export const isAuthenticated = () => {
    // Logika untuk memeriksa apakah pengguna terautentikasi atau tidak
    // Anda dapat memeriksa apakah ada token yang valid di localStorage
    // dan jika ada, berarti pengguna terautentikasi.

    const token = JSON.parse(decryptData(localStorage.getItem('cRdT')));
    return token !== null;
};
  
export const isAuthorized = (allowedRoles, user) => {
    // Logika untuk memeriksa otorisasi pengguna berdasarkan peran (role) yang diizinkan
    // Anda dapat memeriksa peran pengguna dari data yang Anda simpan, misalnya dalam state atau dalam token.
    // Jika peran pengguna ada dalam allowedRoles, berarti pengguna diotorisasi.
    // Jika allowedRoles tidak diberikan, maka artinya tidak ada batasan peran (semua peran diizinkan).
    // Anda bisa mengatur logika otorisasi sesuai dengan kebutuhan aplikasi Anda.
    const userRole = user.division // Misalnya, peran pengguna disimpan dalam state
    if (allowedRoles && allowedRoles.length > 0) {
      return allowedRoles.includes(userRole);
    }
    return true;
};
