// Import CryptoJS
import CryptoJS from 'crypto-js';

const key = process.env.REACT_APP_CRYPTO_KEY

// Fungsi untuk mengenkripsi data
export const encryptData = (data) => {
  const encryptedData = CryptoJS.AES.encrypt(data, key).toString();
  return encryptedData;
}

// Fungsi untuk mendekripsi data
export const decryptData = (encryptedData) => {
  const decryptedData = CryptoJS.AES.decrypt(encryptedData, key).toString(CryptoJS.enc.Utf8);
  return decryptedData;
}
