const PopupReducer = (state, action) => {
    switch(action.type) {
        case "POPUP_START":
            return {
                isPopup: true,
            }
        case "POPUP_END":
            return {
                isPopup: false,
            }
        default:
            return state
    }
}

export default PopupReducer