import React, { useContext, useEffect, useState } from 'react'
import { useParams, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import SyncIcon from '@mui/icons-material/Sync';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { getEnterprisersByRoles, logoutUser, toggleEnterpriser, toggleDevice } from '../../utilities/sendRequest';
import { decryptData } from '../../utilities/cryptoStorage';
import { AuthContext } from '../../context/authContext';

const Enterprisers = () => {
    const token = JSON.parse(decryptData(localStorage.getItem('cRdT')));
    const params = useParams()
    const [title, setTitle] = useState("")
    // const [data, setData] = useState([])
    const { dispatch } = useContext(AuthContext)
    // const { popupDispatch } = useContext(PopupContext)
    const [datas, setDatas] = useState([])
    // const [showModalDetail, setShowModalDetail] = useState(false)
    // const [showModalAdd, setShowModalAdd] = useState(false)
    const [fetchMore, setFetchMore] = useState(true)

    useEffect(() => {
        if (params.slug === 'regular') {
            setTitle("Regular")
        } else if (params.slug === 'vip') {
            setTitle("VIP 3 Bulan")
        } else if (params.slug === 'vip2') {
            setTitle("VIP 6 Bulan")
        } else if (params.slug === 'vip3') {
            setTitle("VIP 1 Tahun")
        } else if (params.slug === 'vip4') {
            setTitle("VIP 3 Hari")
        } else if (params.slug === 'vip5') {
            setTitle("VIP 1 Bulan")
        }  else if (params.slug === 'vvip') {
            setTitle("VVIP")
        } 

        fetchEnterpriser(params.slug)
        setFetchMore(false)
        // eslint-disable-next-line
    }, [params, fetchMore])

    const fetchEnterpriser = async (paramsId) => {
        getEnterprisersByRoles(paramsId, token).then((res) => {
            setDatas(
                res.payload.sort((p1, p2) => {
                    return new Date(p2.created_at) - new Date(p1.created_at)
                })
            )
        }).catch((err) => {
            toast.error(err.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });

            if (err.response.status === 401) {
                setTimeout(() => {
                    logoutUser(dispatch)
                }, 3500)
            }
        })
    }

    const handleToggle = (paramsId) => {
        toggleEnterpriser(paramsId, token).then((res) => {
            toast.success(res.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
            setFetchMore(true)
        }).catch((err) => {
            toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });

            if (err.response.status === 401) {
                setTimeout(() => {
                    logoutUser(dispatch)
                }, 3500)
            }
        })
    }

    const handleResetDevice = (paramsId) => {
        toggleDevice(paramsId, token).then((res) => {
            toast.success(res.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
            setFetchMore(true)
        }).catch((err) => {
            toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });

            if (err.response.status === 401) {
                setTimeout(() => {
                    logoutUser(dispatch)
                }, 3500)
            }
        })
    }

    const columns = [
        { 
            field: 'id_enterpriser', 
            headerName: 'ID Enterpriser',
            width: 150, 
            renderCell: (params) => {
                return (
                    <div className='flex justify-center items-center font-ssp'>
                        {params.row.id_enterpriser}
                    </div>
                )
            } 
        },
        { 
            field: 'name', 
            headerName: 'Fullname',
            width: 300, 
            renderCell: (params) => {
                return (
                    <div className='flex justify-center items-center font-ssp'>
                        {params.row.name}
                    </div>
                )
            } 
        },
        { 
            field: 'roles', 
            headerName: 'Roles',
            width: 150, 
            renderCell: (params) => {
                return (
                    <div className='flex justify-center items-center font-ssp uppercase'>
                        {/* {params.row.product_name ? params.row.product_name : params.row.roles} */}
                        {params.slug === "regular" ? params.row.roles : params.row.product_name}
                    </div>
                )
            } 
        },
        {
            field: 'login_devices',
            headerName: 'Device',
            width: 150,
            renderCell: (params) => {
                return (
                    <div className="font-ssp">
                        {params.row.login_devices
                            ? <span className="px-2 py-1 bg-blue-400 rounded-full text-white uppercase">Logged</span>
                            : <span className="px-2 py-1 bg-slate-400 rounded-full text-white uppercase">Unlogged</span>
                        }
                    </div>
                )
            } 
        },
        {
            field: 'is_active',
            headerName: 'Status',
            width: 150,
            renderCell: (params) => {
                return (
                    <div className="font-ssp">
                        {params.row.is_active === "1"
                            ? <span className="px-2 py-1 bg-green-400 rounded-full text-white uppercase">active</span>
                            : <span className="px-2 py-1 bg-red-400 rounded-full text-white uppercase">inactive</span>
                        }
                    </div>
                )
            } 
        },
        {
            field: 'action',
            headerName: 'Action',
            width: 150,
            renderCell: (params) => {
                return (
                    <div className='w-full flex flex-row items-center gap-3'>
                        <Link to={"/enterprisers/detail/" + params.row.id}>
                            <VisibilityIcon fontSize='medium' className="text-slate-400 hover:text-slate-500 cursor-pointer"/>
                        </Link>
                        {params.row.is_active === "1" 
                            ? <ToggleOnIcon className="text-green-400 hover:text-green-500 cursor-pointer" onClick={() => handleToggle(params.row.id)}/>
                            : <ToggleOffIcon className="text-gray-400 hover:text-gray-500 cursor-pointer" onClick={() => handleToggle(params.row.id)}/>
                        }
                        <SyncIcon className="text-slate-400 hover:text-slate-500 cursor-pointer" onClick={() => handleResetDevice(params.row.id)}/>
                    </div>
                )
            }
        },
    ]

    return (
        
        <div className={`min-h-[calc(100vh-4rem)] flex flex-col`}>
        {/* <div className={`min-h-[calc(100vh-4rem)] flex flex-col ${showModalAdd && 'fixed min-w-[calc(100vw-16.666667%)]'} `}> */}
            {/* header  */}
            <div className="my-5 mb-5 mx-7 md:mx-5 flex flex-col md:flex-row justify-between">
                <h1 className='font-ssp font-bold text-2xl md:text-3xl'>Enterpriser - {title}</h1>
                {/* <div onClick={()=> handleModal()} className="px-5 py-2.5 mr-3 mt-3 md:mt-1 bg-blue-400 hover:bg-blue-500 rounded-lg flex text-sm font-medium self-start md:self-end cursor-pointer">
                    <span className='text-sm text-white font-ssp'>Add New</span>
                </div> */}
            </div>
            {/* content  */}
            <div className="my-5 mb-5 mx-7 md:mx-5 flex">
                <div className="p-2 w-full bg-white drop-shadow-lg rounded-md flex">
                    <DataGrid
                        autoHeight {...datas}
                        rows={datas}
                        columns={columns}
                        pageSize={10}
                        getRowId={(row) => row.id}
                        rowsPerPageOptions={[10]}
                        checkboxSelection
                        disableSelectionOnClick
                        components={{ Toolbar: GridToolbar, }}
                    />
                </div>
            </div>
        </div>
    )
}

export default Enterprisers