import React from 'react';
import { Link } from 'react-router-dom';

const Error403 = () => {
  return (
    <>
        <div className="fixed top-0 left-0 z-30 p-5 w-full min-h-screen bg-grayPrimary flex flex-col lg:justify-center lg:items-center gap-3">
            <div className="lg:w-1/4 flex justify-center items-center">
                <img src="/assets/images/web-maintenance.svg" alt="Not Found" className='md:w-1/2 lg:w-full' />
            </div>
            <div className="flex flex-col items-center justify-center gap-3">
                <span className='font-roboto font-bold text-2xl tracking-widest'>Forbidden</span>
                <span className='font-roboto font-light text-xl text-center tracking-widest'>You do not have access to this page</span>
            </div>
            <div className="mt-10 flex items-center justify-center">
                <Link to={"/"}>
                    <span className="px-4 py-3 bg-blue-500 rounded-xl shadow-lg shadow-blue-500/50 flex text-white text-base tracking-wide font-roboto cursor-pointer">Home</span>
                </Link>
            </div>
        </div>
    </>
  )
}

export default Error403