import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { IoIosCloseCircle } from "react-icons/io";
import { getNational, updateNational } from '../../utilities/sendRequest';
import { decryptData } from '../../utilities/cryptoStorage';

const DetailNational = () => {
    const token = JSON.parse(decryptData(localStorage.getItem('cRdT')));
    const params = useParams()
    const photoRef = useRef(null);
    const navigate = useNavigate()
    const [data, setData] = useState({})
    const [title, setTitle] = useState("")
    const [photo, setPhoto] = useState(null)
    const [status, setStatus] = useState("")
    const [schedule, setSchedule] = useState("")
    const [detailSchedule, setDetailSchedule] = useState("")
    const [location, setLocation] = useState("")
    const [detailLocation, setDetailLocation] = useState("")
    const [information, setInformation] = useState("")
    const [detailInformation, setDetailInformation] = useState("")
    const [link, setLink] = useState("")
    const [checkedValues, setCheckedValues] = useState([]);
    const optionStatus = [
        {
            label: "Choose a Status",
            value: "",
        },
        {
            label: "ACTIVE",
            value: 1,
        },
        {
            label: "INACTIVE",
            value: 0,
        },
    ]

    useEffect(() => {
        fetchNational()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleCheckboxChange = (event) => {
        const { value, checked } = event.target;
    
        if (checked) {
          setCheckedValues(prevValues => [...prevValues, value]);
        } else {
          setCheckedValues(prevValues => prevValues.filter(val => val !== value));
        }
    };

    const fetchNational = async () => {
        getNational(params.uuid, token).then((res) => {
            setData(res.payload)
            setTitle(res.payload.name)
            setSchedule(res.payload.schedule)
            setDetailSchedule(res.payload.detail_schedule)
            setLocation(res.payload.location)
            setDetailLocation(res.payload.detail_location)
            setInformation(res.payload.information)
            setDetailInformation(res.payload.detail_information)
            setLink(res.payload.link)
            setStatus(res.payload.is_active)
            setCheckedValues(res.payload.types)
        }).catch((err) => {
            toast.error(err, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        })  
    }

    const handleResetImage = () => {
        setPhoto(null)
        photoRef.current.value = null;
    }

    const hanldeUpdate = async (e) => {
        e.preventDefault()

        const reqData = new FormData();
        reqData.append("title", title);
        reqData.append('types', JSON.stringify(checkedValues));
        reqData.append("schedule", schedule);
        reqData.append("detailSchedule", detailSchedule);
        reqData.append("location", location);
        reqData.append("detailLocation", detailLocation);
        reqData.append("information", information);
        reqData.append("detailInformation", detailInformation);
        reqData.append("link", link);
        reqData.append("status", status);

        if(photo) {
            reqData.append("photo", photo);
        }

        updateNational(params.uuid, reqData, token).then((res) => {
            toast.success(res.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
            fetchNational()
            handleResetImage()
        }).catch((err) => {
            toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        })
    }

    return (
        <div className='min-h-[calc(100vh-4rem)] flex flex-col'>
            {/* header  */}
            <div className="flex flex-row justify-between my-5 mx-7 md:mx-5">
                <h1 className='font-ssp font-bold text-4xl md:text-3xl'>Detail Nationail Event</h1>
            </div>
            {/* content */}
            <div className="my-5 mx-7 md:mx-5 flex flex-col lg:flex-row justify-evenly gap-10">
                {/* detail national event */}
                <div className="mb-3 px-3 py-4 w-full md:w-1/2 lg:w-1/4 h-full bg-white drop-shadow-lg rounded-md flex flex-col gap-3 items-center">
                    {data.photo && 
                        <div className='w-full h-full'>
                            <img src={`${process.env.REACT_APP_PUBLIC_FOLDER_FLYERS}/${data.photo}`} alt={data.title} />
                        </div>
                    }
                </div>
                {/* form update */}
                <div className="px-2 py-5 w-full md:w-10/12 lg:w-2/3 bg-white drop-shadow-lg rounded-md flex">
                    <form onSubmit={hanldeUpdate} className='px-5 w-full flex flex-wrap justify-between'>
                        {/* title */}
                        <div className="mt-3 mb-6 w-[45%] relative z-0 group">
                            <input 
                                minLength={5} 
                                value={title} 
                                onChange={(e) => setTitle(e.target.value)} 
                                type="text" name="title" id="title" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                            focus:border-blue-600 peer" 
                                placeholder=" " 
                                required 
                            />
                            <label 
                                htmlFor="title" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 
                                            duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                            peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                            peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Name</label>
                        </div>
                        {/* schedule */}
                        <div className="mt-3 mb-6 w-[45%] relative z-0 group">
                            <input 
                                minLength={5} 
                                value={schedule} 
                                onChange={(e) => setSchedule(e.target.value)} 
                                type="text" 
                                name="schedule" 
                                id="schedule" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                            focus:border-blue-600 peer" 
                                placeholder=" " 
                                required 
                            />
                            <label 
                                htmlFor="schedule" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 dark:text-gray-400 
                                            duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                            peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                            peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Event Date</label>
                        </div>
                        {/* detail schedule */}
                        <div className="mt-3 mb-6 w-[45%] relative z-0 group">
                            <input 
                                minLength={5} 
                                value={detailSchedule} 
                                onChange={(e) => setDetailSchedule(e.target.value)} 
                                type="text" 
                                name="detailSchedule" 
                                id="detailSchedule" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                            focus:border-blue-600 peer" 
                                placeholder=" " 
                                required 
                            />
                            <label 
                                htmlFor="detailSchedule" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 dark:text-gray-400 
                                        duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:font-medium 
                                        peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Event Time</label>
                        </div>
                        {/* location */}
                        <div className="mt-3 mb-6 w-[45%] relative z-0 group">
                            <input 
                                minLength={5} 
                                value={location} 
                                onChange={(e) => setLocation(e.target.value)} 
                                type="text" 
                                name="location" 
                                id="location" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                            focus:border-blue-600 peer" 
                                placeholder=" " 
                                required 
                            />
                            <label 
                                htmlFor="location" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 dark:text-gray-400 
                                            duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                            peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                            peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Location</label>
                        </div>
                        {/* detail location */}
                        <div className="mt-3 mb-6 w-[45%] relative z-0 group">
                            <input 
                                minLength={5} 
                                value={detailLocation} 
                                onChange={(e) => setDetailLocation(e.target.value)} 
                                type="text" 
                                name="detailLocation" 
                                id="detailLocation" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                            focus:border-blue-600 peer" 
                                placeholder=" " 
                                required 
                            />
                            <label 
                                htmlFor="detailLocation" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 dark:text-gray-400 
                                            duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                            peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                            peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Detail Location</label>
                        </div>
                        {/* information */}
                        <div className="mt-3 mb-6 w-[45%] relative z-0 group">
                            <input 
                                minLength={5} 
                                value={information} 
                                onChange={(e) => setInformation(e.target.value)} 
                                type="text" 
                                name="information" 
                                id="information" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none 
                                            focus:ring-0 focus:border-blue-600 peer" 
                                placeholder=" " 
                                required 
                            />
                            <label 
                                htmlFor="information" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 dark:text-gray-400 
                                            duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                            peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                            peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Information</label>
                        </div>
                        {/* detail information */}
                        <div className="mt-3 mb-6 w-[45%] relative z-0 group">
                            <input 
                                minLength={5} 
                                value={detailInformation} 
                                onChange={(e) => setDetailInformation(e.target.value)} 
                                type="text" 
                                name="detailInformation" 
                                id="detailInformation" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                            focus:border-blue-600 peer" 
                                placeholder=" " 
                                required 
                            />
                            <label 
                                htmlFor="detailInformation" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 dark:text-gray-400 
                                            duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                            peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                            peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Detail Information</label>
                        </div>
                        {/* link */}
                        <div className="mt-3 mb-6 w-[45%] relative z-0 group">
                            <input 
                                minLength={5} 
                                value={link} 
                                onChange={(e) => setLink(e.target.value)} 
                                type="text" 
                                name="link" 
                                id="link" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                            focus:border-blue-600 peer" 
                                placeholder=" " 
                                required 
                            />
                            <label 
                                htmlFor="link" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 dark:text-gray-400 
                                            duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                            peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                            peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Link Registrasi</label>
                        </div>
                        {/* status */}
                        <div className="mt-3 mb-6 w-[45%] relative z-0 group">
                            <select 
                                required 
                                value={status} 
                                onChange={(e) => setStatus(e.target.value)} 
                                id="status" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-500 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                            focus:border-blue-600 peer"
                            >
                                {optionStatus.map((option) => (
                                    <option key={option.value} value={option.value}>{option.label}</option>
                                ))}
                            </select>
                            <label 
                                htmlFor="status" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 
                                            duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                            peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                            peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Status</label>
                        </div>
                        {/* type */}
                        <div className="mt-4 mb-3 w-[45%] relative z-0 group">
                            <div className='py-3 px-2 w-full flex flex-col md:flex-row'>
                                <div className="mr-4 flex items-center">
                                    <input 
                                        id="inline-checkbox" 
                                        type="checkbox" 
                                        value="ONLINE" 
                                        checked={checkedValues.includes('ONLINE')} 
                                        onChange={handleCheckboxChange} 
                                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 \
                                                    dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 
                                                    dark:border-gray-600" 
                                    />
                                    <label 
                                        htmlFor="inline-checkbox" 
                                        className="ml-2 text-sm font-medium text-gray-500 dark:text-gray-300"
                                    >ONLINE</label>
                                </div>
                                <div className="mr-4 flex items-center">
                                    <input 
                                        id="inline-2-checkbox" 
                                        type="checkbox" 
                                        value="ONSITE" 
                                        checked={checkedValues.includes('ONSITE')} 
                                        onChange={handleCheckboxChange} 
                                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 
                                                    dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 
                                                    dark:border-gray-600" 
                                        />
                                    <label 
                                        htmlFor="inline-2-checkbox" 
                                        className="ml-2 text-sm font-medium text-gray-500 dark:text-gray-300"
                                    >ONSITE</label>
                                </div>
                            </div>
                            <label 
                                htmlFor="type" 
                                className="peer-focus:font-medium absolute text-sm text-gray-500 
                                            duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 
                                            peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                            peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Type</label>
                        </div>
                        {/* photo */}
                        <div className="mt-3 mb-6 w-[45%] relative z-0 group">
                            <label 
                                className="mb-2 block font-ssp text-xs font-medium text-gray-500" 
                                htmlFor="photo"
                            >New Photo</label>
                            <input 
                                type="file" 
                                name="file"
                                id="file"
                                ref={photoRef}
                                onChange={(e) => setPhoto(e.target.files[0])}
                                accept='.png, .jpg, .jpeg'
                                className="block w-full text-sm text-slate-500
                                    file:mr-4 file:py-2 file:px-4
                                    file:rounded-full file:border-0
                                    file:text-sm file:font-semibold
                                    file:bg-gray-50 file:text-gray-500
                                    hover:file:bg-gray-100"
                            />
                            <p className="mt-1 font-ssp text-xs text-gray-500 dark:text-gray-300" id="photo">PNG, JPG, JPEG (MAX. 5MB).</p>
                        </div>
                        {photo && 
                            <div className="mt-3 w-full relative flex flex-row group">
                                <img src={URL.createObjectURL(photo)} alt="Preview" className="w-1/4" />
                                <IoIosCloseCircle  
                                    fontSize='25px' 
                                    className='-mt-3 -ml-3 bg-white rounded-full item-start cursor-pointer' 
                                    onClick={handleResetImage} />
                            </div>
                        }
                        <div className="mb-6 w-full relative z-0 flex gap-5 justify-center">
                            <button 
                                type="submit" 
                                className="mt-5 px-5 py-2.5 w-full sm:w-auto bg-green-400 hover:bg-green-500 rounded-lg 
                                            text-sm text-white text-center font-medium cursor-pointer"
                            >Update</button>
                            <span 
                                onClick={() => navigate('/nationals')} 
                                className="px-5 py-2.5 mt-5 w-full sm:w-auto bg-red-400 hover:bg-red-500 
                                            rounded-lg text-sm text-white text-center font-medium cursor-pointer"
                            >Back</span>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default DetailNational