import axios from 'axios';
import { encryptData } from "./cryptoStorage"
import { decodeToken } from "react-jwt";

//authentication
export const loginUser = async (userCredentials, dispatch) => {
    dispatch({ type: "LOGIN_START"})
    try {
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/authentication/auth.php`, userCredentials)
        const myDecodedToken = decodeToken(response.data.token);
        
        const userData = { 
            uuid: myDecodedToken.uuid,
            name: myDecodedToken.name, 
            division: myDecodedToken.division, 
            exp: myDecodedToken.exp, 
        }
        const encryptedData = encryptData(JSON.stringify(response.data.token));
        localStorage.setItem('cRdT', encryptedData);
        dispatch({ type: "LOGIN_SUCCESS", payload: userData });
        
    } catch (error) {
        dispatch({ type: "LOGIN_FAILURE", payload: error.response.data})
    }
}

export const logoutUser = async (dispatch) => {
    localStorage.clear();
    dispatch({ type: "LOGOUT_SUCCESS" });
} 

export const getDashboardLeaderboard = async (token) => {
    console.log(token);
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/bec/dashboard.php`, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}




// Employee
export const getEmployees = async (token) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/employee/getEmployees.php`, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const toggleEmployee = async (paramsUuid, token) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/employee/toggleEmployee.php?pm=${paramsUuid}`, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const addEmployee = async (reqData, token) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/employee/addEmployee.php`, reqData, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const getEmployee = async (paramsUUID, token) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/employee/getEmployee.php?pm=${paramsUUID}`, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const updateEmployee = async (paramsId, reqData, token) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/employee/updateEmployee.php?pm=${paramsId}`, reqData, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

// Posts
export const getPosts = async (token) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/posts/getPosts.php`, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const togglePost = async (paramsId, token) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/posts/togglePost.php?pt=${paramsId}`, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const addPost = async (reqData, token) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/posts/addPost.php`, reqData, {
        headers:{
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const getPost = async (paramsId, token) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/posts/getPost.php?pt=${paramsId}`, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const updatePost = async (paramsId, reqData, token) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/posts/updatePost.php?pt=${paramsId}`, reqData, {
        headers:{
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const deletePost = async (paramsId, token) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/posts/deletePost.php?pt=${paramsId}`, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

// Speakers
export const getSpeakers = async (token) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/events/getSpeakers.php`, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const toggleSpeaker = async (paramsId, token) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/events/toggleSpeaker.php?sp=${paramsId}`, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const addSpeaker = async (reqData, token) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/events/addSpeaker.php`, reqData, {
        headers:{
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const getSpeaker = async (paramsId, token) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/events/getSpeaker.php?sp=${paramsId}`, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const updateSpeaker = async (paramsId, reqData, token) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/events/updateSpeaker.php?sp=${paramsId}`, reqData, {
        headers:{
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

// National Events
export const addNationalEvent = async (reqData, token) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/events/addNational.php`, reqData, {
        headers:{
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const getNationals = async (token) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/events/getNationals.php`, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const getNational = async (paramsId, token) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/events/getNational.php?nt=${paramsId}`, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const toggleNational = async (paramsId, token) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/events/toggleNational.php?nt=${paramsId}`, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const updateNational = async (paramsId, reqData, token) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/events/updateNational.php?nt=${paramsId}`, reqData, {
        headers:{
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

// Daily Events
export const addDailyEvent = async (reqData, token) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/events/addDaily.php`, reqData, {
        headers:{
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const getDailys = async (token) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/events/getDailys.php`, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const getDaily = async (paramsId, token) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/events/getDaily.php?dl=${paramsId}`, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const toggleDaily = async (paramsId, token) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/events/toggleDaily.php?dl=${paramsId}`, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const updateDaily = async (paramsId, reqData, token) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/events/updateDaily.php?dl=${paramsId}`, reqData, {
        headers:{
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const deleteEvent = async (parmasUUID, token) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/events/deleteEvent.php?uuid=${parmasUUID}`, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

// Product Categories
export const addProductCategory = async (reqData, token) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/products/addCategory.php`, reqData, {
        headers:{
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const getProductCategories = async (token) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/products/getCategories.php`, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const getProductCategory = async (paramsId, token) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/products/getCategory.php?pc=${paramsId}`, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const toggleProductCategory = async (paramsId, token) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/products/toggleCategory.php?pc=${paramsId}`, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const updateProductCategory = async (paramsId, reqData, token) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/products/updateCategory.php?pc=${paramsId}`, reqData, {
        headers:{
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

// Products
export const getProducts = async (token) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/products/getProducts.php`, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const toggleProduct = async (paramsId, token) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/products/toggleProduct.php?pd=${paramsId}`, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const addProduct = async (reqData, token) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/products/addProduct.php`, reqData, {
        headers:{
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const getProduct = async (paramsId, token) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/products/getProduct.php?pd=${paramsId}`, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const updateProduct = async (paramsId, reqData, token) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/products/updateProduct.php?pd=${paramsId}`, reqData, {
        headers:{
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const getProductLinks = async (paramsId, token) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/products/getProductLinks.php?pd=${paramsId}`, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const addProductLink = async (paramsId, reqData, token) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/products/addProductLink.php?pd=${paramsId}`, reqData, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const deleteProductLink = async (paramsId, token) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/products/deleteProductLink.php?mpp=${paramsId}`, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

// HDI Products
export const addHdiProduct = async (reqData, token) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/hdi-products/addHdiProduct.php`, reqData, {
        headers:{
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const getHdiProducts = async (token) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/hdi-products/getHdiProducts.php`, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const getHdiProduct = async (paramsId, token) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/hdi-products/getHdiProduct.php?pd=${paramsId}`, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const toggleHdiProduct = async (paramsId, token) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/hdi-products/toggleHdiProduct.php?pd=${paramsId}`, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const updateHdiProduct = async (paramsId, reqData, token) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/hdi-products/updateHdiProduct.php?pd=${paramsId}`, reqData, {
        headers:{
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

// Testimonial Categories
export const getTestimonialCategories = async (token) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/testimonials/getCategories.php`, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const toggleTestimonialCategory = async (paramsId, token) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/testimonials/toggleCategory.php?tc=${paramsId}`, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const addTestimonialCategory = async (reqData, token) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/testimonials/addCategory.php`, reqData, {
        headers:{
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const getTestimonialCategory = async (paramsId, token) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/testimonials/getCategory.php?tc=${paramsId}`, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const updateTestimonialCategory = async (paramsId, reqData, token) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/testimonials/updateCategory.php?tc=${paramsId}`, reqData, {
        headers:{
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

// Testimonial
export const getTestimonials = async (token) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/testimonials/getTestimonials.php`, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const toggleTestimonial = async (paramsId, token) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/testimonials/toggleTestimonial.php?tm=${paramsId}`, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const addTestimonial = async (reqData, token) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/testimonials/addTestimonial.php`, reqData, {
        headers:{
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const getTestimonial = async (paramsId, token) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/testimonials/getTestimonial.php?tm=${paramsId}`, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const updateTestimonial = async (paramsId, reqData, token) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/testimonials/updateTestimonial.php?tm=${paramsId}`, reqData, {
        headers:{
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

// Disease
export const getDiseases = async (token) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/diseases/getDiseases.php`, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const toggleDisease = async (paramsId, token) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/diseases/toggleDisease.php?ds=${paramsId}`, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const addDisease = async (reqData, token) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/diseases/addDisease.php`, reqData, {
        headers:{
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const getDisease = async (paramsId, token) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/diseases/getDisease.php?ds=${paramsId}`, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const updateDisease = async (paramsId, reqData, token) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/diseases/updateDisease.php?ds=${paramsId}`, reqData, {
        headers:{
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

// Benefits
export const getBenefits = async (token) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/benefits/getBenefits.php`, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const toggleBenefit = async (paramsId, token) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/benefits/toggleBenefit.php?bf=${paramsId}`, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const addBenefit = async (reqData, token) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/benefits/addBenefit.php`, reqData, {
        headers:{
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const getBenefit = async (paramsId, token) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/benefits/getBenefit.php?bf=${paramsId}`, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const updateBenefit = async (paramsId, reqData, token) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/benefits/updateBenefit.php?bf=${paramsId}`, reqData, {
        headers:{
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

// Content Categories
export const getContentCategories = async (token) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/billionaires-contents/getCategories.php`, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const toggleContentCategory = async (paramsId, token) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/billionaires-contents/toggleCategory.php?cc=${paramsId}`, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const addContentCategory = async (reqData, token) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/billionaires-contents/addCategory.php`, reqData, {
        headers:{
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const getContentCategory = async (paramsId, token) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/billionaires-contents/getCategory.php?cc=${paramsId}`, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const updateContentCategory = async (paramsId, reqData, token) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/billionaires-contents/updateCategory.php?cc=${paramsId}`, reqData, {
        headers:{
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

// Content
export const getContents = async (token) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/billionaires-contents/getContents.php`, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const toggleContent = async (paramsId, token) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/billionaires-contents/toggleContent.php?ct=${paramsId}`, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const addContent = async (reqData, token) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/billionaires-contents/addContent.php`, reqData, {
        headers:{
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const getContent = async (paramsId, token) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/billionaires-contents/getContent.php?ct=${paramsId}`, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const updateContent = async (paramsId, reqData, token) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/billionaires-contents/updateContent.php?ct=${paramsId}`, reqData, {
        headers:{
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

// Success Profile Categories
export const getProfileCategories = async (token) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/success-profiles/getCategories.php`, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const toggleProfileCategory = async (paramsId, token) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/success-profiles/toggleCategory.php?pc=${paramsId}`, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const addProfileCategory = async (reqData, token) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/success-profiles/addCategory.php`, reqData, {
        headers:{
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const getProfileCategory = async (paramsId, token) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/success-profiles/getCategory.php?pc=${paramsId}`, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const updateProfileCategory = async (paramsId, reqData, token) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/success-profiles/updateCategory.php?pc=${paramsId}`, reqData, {
        headers:{
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

// Success Profile
export const getSuccessProfiles = async (token) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/success-profiles/getProfiles.php`, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const toggleSuccessProfile = async (paramsId, token) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/success-profiles/toggleProfile.php?pi=${paramsId}`, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const addSuccessProfile = async (reqData, token) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/success-profiles/addProfile.php`, reqData, {
        headers:{
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const getSuccessProfile = async (paramsId, token) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/success-profiles/getProfile.php?pi=${paramsId}`, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const updateSuccessProfile = async (paramsId, reqData, token) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/success-profiles/updateProfile.php?pi=${paramsId}`, reqData, {
        headers:{
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const getProfileGalleries = async (paramsId, token) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/success-profiles/getGalleries.php?pi=${paramsId}`, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const addGalleryProfile = async (reqData, token) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/success-profiles/uploadGallery.php`, reqData, {
        headers:{
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const deleteGalleryProfile = async (paramsId, token) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/success-profiles/deleteGallery.php?pi=${paramsId}`, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

// Market Places
export const addMarketPlace = async (reqData, token) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/market-places/addMarketPlace.php`, reqData, {
        headers:{
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const getMarketPlaces = async (token) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/market-places/getMarketPlaces.php`, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const getMarketPlace = async (paramsId, token) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/market-places/getMarketPlace.php?mp=${paramsId}`, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const toggleMarketPlace = async (paramsId, token) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/market-places/toggleMarketPlace.php?mp=${paramsId}`, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const updateMarketPlace = async (paramsId, reqData, token) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/market-places/updateMarketPlace.php?mp=${paramsId}`, reqData, {
        headers:{
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

// audio player
export const getAudioContents = async (paramsId, token) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/products/getAudioContents.php?ct=${paramsId}`, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const addSpeakerAudio = async (reqData, token) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/products/addSpeakerAudio.php`, reqData, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const updateStoreAudioDetail = async (reqData, token) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/products/updateAudioDetail.php`, reqData, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const getSpeakersAudio = async (paramsId, token) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/products/getSpeakersAudio.php?pd=${paramsId}`, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const deleteSpeakerAudio = async (paramsId, token) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/products/deleteSpeakerAudio.php?as=${paramsId}`, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const getPlaylistAudio = async (paramsId, token) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/products/getPlaylistAudio.php?et=${paramsId}`, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const addPlaylistEnterpriser = async (reqData, token) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/products/addPlaylistEnterpriser.php`, reqData, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const deletePlaylistEnterpriser = async (paramsId, token) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/products/deletePlaylistAudio.php?pa=${paramsId}`, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

// enterprisers
export const getEnterprisersByRoles = async (paramsId, token) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/enterprisers/getEnterprisersByRoles.php?rs=${paramsId}`, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const getDetailEntrepriser = async (paramsId, token) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/enterprisers/getEnterpriser.php?ent=${paramsId}`, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const getRecordVIP = async (paramsId, token) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/enterprisers/record_vip.php?ent=${paramsId}`, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}


export const getVipProduct = async (token) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/enterprisers/getVipProducts.php`, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const toggleEnterpriser = async (paramsId, token) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/enterprisers/toggleEnterpriser.php?ent=${paramsId}`, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const toggleDevice = async (paramsId, token) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/enterprisers/toggleEnterpriserDevice.php?ent=${paramsId}`, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const updateEnterpriser = async (paramsId, reqData, token) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/enterprisers/updateEnterpriser.php?ent=${paramsId}`, reqData, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const updateDurationVip = async (paramsId, reqData, token) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/enterprisers/updateDurationVip.php?ent=${paramsId}`, reqData, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const updateChangeDurationVip = async (paramsId, reqData, token) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/enterprisers/changeDuration.php?ent=${paramsId}`, reqData, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

// Transaction
export const getUnpaidTransactions = async (token) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/transactions/getUnpaidTransactions.php`, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const getTransaction = async (paramsId, token) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/transactions/getTransaction.php?tr=${paramsId}`, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const updateTransaction = async (paramsId, reqData, token) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/transactions/updateTransaction.php?tr=${paramsId}`, reqData, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const reportTransactions = async (reqData, token) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/transactions/reportTransaction.php`, reqData, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

// carousel
export const getCarousels = async (token) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/carousels/getCarousels.php`, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const toggleCarousel = async (paramsId, token) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/carousels/toggleCarousel.php?cs=${paramsId}`, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const addCarousel = async (reqData, token) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/carousels/addCarousel.php`, reqData, {
        headers:{
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const getCarousel = async (paramsId, token) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/carousels/getCarousel.php?cs=${paramsId}`, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const updateCarousel = async (paramsId, reqData, token) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/carousels/updateCarousel.php?cs=${paramsId}`, reqData, {
        headers:{
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const deleteCarousel = async (paramsId, token) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/carousels/deleteCarousel.php?cs=${paramsId}`, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

// broadcast
export const getBroadcasts = async (token) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/broadcasts/getBroadcasts.php`, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const addBroadcast = async (reqData, token) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/broadcasts/addBroadcast.php`, reqData, {
        headers:{
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const getBroadcast = async (paramsId, token) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/broadcasts/getBroadcast.php?bc=${paramsId}`, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const updateBroadcast = async (paramsId, reqData, token) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/broadcasts/updateBroadcast.php?bc=${paramsId}`, reqData, {
        headers:{
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const deleteBroadcast = async (paramsId, token) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/broadcasts/deleteBroadcast.php?bc=${paramsId}`, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}





















// profile user
export const getProfile = async (userLogin, token) => {
    const {data} = await axios.get(`${process.env.REACT_APP_BASE_URL}/authentication/userLogin.php?me=${userLogin}`, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const updateProfile = async (paramsUUID, reqData, token) => {
    const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/authentication/userUpdate.php?me=${paramsUUID}`, reqData, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}

export const getMe = async (userLogin, dispatch) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/authentication/userLogin.php?id=${userLogin}`);
        dispatch({ type: "GET_ME", payload: response.data.payload });
    } catch (error) {
        dispatch({ type: "GET_ME_FAILURE", payload: error.response.data})
    }
}

export const searchEnterprisers = async (keyword, token) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/enterprisers/searchEnterprisers.php?key=${keyword}`, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    return data;
}