import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import CampaignIcon from '@mui/icons-material/Campaign';
import { PopupContext } from "../../context/popupContext";
import { AuthContext } from '../../context/authContext';
import { logoutUser, getAudioContents, toggleProduct } from '../../utilities/sendRequest';
import { decryptData } from '../../utilities/cryptoStorage';
import ModalDetailAudio from './modalDetail';
import ModalAddSpeaker from './modalAdd';
import SearchFormAudio from './search';
import AddPlaylist from './addPlaylist';

const AudioContents = () => {
    const token = JSON.parse(decryptData(localStorage.getItem('cRdT')));
    const params = useParams()
    const [title, setTitle] = useState("")
    const [data, setData] = useState([])
    const { dispatch } = useContext(AuthContext)
    const { popupDispatch } = useContext(PopupContext)
    const [datas, setDatas] = useState([])
    const [showModalDetail, setShowModalDetail] = useState(false)
    const [showModalAdd, setShowModalAdd] = useState(false)
    const [fetchMore, setFetchMore] = useState(true)

    useEffect(() => {
        if (params.slug === 'players') {
            setTitle("Audio Player")
            // id product
            fetchAudios(3)
        } else if (params.slug === 'books') {
            setTitle("Audio Books")
            fetchAudios(9)
        } else if (params.slug === 'testimonials') {
            setTitle("Audio Testimonials")
            fetchAudios(8)
        } else if (params.slug === 'studies') {
            setTitle("Book Studies")
            fetchAudios(10)
        } else if (params.slug === 'free') {
            setTitle("Audio Free")
            fetchAudios(11)
        }
        setFetchMore(false)
        // eslint-disable-next-line
    }, [params, fetchMore])

    const fetchAudios = async (paramsId) => {
        getAudioContents(paramsId, token).then((res) => {
            setDatas(
                res.payload.sort((a, b) => a.name.localeCompare(b.name))
                //     res.payload.sort((p1, p2) => {
                //         return new Date(p2.created_at) - new Date(p1.created_at)
                //     })
            )
        }).catch((err) => {
            toast.error(err.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });

            if (err.response.status === 401) {
                setTimeout(() => {
                    logoutUser(dispatch)
                }, 3500)
            }
        })
    }

    const handleToggle = (productId) => {
        toggleProduct(productId, token).then((res) => {
            toast.success(res.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
            setFetchMore(true)
        }).catch((err) => {
            toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });

            if (err.response.status === 401) {
                setTimeout(() => {
                    logoutUser(dispatch)
                }, 3500)
            }
        })
    }

    const handleModalDetail = (tempData) => {
        setShowModalDetail(true)
        setData(tempData)
        popupDispatch({ type: "POPUP_START" });
    }

    const handleModalAdd = (tempData) => {
        setShowModalAdd(true)
        setData(tempData)
        popupDispatch({ type: "POPUP_START" });
    }

    const columns = [
        { 
            field: 'photo', 
            headerName: 'Cover',
            width: 70, 
            renderCell: (params) => {
                return (
                    <div className='flex justify-center items-center font-ssp'>
                        {params.row.thumbnail_1 ? (
                            <div className='w-8 h-8 object-cover object-top bg-slate-400'>
                                <img className='w-8 h-8 object-cover object-top' src={`${process.env.REACT_APP_PUBLIC_FOLDER_PRODUCT_COVERS}/${params.row.thumbnail_1}`} alt={params.row.name} />
                            </div>
                        ) : (
                            <div className='w-8 h-8 object-cover object-top bg-slate-400'></div>
                        )}        
                    </div>
                )
            } 
        },
        { 
            field: 'name', 
            headerName: 'Title',
            width: 350, 
            renderCell: (params) => {
                return (
                    <div className='flex justify-center items-center font-ssp'>
                        {params.row.name}
                    </div>
                )
            } 
        },
        { 
            field: 'full_audio', 
            headerName: 'Full Audio',
            width: 300, 
            renderCell: (params) => {
                return (
                    <div className='flex justify-center items-center font-ssp truncate'>
                        {params.row.full_audio ? params.row.full_audio : <span className="text-red-500 italic">Belum ada audio</span>}
                    </div>
                )
            } 
        },
        {
            field: 'is_active',
            headerName: 'Status',
            width: 150,
            renderCell: (params) => {
                return (
                    <div className="font-ssp">
                        {params.row.is_active === "1"
                            ? <span className="px-2 py-1 bg-green-400 rounded-full text-white uppercase">active</span>
                            : <span className="px-2 py-1 bg-red-400 rounded-full text-white uppercase">inactive</span>
                        }
                    </div>
                )
            } 
        },
        {
            field: 'action',
            headerName: 'Action',
            width: 200,
            renderCell: (params) => {
                return (
                    <div className='w-full flex flex-row items-center gap-3'>
                        <VisibilityIcon onClick={() => handleModalDetail(params.row)} fontSize='medium' className="text-slate-400 hover:text-slate-500 cursor-pointer"/>
                        {params.row.is_active === "1" 
                            ? <ToggleOnIcon className="text-green-400 hover:text-green-500 cursor-pointer" onClick={() => handleToggle(params.row.id)}/>
                            : <ToggleOffIcon className="text-gray-400 hover:text-gray-500 cursor-pointer" onClick={() => handleToggle(params.row.id)}/>
                        }
                        <CampaignIcon onClick={() => handleModalAdd(params.row)} fontSize='medium' className="text-slate-400 hover:text-slate-500 cursor-pointer"/>                  
                        {/* <EditIcon fontSize='medium' className="text-slate-400 hover:text-slate-500 cursor-pointer"/>                   */}
                    </div>
                )
            }
        },
    ]

    return (
        <>
            {showModalDetail && 
                <ModalDetailAudio setShowModalDetail={setShowModalDetail} setFetchMore={setFetchMore} data={data} />
            }
            {showModalAdd && 
                <ModalAddSpeaker setShowModalAdd={setShowModalAdd} setFetchMore={setFetchMore} data={data} />
            }
            <div className={`min-h-[calc(100vh-4rem)] flex flex-col ${(showModalDetail || showModalAdd) && 'fixed min-w-[calc(100vw-16.666667%)]'} `}>
                {/* header  */}
                <div className="my-5 mb-5 mx-7 md:mx-5 flex flex-col md:flex-row justify-between">
                    <h1 className='font-ssp font-bold text-2xl md:text-3xl'>{title}</h1>
                </div>
                {/* content  */}
                
                {params.slug === 'players' &&
                    <div className="my-5 mx-7 md:mx-5 flex flex-col lg:flex-row justify-center gap-10">
                        <SearchFormAudio />
                        <AddPlaylist datas={datas} />
                    </div>
                }

                <div className="my-5 mb-5 mx-7 md:mx-5 flex">
                    <div className="p-2 w-full bg-white drop-shadow-lg rounded-md flex">
                        <DataGrid
                            autoHeight {...datas}
                            rows={datas}
                            columns={columns}
                            pageSize={10}
                            getRowId={(row) => row.id}
                            rowsPerPageOptions={[10]}
                            checkboxSelection
                            disableSelectionOnClick
                            components={{ Toolbar: GridToolbar, }}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default AudioContents