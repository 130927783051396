import React, { useEffect, useRef, useState } from 'react';
import { Sidebar } from '../components';
import { Routes, Route } from 'react-router-dom';
import ContainerPanel from './container';

const Dashboard = () => {
    const [sidebarToggle, setSidebarToggle] = useState(false)
    const [animateSidebar, setAnimateSidebar] = useState("animate__slideInLeft")
    const scrollRef = useRef(null)

    useEffect(() => {
        scrollRef.current.scrollTo(0, 0)
    }, [])

    const handleSidebar = () => {
        if(sidebarToggle) {
            setAnimateSidebar("animate__slideOutLeft animate__fast")
            setTimeout(() => {
                setSidebarToggle(false)
            }, 500)
        } else {
            setAnimateSidebar("animate__slideInLeft")
            setSidebarToggle(true)
        }
    }

    return (
        <div className='w-full h-fit min-h-screen flex md:flex-row transition duration-75 ease-out'>
            {/* dekstop */}
            <div className="hidden w-1/6 md:flex flex-none">
                <Sidebar closeToggle={setSidebarToggle} />
            </div> 
            {/* mobile */}
            {sidebarToggle && 
                <div className='fixed top-0 z-20 w-screen h-screen bg-black bg-opacity-40'>
                    <div className={`fixed z-20 w-3/5 h-screen flex bg-darkPrimary overflow-y-auto shadow-md animate__animated ${animateSidebar}`}>
                        <div className="relative w-full flex">
                            <Sidebar handleSidebar={handleSidebar}/>
                        </div>
                    </div>
                    <div onClick={handleSidebar} className='fixed z-10 right-0 w-3/5 h-screen'>
                    </div>
                </div>
            }
            <div className="pb-2 w-full min-h-screen h-fit flex-1 overflow-y-scrol" ref={scrollRef}>
                <Routes>
                    <Route path='/*' element={<ContainerPanel handleSidebar={handleSidebar} />} />
                </Routes>
            </div>
        </div>
    )
}

export default Dashboard