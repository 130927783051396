import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from "../../context/authContext";
import { updateProfile, logoutUser, getProfile } from '../../utilities/sendRequest';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { decryptData } from '../../utilities/cryptoStorage';

const Profile = () => {
    const token = JSON.parse(decryptData(localStorage.getItem('cRdT')));
    const { user, dispatch } = useContext(AuthContext)
    const [fullname, setFullname] = useState("")
    const [division, setDivision] = useState("")
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const navigate = useNavigate()

    useEffect(() => {
        fetchProfile()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const fetchProfile = async () => {
        getProfile(user.uuid, token).then((res) => {
            setFullname(res.payload.name)
            setUsername(res.payload.username)
            setDivision(res.payload.division)
        }).catch((error) => {
            toast.error("Something Wrong", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });

            if (error.response.status === 401) {
                setTimeout(() => {
                    logoutUser(dispatch)
                }, 3500)
            }
        })
    }

    const handleLogOut = () => {
        logoutUser(dispatch)
    }

    const handleUpdate = async (e) => {
        e.preventDefault()

        if(newPassword !== confirmPassword) {
            return toast.error("New password not match", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        }

        const reqData = {
            fullname,
            username,
            newPassword,
            password
        }

        updateProfile(user.uuid, reqData, token).then((res) => {
            if (res.status === 200 || res.status === 201) {
                toast.success(res.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000
                });
                fetchProfile()
                setTimeout(() => {
                    handleLogOut()
                }, 3000)
            } else {
                return toast.error(res.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000
                });
            }
        }).catch((err) => {
            return toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        })

        setPassword("")
        setNewPassword("")
        setConfirmPassword("")
    }

    return (
        <div className='min-h-[calc(100vh-4rem)] flex flex-col'>
            {/* header */}
            <div className="my-5 mx-7 md:mx-5 flex flex-row justify-between">
                <h1 className='font-ssp font-bold text-4xl md:text-3xl'>Detail Profile</h1>
            </div>
            {/* content */}
            <div className="my-5 mx-7 md:mx-5 flex flex-col lg:flex-row gap-10">
                {/* form update */}
                <div className="px-2 py-4 w-full lg:w-1/3 md:w-10/12 flex bg-white drop-shadow-lg rounded-md">
                    <form onSubmit={handleUpdate} className='px-5 w-full flex flex-col'>
                        {/* fullname */}
                        <div className="mt-3 mb-6 w-full relative z-0 group">
                            <input 
                                minLength={3} 
                                value={fullname} 
                                onChange={(e) => setFullname(e.target.value)} 
                                type="text" 
                                name="fullname" 
                                id="fullname" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                            focus:border-blue-600 peer" 
                                placeholder=" " 
                                required 
                            />
                            <label 
                                htmlFor="fullname" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 
                                            duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                            peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                            peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Fullname</label>
                        </div>
                        {/* username */}
                        <div className="mb-6 w-full relative z-0 group">
                            <input 
                                minLength={3} 
                                value={username} 
                                onChange={(e) => setUsername(e.target.value)} 
                                type="text" 
                                name="username" 
                                id="username" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 
                                            appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                                placeholder=" "
                                required 
                            />
                            <label 
                                htmlFor="username" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 
                                            duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                            peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                            peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Username</label>
                        </div>
                        {/* division */}
                        <div className="mb-6 w-full relative z-0 group">
                            <input 
                                minLength={3} 
                                value={division} 
                                onChange={(e) => setUsername(e.target.value)} 
                                type="text" 
                                name="division" 
                                id="division" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 
                                            appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                                placeholder=" "
                                readOnly 
                            />
                            <label 
                                htmlFor="division" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 
                                            duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                            peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                            peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Division</label>
                        </div>
                        {/* password old */}
                        <div className="mb-6 w-full relative z-0 group">
                            <input 
                                minLength={6} 
                                value={password} 
                                required 
                                onChange={(e) => setPassword(e.target.value)} 
                                type="password" 
                                name="floating_password" 
                                id="floating_password" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                            focus:border-blue-600 peer" 
                                placeholder=" " 
                            />
                            <label 
                                htmlFor="floating_password" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 
                                            duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                            peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                            peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Password</label>
                        </div>
                        {/* password baru */}
                        <div className="mb-6 w-full relative z-0 group">
                            <input 
                                minLength={6} 
                                value={newPassword} 
                                onChange={(e) => setNewPassword(e.target.value)} 
                                type="password" 
                                name="new_password" 
                                id="new_password" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                            focus:border-blue-600 peer" 
                                placeholder=" " 
                            />
                            <label 
                                htmlFor="new_password" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 
                                            duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                            peer-focus:text-blue-600 peer-placeholder-shown:scale-100 
                                            peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >New Password</label>
                        </div>
                        {/* confirm password baru */}
                        <div className="mb-6 w-full relative z-0 group">
                            <input 
                                minLength={6} 
                                value={confirmPassword} 
                                onChange={(e) => setConfirmPassword(e.target.value)} 
                                type="password" 
                                name="repeat_password" 
                                id="floating_repeat_password" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 
                                            focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                                placeholder=" " 
                            />
                            <label 
                                htmlFor="floating_repeat_password" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 
                                            duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                            peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
                                            peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Confirm New Password</label>
                        </div>
                        <div className="mb-6 w-full relative z-0 flex justify-center gap-5">
                            <button 
                                type="submit" 
                                className="px-5 py-2.5 mt-5 w-full sm:w-auto bg-green-400 hover:bg-green-500 rounded-lg 
                                            font-medium text-sm text-white text-center cursor-pointer"
                            >Submit</button>
                            <span 
                                onClick={() => navigate(-1)} 
                                className="px-5 py-2.5 mt-5 w-full sm:w-auto bg-red-400 hover:bg-red-500 rounded-lg
                                            font-medium text-sm text-white text-center cursor-pointer"
                            >Back</span>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Profile