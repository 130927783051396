import React, { useEffect, useState } from 'react'
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { dateTimeIndo, formatRupiah } from '../../utilities/helper';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { toast } from 'react-toastify';
import { decryptData } from '../../utilities/cryptoStorage';
import { reportTransactions } from '../../utilities/sendRequest';
import DetailTransactionReport from './detailTransaction';

const TransactionReport = () => {
    const token = JSON.parse(decryptData(localStorage.getItem('cRdT')));
    const [datas, setDatas] = useState([])
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")
    const [showDetail, setShowDetail] = useState(false)
    const [detailTransaction, setDetailTransaction] = useState("")

    useEffect(() => {
        fetchMore()
        // eslint-disable-next-line
    }, [showDetail])

    const fetchMore = async () => {
        const reqData = {
            startDate,
            endDate
        }
        if (startDate && endDate) {
            reportTransactions(reqData, token).then((res) => {
                setDatas(
                    res.payload.sort((p1, p2) => {
                        return new Date(p2.created_at) - new Date(p1.created_at)
                    })
                )
            }).catch((err) => {
                toast.error(err.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000
                });
            })
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        const reqData = {
            startDate,
            endDate
        }

        reportTransactions(reqData, token).then((res) => {
            setDatas(
                res.payload.sort((p1, p2) => {
                    return new Date(p2.created_at) - new Date(p1.created_at)
                })
            )
        }).catch((err) => {
            toast.error(err.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        })  
    }

    const handleReset = () => {
        setDatas([])
        setStartDate("")
        setEndDate("")
    }

    const handleDetail = (id) => {
        setDetailTransaction(id)
        setShowDetail(true)
    }

    const columns = [
        { 
            field: 'created_at', 
            headerName: 'Time', 
            width: 200, 
            renderCell: (params) => {
                return (
                    <div className="font-ssp">
                        {
                            dateTimeIndo(params.row.created_at)
                        }
                    </div>
                )
            } 
        },
        { 
            field: 'id_enterpriser', 
            headerName: 'ID Enterpriser',
            width: 150, 
            renderCell: (params) => {
                return (
                    <div className='flex justify-center items-center font-ssp'>
                        {params.row.id_enterpriser}
                    </div>
                )
            } 
        },
        { 
            field: 'name', 
            headerName: 'Enterpriser',
            width: 300, 
            renderCell: (params) => {
                return (
                    <div className='flex justify-center items-center font-ssp'>
                        {params.row.name_enterprisers}
                    </div>
                )
            } 
        },
        { 
            field: 'total_amount', 
            headerName: 'Total Transaksi',
            width: 200, 
            renderCell: (params) => {
                return (
                    <div className='flex justify-center items-center font-ssp'>
                        {formatRupiah(params.row.total_amount)}
                    </div>
                )
            } 
        },
        {
            field: 'status_transaction',
            headerName: 'Status',
            width: 150,
            renderCell: (params) => {
                return (
                    <div className="font-ssp">
                        {params.row.status_transaction === 'unpaid' ?
                                (<span className="px-2 py-1 bg-yellow-400 rounded-full text-white uppercase">{params.row.status_transaction}</span>)
                            : params.row.status_transaction === 'success' ?
                                (<span className="px-2 py-1 bg-green-400 rounded-full text-white uppercase">{params.row.status_transaction}</span>)
                            : params.row.status_transaction === 'cancel' ?
                                (<span className="px-2 py-1 bg-red-400 rounded-full text-white uppercase">{params.row.status_transaction}</span>)
                            : ("")
                        }
                    </div>
                )
            } 
        },
        {
            field: 'action',
            headerName: 'Action',
            width: 100,
            renderCell: (params) => {
                return (
                    <div className='w-full flex flex-row items-center gap-3'>
                        {/* <Link to={"/reports/detail/" + params.row.id}>
                            <VisibilityIcon fontSize='medium' className="text-slate-400 hover:text-slate-500 cursor-pointer"/>
                        </Link> */}
                        <VisibilityIcon onClick={() => handleDetail(params.row.id)} fontSize='medium' className="text-slate-400 hover:text-slate-500 cursor-pointer"/>
                    </div>
                )
            }
        },
    ]

    return (
        <>
            {showDetail ?
                    <DetailTransactionReport detailTransaction={detailTransaction} setShowDetail={setShowDetail} />
                :
                    <div className={`min-h-[calc(100vh-4rem)] flex flex-col `}>
                        {/* header */}
                        <div className="my-5 mb-5 mx-7 md:mx-5 flex flex-col md:flex-row justify-between">
                            <h1 className='font-ssp font-bold text-2xl md:text-3xl'>Report Control Transactions</h1>
                        </div>
                        {/* form */}
                        <div className="my-5 mb-5 mx-7 md:mx-5 flex">
                            <div className="p-2 w-10/12 md:w-1/4 bg-white rounded-md drop-shadow-lg flex">
                                <form 
                                    onSubmit={handleSubmit}
                                    className="px-3 w-full flex flex-col"
                                >
                                    {/* start date */}
                                    <div className="relative z-0 mb-3 mt-3 w-full group">
                                        <input 
                                            type="date" 
                                            value={startDate} 
                                            onChange={(e) => setStartDate(e.target.value)} 
                                            name="startDate" 
                                            id="startDate" 
                                            className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                                            placeholder=" " 
                                            required 
                                        />
                                        <label 
                                            htmlFor="startDate" 
                                            className="absolute top-3 -z-10 text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                        >Start Date</label>
                                    </div>
                                    {/* end date */}
                                    <div className="relative z-0 mb-3 mt-3 w-full group">
                                        <input 
                                            type="date" 
                                            value={endDate} 
                                            onChange={(e) => setEndDate(e.target.value)} 
                                            name="endDate" 
                                            id="endDate" 
                                            className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                                            placeholder=" " 
                                            required 
                                        />
                                        <label 
                                            htmlFor="endDate" 
                                            className="absolute top-3 -z-10 text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                        >End Date</label>
                                    </div>
                                    {/* button  */}
                                    <div className="relative z-0 mb-6 mt-5 w-full flex justify-end gap-3">
                                        <button 
                                            type="submit" 
                                            className="mt-5 px-5 py-2.5 w-full sm:w-auto bg-green-400 hover:bg-green-500 rounded-lg text-white font-medium text-sm text-center"
                                        >Search</button>
                                        <span 
                                            onClick={handleReset} 
                                            className="mt-5 px-5 py-2.5 w-full sm:w-auto bg-red-400 hover:bg-red-500 rounded-lg font-medium text-sm text-white text-center cursor-pointer"
                                        >Reset</span>
                                    </div>
                                </form>
                            </div>
                        </div>
                        {/* content */}
                        <div className="my-5 mb-5 mx-7 md:mx-5 flex">
                            <div className="p-2 w-full bg-white rounded-md drop-shadow-lg flex">
                                <DataGrid
                                    autoHeight {...datas}
                                    rows={datas}
                                    columns={columns}
                                    pageSize={10}
                                    getRowId={(row) => row.id}
                                    rowsPerPageOptions={[10]}
                                    checkboxSelection
                                    disableSelectionOnClick
                                    components={{ Toolbar: GridToolbar, }}
                                />
                            </div>
                        </div>
                    </div>
            }
        </>
    )
}

export default TransactionReport