import MainLayouts from "./layouts/mainLayouts";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Login from "./pages/Login";
import { useContext, useEffect } from "react";
import { AuthContext } from "./context/authContext";
import { ToastContainer } from 'react-toastify';
import { decryptData } from "./utilities/cryptoStorage";
import { decodeToken } from "react-jwt";

function App() {
  const { user, dispatch } = useContext(AuthContext)
  
  useEffect(() => {

    const storedData = localStorage.getItem('cRdT');
    if (storedData) {
      // Jika ada, melakukan parsing string menjadi objek
      const parsedData = JSON.parse(decryptData(storedData));

      // kemudian decode token dan set ke context
      const myDecodedToken = decodeToken(parsedData);

      dispatch({ type: "LOGIN_SUCCESS", payload: myDecodedToken });
    }

  }, [dispatch])

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route exact path="/*" element={user ? <MainLayouts /> : <Login />}/>
          <Route path="/login" element={user ? < Navigate to={"/"} /> : <Login />}/>
        </Routes>
      </BrowserRouter>
      <ToastContainer />
    </>
  );
}

export default App;
