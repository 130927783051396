import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getEmployee, updateEmployee } from '../../utilities/sendRequest';
import { decryptData } from '../../utilities/cryptoStorage';

const DetailEmployee = () => {
    const token = JSON.parse(decryptData(localStorage.getItem('cRdT')));
    const params = useParams()
    const navigate = useNavigate()
    const [fullname, setFullname] = useState("")
    const [username, setUsername] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [division, setDivision] = useState("")
    const [status, setStatus] = useState("")
    const options = [
        {
            label: "ACTIVE",
            value: 1
        },
        {
            label: "INACTIVE",
            value: 0
        },
    ]
    const optionDivision = [
        {
            label: "Choose a division",
            value: "",
        },
        {
            label: "Marketing",
            value: "market",
        },
        {
            label: "Event",
            value: "event",
        }
    ]
    
    useEffect(() => {
        fetchEmployee()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const fetchEmployee = async () => {
        getEmployee(params.uuid, token).then((res) => {
            setFullname(res.payload.name)
            setUsername(res.payload.username)
            setDivision(res.payload.division)
            setStatus(res.payload.is_active)
        }).catch((err) => {
            toast.error(err.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        })  
    }

    const handleUpdate = async (e) => {
        e.preventDefault()

        if(newPassword !== confirmPassword) {
            return toast.error("New password not match", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        }

        const reqData = {
            fullname,
            username,
            newPassword,
            division, 
            status
        }

        updateEmployee(params.uuid, reqData, token).then((res) => {
            toast.success(res.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
            fetchEmployee()
        }).catch((err) => {
            return toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        })

        setNewPassword("")
        setConfirmPassword("")
    }

    return (
        <div className='min-h-[calc(100vh-4rem)] flex flex-col'>
            {/* header */}
            <div className="my-5 mx-7 md:mx-5 flex flex-row justify-between">
                <h1 className='font-ssp font-bold text-4xl md:text-3xl'>Detail Employee</h1>
            </div>
            {/* content */}
            <div className="my-5 mx-7 md:mx-5 flex flex-col lg:flex-row gap-10">
                {/* form update */}
                <div className="px-2 py-4 w-full lg:w-1/3 md:w-10/12 flex bg-white drop-shadow-lg rounded-md">
                    <form onSubmit={handleUpdate} className='px-5 w-full flex flex-col'>
                        {/* fullname */}
                        <div className="relative z-0 mt-3 mb-6 w-full group">
                            <input 
                                minLength={3} 
                                value={fullname} 
                                onChange={(e) => setFullname(e.target.value)} 
                                type="text" 
                                name="fullname" 
                                id="fullname" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                            focus:border-blue-600 peer" 
                                placeholder=" " 
                                required 
                            />
                            <label 
                                htmlFor="fullname" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 
                                            duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                            peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                            peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Fullname</label>
                        </div>
                        {/* username */}
                        <div className="relative z-0 mb-6 w-full group">
                            <input 
                                minLength={3} 
                                value={username} 
                                onChange={(e) => setUsername(e.target.value)} 
                                type="text" 
                                name="username" 
                                id="username" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 
                                            appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                                placeholder=" "
                                required 
                            />
                            <label 
                                htmlFor="username" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 
                                            duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                            peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                            peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Username</label>
                        </div>
                        {/* division */}
                        <div className="relative z-0 mb-6 w-full group">
                            <select 
                                required 
                                value={division} 
                                onChange={(e) => setDivision(e.target.value)} 
                                id="division_select" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            >
                                {optionDivision.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label} 
                                    </option>
                                ))}
                            </select>
                            <label 
                                htmlFor="division_select" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:font-medium peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Division</label>
                        </div>
                        {/* password baru */}
                        <div className="relative z-0 mb-6 w-full group">
                            <input 
                                minLength={6} 
                                value={newPassword} 
                                onChange={(e) => setNewPassword(e.target.value)} 
                                type="password" 
                                name="new_password" 
                                id="new_password" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                            focus:border-blue-600 peer" 
                                placeholder=" " 
                            />
                            <label 
                                htmlFor="new_password" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 
                                            duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                            peer-focus:text-blue-600 peer-placeholder-shown:scale-100 
                                            peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >New Password</label>
                        </div>
                        {/* confirm password baru */}
                        <div className="relative z-0 mb-6 w-full group">
                            <input 
                                minLength={6} 
                                value={confirmPassword} 
                                onChange={(e) => setConfirmPassword(e.target.value)} 
                                type="password" 
                                name="repeat_password" 
                                id="floating_repeat_password" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 
                                            focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                                placeholder=" " 
                            />
                            <label 
                                htmlFor="floating_repeat_password" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 
                                            duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                            peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
                                            peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Confirm New Password</label>
                        </div>
                        {/* status */}
                        <div className="relative z-0 mb-6 w-full group">
                            <select required value={status} 
                                onChange={(e) => setStatus(e.target.value)} 
                                id="status_select" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            >
                                <option value="">Choose a status</option>
                                {options.map((option, i) => (
                                    <option key={i} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                            <label 
                                htmlFor="status_select" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Status</label>
                        </div>
                        {/* button */}
                        <div className="mb-6 w-full relative z-0 flex justify-center gap-5">
                            <button 
                                type="submit" 
                                className="px-5 py-2.5 mt-5 w-full sm:w-auto bg-green-400 hover:bg-green-500 rounded-lg 
                                            font-medium text-sm text-white text-center cursor-pointer"
                            >Submit</button>
                            <span 
                                onClick={() => navigate('/employees')} 
                                className="px-5 py-2.5 mt-5 w-full sm:w-auto bg-red-400 hover:bg-red-500 rounded-lg
                                            font-medium text-sm text-white text-center cursor-pointer"
                            >Back</span>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default DetailEmployee