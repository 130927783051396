import React, { useState } from 'react'
import { toast } from 'react-toastify';
import { addPlaylistEnterpriser } from '../../utilities/sendRequest';
import { decryptData } from '../../utilities/cryptoStorage';

const AddPlaylist = ({ datas }) => {
    const token = JSON.parse(decryptData(localStorage.getItem('cRdT')));
    const [enterprise, setEnterprise] = useState("")
    const [audio, setAudio] = useState("")

    const handleSubmit = (e) => {
        e.preventDefault();

        const reqData = {
            enterprise,
            audio
        }

        addPlaylistEnterpriser(reqData, token).then((res) => {
            toast.success(res.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
            
            setEnterprise("")
            setAudio("")
        }).catch((err) => {
            toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        })

    }

    return (
        <>
            {/* form add playlist */}
            <div className="px-2 py-4 w-full lg:w-1/3 md:w-10/12 flex flex-col gap-5 md:gap-3 bg-white drop-shadow-lg rounded-md">
                <h1 className='text-center font-semibold'>Add Playlist Enterprise</h1>
                <form onSubmit={handleSubmit} className='px-5 w-full flex flex-col'>
                    {/* enterprise */}
                    <div className="relative z-0 mt-3 mb-6 w-full group">
                        <input 
                            value={enterprise} 
                            onChange={(e) => setEnterprise(e.target.value)} 
                            type="text" 
                            name="enterprise" 
                            id="enterprise" 
                            className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                            focus:border-blue-600 peer" 
                            placeholder=" " 
                            required 
                        />
                        <label 
                            htmlFor="enterprise" 
                            className="absolute top-3 -z-10 text-sm text-gray-500 
                                            duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                            peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                            peer-focus:scale-75 peer-focus:-translate-y-6"
                        >ID Enterpriser</label>
                    </div>
                    {/* audios */}
                    <div className="mb-3 mt-4 w-full relative z-0 group">
                        <select 
                            required 
                            value={audio} 
                            onChange={(e) => setAudio(e.target.value)} 
                            id="audios_select" 
                            className="block py-2.5 px-2 w-full text-sm text-gray-500 bg-transparent 
                                border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                focus:border-blue-600 peer"
                        >
                            <option value="">Choose a Audio</option>
                            {datas.map((item) => (
                                <option key={item.id} value={item.id} >{item.name}</option>
                            ))}
                        </select>
                        <label 
                            htmlFor="audios_select" 
                            className="absolute top-3 -z-10 text-sm text-gray-500 
                                duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                peer-focus:scale-75 peer-focus:-translate-y-6"
                        >Audios</label>
                    </div>
                    {/* button */}
                    <div className="mb-3 w-full relative z-0 flex justify-center gap-5">
                        <button 
                            type="submit" 
                            className="px-5 py-2.5 mt-5 w-full sm:w-auto bg-green-400 hover:bg-green-500 rounded-lg 
                                        font-medium text-sm text-white text-center cursor-pointer"
                        >Submit</button>
                    </div>
                </form>
            </div>
        </>
    )
}

export default AddPlaylist