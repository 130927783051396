import React, { useContext, useEffect, useRef, useState } from 'react';
import { RiCloseFill } from "react-icons/ri";
import { toast } from 'react-toastify';
import DeleteIcon from '@mui/icons-material/Delete';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { PopupContext } from "../../context/popupContext";
import { deletePlaylistEnterpriser, getPlaylistAudio } from '../../utilities/sendRequest';
import { decryptData } from '../../utilities/cryptoStorage';

const PlaylistAudio = ({ setShowPlaylist, enterprise }) => {

    const token = JSON.parse(decryptData(localStorage.getItem('cRdT')));
    const { popupDispatch } = useContext(PopupContext)
    const clickRef = useRef(null)
    const [datas, setDatas] = useState([])
    
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (clickRef.current && !clickRef.current.contains(event.target)) {
                setShowPlaylist(null)
                popupDispatch({ type: "POPUP_END" });
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clickRef])

    useEffect(() => {
        fetchPlaylist()
        // eslint-disable-next-line
    }, [])

    const fetchPlaylist = async () => {
        getPlaylistAudio(enterprise, token).then((res) => {
            setDatas(
                res.payload.sort((p1, p2) => {
                    return new Date(p2.created_at) - new Date(p1.created_at)
                })
            )
        }).catch((err) => {
            toast.error(err.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        })
    }

    const handleModal = () => {
        setShowPlaylist(null)
        popupDispatch({ type: "POPUP_END" });
    }

    const handleDeleteList = async (paramsId) => {
        deletePlaylistEnterpriser(paramsId, token).then((res) => {
            toast.success(res.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
            fetchPlaylist()
        }).catch((err) => {
            toast.error(err.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        })
    }

    const columns = [
        { 
            field: 'id_enterpriser', 
            headerName: 'ID Enterpriser',
            width: 150, 
            renderCell: (params) => {
                return (
                    <div className='flex justify-center items-center font-ssp'>
                        {params.row.id_enterpriser}
                    </div>
                )
            } 
        },
        { 
            field: 'enterprise_name', 
            headerName: 'Name Enterpriser',
            width: 250, 
            renderCell: (params) => {
                return (
                    <div className='flex justify-center items-center font-ssp truncate'>
                        {params.row.enterprise_name}
                    </div>
                )
            } 
        },
        { 
            field: 'product_name', 
            headerName: 'Audio',
            width: 400, 
            renderCell: (params) => {
                return (
                    <div className='flex justify-center items-center font-ssp truncate'>
                        {params.row.product_code} - {params.row.product_name}
                    </div>
                )
            } 
        },
        {
            field: 'action',
            headerName: 'Action',
            width: 100,
            renderCell: (params) => {
                return (
                    <div className='w-full flex flex-row items-center gap-3'>
                        <DeleteIcon onClick={() => handleDeleteList(params.row.id)} fontSize='medium' className="text-red-400 hover:text-red-500 cursor-pointer"/>                  
                    </div>
                )
            }
        },
    ]

    return (
        <div  className="fixed top-0 left-0 z-10 p-3 w-screen min-h-screen overscroll-y-none overflow-y-hidden bg-black bg-opacity-70 flex items-center justify-center">
            <div ref={clickRef} className="p-3 w-11/12 md:w-[70%] h-full bg-white rounded-md flex flex-col">
                <div className="px-2 flex justify-between items-center">
                    <div className="font-bold font-ssp text-xl md:text-2xl">
                        Playlist audio enterpriser by "<span className='italic'>{enterprise}</span>"
                    </div>
                    <RiCloseFill onClick={handleModal} className="w-8 h-8 cursor-pointer"/>
                </div>
                <div className='mt-5 px-2 w-full flex flex-col'>
                    <DataGrid
                        autoHeight {...datas}
                        rows={datas}
                        columns={columns}
                        pageSize={5}
                        getRowId={(row) => row.id}
                        rowsPerPageOptions={[5]}
                        checkboxSelection
                        disableSelectionOnClick
                        components={{ Toolbar: GridToolbar, }}
                    />
                </div>
            </div>
        </div>
    )
}

export default PlaylistAudio