import React, { useContext, useState } from 'react'
import PlaylistAudio from './playlist'
import { PopupContext } from '../../context/popupContext'

const SearchFormAudio = () => {
    const [enterprise, setEnterprise] = useState("")
    const [showPlaylist, setShowPlaylist] = useState(false)
    const { popupDispatch } = useContext(PopupContext)

    const handleSubmit = async (e) => {
        e.preventDefault()
        setShowPlaylist(true)
        popupDispatch({ type: "POPUP_START" });
    }

    return (
        <>
            {showPlaylist &&
                <PlaylistAudio setShowPlaylist={setShowPlaylist} enterprise={enterprise}/>
            }
            {/* form search */}
            <div className="px-2 py-4 w-full lg:w-1/3 md:w-10/12 h-fit flex flex-col gap-5 md:gap-3 bg-white drop-shadow-lg rounded-md">
                <h1 className='text-center font-semibold'>Search List Audio Enterpriser</h1>
                <form onSubmit={handleSubmit} className='px-5 w-full flex flex-col'>
                    {/* enterprise */}
                    <div className="relative z-0 mt-3 mb-6 w-full group">
                        <input 
                            value={enterprise} 
                            onChange={(e) => setEnterprise(e.target.value)} 
                            type="text" 
                            name="enterprise" 
                            id="enterprise" 
                            className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                            focus:border-blue-600 peer" 
                            placeholder=" " 
                            required 
                        />
                        <label 
                            htmlFor="enterprise" 
                            className="absolute top-3 -z-10 text-sm text-gray-500 
                                            duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                            peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                            peer-focus:scale-75 peer-focus:-translate-y-6"
                        >ID Enterpriser</label>
                    </div>
                    {/* button */}
                    <div className="mb-3 w-full relative z-0 flex justify-center gap-5">
                        <button 
                            type="submit" 
                            className="px-5 py-2.5 mt-5 w-full sm:w-auto bg-green-400 hover:bg-green-500 rounded-lg 
                                        font-medium text-sm text-white text-center cursor-pointer"
                        >Search</button>
                    </div>
                </form>
            </div>
        </>
    )
}

export default SearchFormAudio