import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { IoIosCloseCircle } from "react-icons/io";
import { getPost, updatePost } from '../../utilities/sendRequest';
import { decryptData } from '../../utilities/cryptoStorage';
import { getYoutubeEmbedLink } from '../../utilities/youtubeEmbed';

const DetailPost = () => {
    const token = JSON.parse(decryptData(localStorage.getItem('cRdT')));
    const params = useParams()
    const navigate = useNavigate()
    const thumbnailRef = useRef(null)
    const contentRef = useRef(null)
    const [datas, setDatas] = useState([])
    const [title, setTitle] = useState("")
    const [description, setDescription] = useState("")
    const [textContent, setTextContent] = useState("")
    const [scheduleDate, setScheduleDate] = useState("")
    const [status, setStatus] = useState("")
    const [type, setType] = useState("")
    const [thumbnail, setThumbnail] = useState(null)
    const [imageContent, setImageContent] = useState(null)
    
    const optionStatus = [
        {
            label: "Choose a Status",
            value: "",
        },
        {
            label: "ACTIVE",
            value: 1,
        },
        {
            label: "INACTIVE",
            value: 0,
        },
    ]
    const optionType = [
        {
            label: "Choose a Type",
            value: "",
        },
        {
            label: "Video",
            value: 1,
        },
        {
            label: "Image",
            value: 0,
        },
    ]

    useEffect(() => {
        fetchPost()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const fetchPost = async () => {
        getPost(params.id, token).then((res) => {
            setDatas(res.payload);
            setTitle(res.payload.title)
            setScheduleDate(res.payload.publish_at)
            setDescription(res.payload.description)
            setStatus(res.payload.is_active)
            setType(res.payload.is_video)
            (res.payload.is_video === "1") ? setTextContent(res.payload.content) : setTextContent("")
        }).catch((err) => {
            toast.error(err, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        })  
    }

    const handleResetThumbnail = () => {
        setThumbnail(null)
        thumbnailRef.current.value = null;
    }

    const handleResetImageContent = () => {
        setImageContent(null)
        contentRef.current.value = null;
    }

    const hanldeUpdate = async (e) => {
        e.preventDefault()

        const reqData = new FormData();
        reqData.append("title", title);
        reqData.append("description", description);
        reqData.append("scheduleDate", scheduleDate);
        reqData.append("status", status);
        reqData.append("type", type);
        reqData.append("textContent", textContent);

        if(thumbnail) {
            reqData.append("thumbnail", thumbnail);
        }

        if(imageContent) {
            setTextContent("")
            reqData.append("imageContent", imageContent);
        }

        updatePost(params.id, reqData, token).then((res) => {
            toast.success(res.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
            fetchPost()
            handleResetThumbnail()
            if (type === "0") {handleResetImageContent()}
        }).catch((error) => {
            console.log(error);
            toast.error(error.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        })
    }

    return (
        <div className='min-h-[calc(100vh-4rem)] flex flex-col'>
            {/* header  */}
            <div className="my-5 mx-7 md:mx-5 flex flex-row justify-between">
                <h1 className='font-ssp font-bold text-4xl md:text-3xl'>Detail Post</h1>
            </div>
            {/* content */}
            <div className="my-5 mx-7 md:mx-5 flex flex-col lg:flex-row justify-evenly gap-7 md:gap-10">
                {/* detail post */}
                <div className="w-full h-full lg:w-1/4 md:w-1/2 flex flex-col gap-3 items-center">
                    {datas.thumbnail && 
                        <div className='px-3 py-4 w-full h-full bg-white drop-shadow-lg rounded-md flex flex-col gap-2'>
                            <h3 className='text-lg font-ssp font-semibold text-center'>Thumbnail</h3>
                            <img src={`${process.env.REACT_APP_PUBLIC_FOLDER_POSTS_FLYERS}/${datas.thumbnail}`} alt={title} />
                        </div>
                    }

                    {(datas.is_video === "1") && 
                        <div className='px-3 py-4 w-full h-full bg-white drop-shadow-lg rounded-md flex flex-col gap-2'>
                            {/* video */}
                            <h3 className='text-lg font-ssp font-semibold text-center'>Video Content</h3>
                            <iframe 
                                className='w-full h-full'
                                title="YouTube Video"
                                src={getYoutubeEmbedLink(datas.content)}
                                >
                            </iframe>
                        </div>
                    }

                    {(datas.is_video === "0") && 
                        <div className='px-3 py-4 w-full h-full bg-white drop-shadow-lg rounded-md flex flex-col gap-2'>
                            {/* gambar */}
                            <h3 className='text-lg font-ssp font-semibold text-center'>Image Content</h3>
                            <img src={`${process.env.REACT_APP_PUBLIC_FOLDER_POSTS_CONTENTS}/${datas.content}`} alt={datas.title} />
                        </div>
                    }
                </div>

                {/* form update */}
                <div className="px-2 py-4 w-full h-fit lg:w-2/3 md:w-10/12 bg-white drop-shadow-lg rounded-md flex">
                    <form onSubmit={hanldeUpdate} className='px-5 w-full flex flex-wrap justify-between'>
                        {/* title */}
                        <div className="mt-3 mb-6 w-[45%] relative z-0 group">
                            <input 
                                minLength={5} 
                                value={title} 
                                onChange={(e) => setTitle(e.target.value)} 
                                type="text" 
                                name="title" 
                                id="title" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                            focus:border-blue-600 peer" 
                                placeholder=" " 
                                required 
                            />
                            <label 
                                htmlFor="title" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 
                                            duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                            peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                            peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Title</label>
                        </div>
                        {/* publish date */}
                        <div className="mt-3 mb-6 w-[45%] relative z-0 group">
                            <input 
                                value={scheduleDate} 
                                onChange={(e) => setScheduleDate(e.target.value)} 
                                type="date" 
                                name="scheduleDate" 
                                id="scheduleDate" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                            focus:border-blue-600 peer" 
                                placeholder=" " 
                                required 
                            />
                            <label 
                                htmlFor="scheduleDate" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 dark:text-gray-400 
                                            duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                            peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                            peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Publish Date</label>
                        </div>
                        {/* description */}
                        <div className="mt-3 mb-6 w-[45%] relative z-0 group">
                            <input 
                                minLength={5} 
                                value={description} 
                                onChange={(e) => setDescription(e.target.value)} 
                                type="text" 
                                name="description" 
                                id="description" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                            focus:border-blue-600 peer" 
                                placeholder=" " 
                            />
                            <label 
                                htmlFor="description" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 dark:text-gray-400 
                                            duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                            peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                            peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Description</label>
                        </div>
                        {/* status */}
                        <div className="mt-3 mb-6 w-[45%] relative z-0 group">
                            <select 
                                required 
                                value={status} 
                                onChange={(e) => setStatus(e.target.value)} 
                                id="status" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-500 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                            focus:border-blue-600 peer"
                            >
                                {optionStatus.map((option) => (
                                    <option key={option.value} value={option.value}>{option.label}</option>
                                ))}
                            </select>
                            <label 
                                htmlFor="status" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 
                                            duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                            peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                            peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Status</label>
                        </div>
                        {/* type */}
                        <div className="mt-3 mb-6 w-[45%] relative z-0 group">
                            <select 
                                required 
                                value={type} 
                                onChange={(e) => setType(e.target.value)} 
                                id="type" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-500 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                            focus:border-blue-600 peer"
                            >
                                {optionType.map((option) => (
                                    <option key={option.value} value={option.value}>{option.label}</option>
                                ))}
                            </select>
                            <label 
                                htmlFor="type" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 
                                            duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                            peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                            peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Type</label>
                        </div>
                        {/* text content */}
                        {type === "1" &&
                            <div className="mt-3 mb-6 w-[45%] relative z-0 group">
                                <input 
                                    minLength={5} 
                                    value={textContent} 
                                    onChange={(e) => setTextContent(e.target.value)} 
                                    type="text" 
                                    name="textContent" 
                                    id="textContent" 
                                    className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                                border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                                focus:border-blue-600 peer" 
                                    placeholder=" " 
                                    required 
                                />
                                <label 
                                    htmlFor="textContent" 
                                    className="absolute top-3 -z-10 text-sm text-gray-500 
                                                duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                                peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                                peer-focus:scale-75 peer-focus:-translate-y-6"
                                >Link Youtube</label>
                            </div>
                        }
                        {/* image */}
                        {type === "0" &&
                            <>
                                <div className="mt-3 mb-3 w-full relative z-0 group">
                                    <label 
                                        className="block mb-2 font-ssp text-sm font-medium text-gray-500" 
                                        htmlFor="file_image"
                                    >Upload Image</label>
                                    <input 
                                        type="file" 
                                        name="imageContent"
                                        ref={contentRef}
                                        onChange={(e) => setImageContent(e.target.files[0])}
                                        accept='.png,.jpg,.jpeg'
                                        className="block w-full text-sm text-slate-500
                                                    file:mr-4 file:py-2 file:px-4
                                                    file:rounded-full file:border-0
                                                    file:text-sm file:font-semibold
                                                    file:bg-gray-50 file:text-gray-500
                                                    hover:file:bg-gray-100"
                                    />
                                    <p className="mt-1 font-ssp text-xs text-gray-500 dark:text-gray-300" id="file_image_help">PNG, JPG, JPEG (MAX. 5MB).</p>
                                </div>
                                {imageContent && 
                                    <div className="mt-3 w-full relative flex flex-row group">
                                        <img src={URL.createObjectURL(imageContent)} alt="Preview" className="w-1/4" />
                                        <IoIosCloseCircle 
                                            fontSize='25px' 
                                            className='-mt-3 -ml-3 bg-white rounded-full item-start cursor-pointer' 
                                            onClick={handleResetImageContent} />
                                    </div>
                                }
                            </>
                        }
                        {/* photoThumbail */}
                        <div className="mt-3 mb-6 w-full relative z-0 group">
                            <label 
                                className="block mb-2 font-ssp text-xs font-medium text-gray-500" htmlFor="thumbnail">New Thumbnail</label>
                            <input 
                                type="file" 
                                name="thumbnail"
                                id="thumbnail"
                                ref={thumbnailRef}
                                onChange={(e) => setThumbnail(e.target.files[0])}
                                accept='.png, .jpg, .jpeg'
                                className="block w-full text-sm text-slate-500
                                    file:mr-4 file:py-2 file:px-4
                                    file:rounded-full file:border-0
                                    file:text-sm file:font-semibold
                                    file:bg-gray-50 file:text-gray-500
                                    hover:file:bg-gray-100"
                            />
                            <p className="mt-1 font-ssp text-xs text-gray-500 dark:text-gray-300" id="photo">PNG, JPG, JPEG (MAX. 5MB).</p>
                        </div>
                        {thumbnail && 
                            <div className="mt-3 w-full relative flex flex-row group">
                                <img src={URL.createObjectURL(thumbnail)} alt="Preview" className="w-1/4" />
                                <IoIosCloseCircle  
                                    fontSize='25px' 
                                    className='-mt-3 -ml-3 bg-white rounded-full item-start cursor-pointer' 
                                    onClick={handleResetThumbnail} 
                                />
                            </div>
                        }
                        {/* button */}
                        <div className="mb-6 w-full relative z-0 flex justify-center gap-5">
                            <button 
                                type="submit" 
                                className="mt-5 px-5 py-2.5 w-full sm:w-auto bg-green-400 hover:bg-green-500 rounded-lg 
                                            font-medium text-white text-sm text-center cursor-pointer"
                            >Update</button>
                            <span 
                                onClick={() => navigate('/posts')} 
                                className="mt-5 px-5 py-2.5 w-full sm:w-auto bg-red-400 hover:bg-red-500 rounded-lg 
                                            font-medium text-white text-sm text-center cursor-pointer"
                            >Back</span>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default DetailPost